export interface PackageRankReward {
  id: number;
  userId: number;
  dateCreated: string;
  amount: number;
  status: PackageRankRewardStatus;
  packageRank: {
    packageRankId: number;
    name: string;
  };
  packageRankReward: {
    packageRankRewardId: number;
    name: string;
  };
}

export enum PackageRankRewardStatus {
  PENDING = 1,
  DELIVERED
}

export interface PackageRankRewardsState {
  data: PackageRankReward[];
  loading: boolean;
  totalCount: number;
}
