import { FC } from 'react';
import { Col } from 'reactstrap';

export const Tile: FC<any> = ({ label, children }) => {
  return (
    <Col lg={4}>
      <div className='border border-1 p-3 mb-3 mt-2 card-primary'>
        <h5 className='card-title text-uppercase fs-13'>
          {label} <i className={'fs-18 float-end align-middle '}></i>
        </h5>
        <div className='d-flex align-items-center'>
          {/* <div className='flex-shrink-0'>
              <i className={'display-6 text-muted ' + item.icon}></i>
            </div> */}
          <div className='card-title muted flex-grow-1 ms-3'>
            <h2 className='card-title mb-0 fs-24'>{children}</h2>
          </div>
        </div>
      </div>
    </Col>
  );
};
