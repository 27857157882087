import { useState } from 'react';
import {
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap';
import { PackageStatus, UserPackages } from '../types';
import { MarkAsPaid, MarkAsPaidModal } from './markAsPaid';
import {
  RecalculateTotalBonus,
  RecalculateTotalBonusModal
} from './recalculateTotalBonus';
import { ResendPaymentInstructions } from './resendPaymentInstructions';
import { Storno, StornoModal } from './storno';

export const PackageOrdersActions = (cell: any) => {
  const [isOpen, setIsOpen] = useState(0);

  const { id, status }: UserPackages = cell.row.original;

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          href='#'
          className='btn btn-soft-secondary btn-sm'
          tag='button'>
          <i className='ri-more-fill' />
        </DropdownToggle>

        <DropdownMenu className='dropdown-menu-end'>
          {status === PackageStatus.PAID && (
            <RecalculateTotalBonus setIsOpen={setIsOpen} />
          )}

          {status === PackageStatus.PENDING && (
            <MarkAsPaid isOpen={isOpen} setIsOpen={setIsOpen} />
          )}

          {status === PackageStatus.CANCELED && (
            <ResendPaymentInstructions id={id} />
          )}

          {status === PackageStatus.CANCELED_BY_USER && (
            <ResendPaymentInstructions id={id} />
          )}

          {status === PackageStatus.PENDING && <Storno setIsOpen={setIsOpen} />}
        </DropdownMenu>
      </UncontrolledDropdown>

      {status === PackageStatus.PAID && (
        <RecalculateTotalBonusModal
          id={id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {status === PackageStatus.PENDING && (
        <MarkAsPaidModal id={id} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      {status === PackageStatus.PENDING && (
        <StornoModal id={id} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
};

export default PackageOrdersActions;
