import { FC, useEffect } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { CardBody, Col, Row } from 'reactstrap';
import TableContainer from '../../../Components/Common/TableContainer';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { formatUSD, setDocumentTitle } from '../../../helpers/helpers';
import { columns } from './columns';
import { Filters } from './filters';
import { getEarningsHistory, getEarningsInfo } from './slice';

const PAGE_TITLE = 'Earnings';

const EarningsPage = () => {
  const dispatch = useAppDispatch();

  const match = useMatch('/users/:id/*');
  const id = match?.params.id;

  const INITIAL_PARAMS: {
    sortBy: string;
    sortOrder: string;
    countPerPage: string;
    numberOfPage: string;
    userId: string;
  } = {
    sortBy: 'dateCreated',
    sortOrder: 'DESC',
    countPerPage: '10',
    numberOfPage: '1',
    userId: id || ''
  };

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);

  const { info, history, totalCount } = useAppSelector(
    (state) => state.earnings
  );

  useEffect(() => {
    if (id) {
      dispatch(getEarningsInfo(id));
      dispatch(getEarningsHistory(searchParams));
    }
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <>
      {info && searchParams.get('userId') && (
        <Row>
          <Col lg={4}>
            <CardBody className='pt-0'>
              <PageHeader title='Total Earnings' />

              <Row>
                <Tile
                  label='Total Earnings'
                  value={
                    <>
                      {formatUSD(info?.info?.totalEarnings.totalEarnings)} USDT
                    </>
                  }
                  today={info?.info?.totalEarnings.todayTotalEarnings}
                  colLg={12}
                />

                <Tile
                  label='USDT Balance'
                  value={
                    <>
                      {formatUSD(info?.info?.totalEarnings.availableBalance)}{' '}
                      USDT
                    </>
                  }
                  today={info?.info?.totalEarnings.todayAvailableBalance}
                  fee={false}
                  colLg={12}
                />

                <Tile
                  label='Club Balance'
                  value={
                    <>
                      {formatUSD(info?.info?.totalEarnings.shoppingPoints)} USDT
                    </>
                  }
                  today={info?.info?.totalEarnings.todayShoppingPoints}
                  colLg={12}
                  fee={false}
                />

                <Tile
                  label='Loyality Points'
                  value={
                    <>
                      {formatUSD(info?.info?.totalEarnings.loyaltyPoints)} USDT
                    </>
                  }
                  today={info?.info?.totalEarnings.todayLoyaltyPoints}
                  colLg={12}
                  fee={false}
                />
              </Row>
              <PageHeader title='Partner Earnings' />
              <Row>
                <Tile
                  label='Direct Bonus'
                  value={
                    <>
                      {formatUSD(
                        info?.info?.partnerEarnings.directBonusEarnings
                      )}{' '}
                      USDT
                    </>
                  }
                  today={info?.info?.partnerEarnings.todayDirectBonusEarnings}
                  fee={false}
                  colLg={12}
                />
                <Tile
                  label='Team Bonus'
                  value={
                    <>
                      {formatUSD(info?.info?.partnerEarnings.teamBonusEarnings)}{' '}
                      USDT
                    </>
                  }
                  today={info?.info?.partnerEarnings.todayTeamBonusEarnings}
                  fee={false}
                  colLg={12}
                />

                <Tile
                  label='TRADING TEAM BONUS'
                  value={
                    <>
                      {formatUSD(
                        info?.info?.partnerEarnings.tradingBonusEarnings
                      )}{' '}
                      USDT
                    </>
                  }
                  today={info?.info?.partnerEarnings.todayTradingBonusEarnings}
                  fee={false}
                  colLg={12}
                />
                <Tile
                  label='Matching Bonus'
                  value={
                    <>
                      {formatUSD(
                        info?.info?.partnerEarnings.matchingBonusEarnings
                      )}{' '}
                      USDT
                    </>
                  }
                  today={info?.info?.partnerEarnings.todayMatchingBonusEarnings}
                  fee={false}
                  colLg={12}
                />
                <Tile
                  label='Fast Start Bonus'
                  value={
                    <>
                      {formatUSD(
                        info?.info?.partnerEarnings.cashbackBonusEarnings
                      )}{' '}
                      USDT
                    </>
                  }
                  today={info?.info?.partnerEarnings.todayCashbackBonusEarnings}
                  fee={false}
                  colLg={12}
                />

                <Tile
                  label='Rank Rewards'
                  value={
                    <>
                      {formatUSD(
                        info?.info?.partnerEarnings.packageRankRewardsEarnings
                      )}{' '}
                      USDT
                    </>
                  }
                  today={
                    info?.info?.partnerEarnings.todayPackageRankRewardsEarnings
                  }
                  fee={false}
                  colLg={12}
                />

                <Tile
                  label='Other Bonuses'
                  value={
                    <>
                      {formatUSD(
                        info?.info?.partnerEarnings.legacyBonusEarnings
                      )}{' '}
                      USDT
                    </>
                  }
                  today={false}
                  fee={false}
                  colLg={12}
                />
                <Tile
                  label='Total hubs bought with USDT balance'
                  value={
                    <>
                      {formatUSD(
                        info?.info?.totalEarnings.totalHubsBoughtWithUSDT
                      )}{' '}
                      USDT
                    </>
                  }
                  today={false}
                  fee={false}
                  colLg={12}
                />
                <Tile
                  label='Total withdrawal orders'
                  value={
                    <>
                      {formatUSD(info?.info?.totalEarnings.totalWithdrawal)}{' '}
                      USDT
                    </>
                  }
                  today={false}
                  fee={info?.info?.totalEarnings?.totalWithdrawalFee}
                  colLg={12}
                />
              </Row>
            </CardBody>
          </Col>
          <Col lg={8}>
            <CardBody className='pt-0'>
              <PageHeader
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
              />

              <Filters
                params={searchParams}
                setParams={setSearchParams}
                colLg={6}
                colMd={12}
                colSm={12}
              />

              <TableContainer
                columns={columns}
                data={history || []}
                divClass='table-responsive table-card mb-1'
                tableClass='align-middle table-nowrap'
                theadClass='table-light text-muted'
              />

              <Pagination
                totalCount={totalCount}
                data={history}
                params={searchParams}
                setParams={setSearchParams}
              />
            </CardBody>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EarningsPage;

export const Tile: FC<any> = ({ label, value, today, fee, colLg }) => {
  return (
    <Col lg={colLg || 4}>
      <div className='border border-1 p-3 mb-3 mt-2 card-primary'>
        <h5 className='card-title text-uppercase fs-13'>
          {label} <i className={'fs-18 float-end align-middle '}></i>
        </h5>
        <h3 className='card-title mb-0 text-end mb-1 fs-24'>{value}</h3>

        {today !== false && (
          <h5 className='card-title text-muted text-uppercase fs-13 mb-0 text-end'>
            TODAY: {today}
          </h5>
        )}
        {fee !== false && (
          <h5 className='card-title text-muted text-uppercase fs-13 mb-0 text-end'>
            Total fee: {formatUSD(fee)} USDT
          </h5>
        )}
      </div>
    </Col>
  );
};
