import { Input } from 'reactstrap';

export const Toggle = (props: any) => {
  return (
    <Input
      className='form-check-input code-switcher'
      type='checkbox'
      {...props}
    />
  );
};
