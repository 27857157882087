import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { WillyDataResponse } from './types';
import moment from 'moment';

export const getFreeWillyCoins = createAsyncThunk(
  'getFreeWillyCoins',
  async (id: string) => {
    const response = await axiosInstance.get(`/meme-coin/${id}/progress`);

    return response.data.data as WillyDataResponse;
  }
);

export const updateWillyCoins = createAsyncThunk(
  'auth/updateWillyCoins',
  ({ totalCoins, coinsPerChange }: WillyDataResponse) => {
    let willyCoins = {
      totalCoins: (totalCoins += coinsPerChange)
    };

    return willyCoins;
  }
);

export interface WillyCoinsState {
  loading: boolean;
  willyCoins: WillyDataResponse;
}

const initialState = {
  loading: false,
  willyCoins: {
    user: {
      id: 0,
      username: '',
      isValid: false,
      packageName: '',
      packagePrice: 0
    },
    sales: [
      {
        id: 0,
        username: '',
        isValid: false,
        packageName: '',
        packagePrice: 0
      }
    ],
    coins: 0,
    totalCoins: 0,
    coinsPerDay: 0,
    changePeriodInSeconds: 0,
    coinsPerChange: 0,
    daysLeft: 0
  }
} as WillyCoinsState;

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getFreeWillyCoins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFreeWillyCoins.fulfilled, (state, { payload }) => {
      const now = moment();
      const midnight = moment.utc().startOf('day');
      const secondsSinceMidnight = now.diff(midnight, 'seconds');

      state.loading = false;

      state.willyCoins = {
        ...payload,
        totalCoins:
          payload.totalCoins +
          (secondsSinceMidnight / payload.changePeriodInSeconds) *
            payload.coinsPerChange
      };
    });
    builder.addCase(getFreeWillyCoins.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateWillyCoins.fulfilled, (state, { payload }) => {
      state.willyCoins = {
        ...state.willyCoins,
        totalCoins: payload.totalCoins
      };
    });
  }
});

export default teamsSlice.reducer;
