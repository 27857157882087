import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { TaskListType, TaskAddType, AssignedTask } from './types';

export const getTaskList = createAsyncThunk(
  'tasklist/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/admin/user/task', {
      params
    });
    return response.data.data;
  }
);

export const addTask = createAsyncThunk(
  'tasklist/add',
  async (data: TaskAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/admin/user/task', data);
      toast.success('Task successfully added');

      return response.data.data as TaskListType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const assignTask = createAsyncThunk(
  'tasklist/assign',
  async (data: TaskAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/admin/user/assign-task',
        data
      );

      return response.data.data.userAssignedTask as AssignedTask;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const updateTask = createAsyncThunk(
  'tasklist/update',
  async (task: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/admin/user/task/status', {
        id: task.id,
        status: task.statusId
      });

      return response.data.data.task as TaskListType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const deleteTask = createAsyncThunk(
  'tasklist/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/admin/user/task/${id}`);
      toast.success(response.data.message);

      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export interface TaskListTypeInterface {
  data: TaskListType[];
  loading: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as TaskListTypeInterface;

const taskListSlice = createSlice({
  name: 'tasklist',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getTaskList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTaskList.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data = payload.tasks as TaskListType[];
      state.totalCount = payload.count;
    });
    builder.addCase(getTaskList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTask.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.unshift(payload);
    });
    builder.addCase(addTask.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTask.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data = state.data.map((task) => {
        if (task.id === payload.id) {
          return payload;
        } else {
          if (task.status.statusId == 2) {
            return {
              ...task,
              status: {
                statusId: 1,
                statusName: 'To Do'
              }
            };
          } else return task;
        }
      });
    });

    builder.addCase(updateTask.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTask.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data.splice(
        state.data.findIndex((p) => p.id === payload),
        1
      );
    });
    builder.addCase(deleteTask.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default taskListSlice.reducer;
