import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { EarningHistory, EarningInfoType, EarningsState } from './types';

export const getEarningsInfo = createAsyncThunk(
  'earnings/info/fetch',
  async (userId: string) => {
    const response = await axiosInstance.get('/mlm/user/earnings/info', {
      params: { userId }
    });

    return response.data.data as EarningInfoType;
  }
);

export const getEarningsHistory = createAsyncThunk(
  'earnings/history/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/mlm/user/earnings/history', {
      params
    });
    return response.data.data;
  }
);

const initialState = {
  info: null,
  history: [],
  loading: false,
  totalCount: 0
} as EarningsState;

const earningsSlice = createSlice({
  name: 'earnings',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getEarningsInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEarningsInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.info = payload;
      // state.totalCount = payload.count;
    });
    builder.addCase(getEarningsInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getEarningsHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEarningsHistory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.history = payload.earnings as EarningHistory[];
      state.totalCount = payload.count as number;
    });
    builder.addCase(getEarningsHistory.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default earningsSlice.reducer;
