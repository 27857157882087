import React from 'react';
import { useAppSelector } from '../../../../../app/hooks';

import RankComponent from './RankComponent';

export const Ranking: React.FC = () => {
  const { ranking } = useAppSelector((state) => state.userDetails);

  return (
    <div>
      {ranking?.map((rank: any, i: number) => {
        return <RankComponent rank={rank} key={i} />;
      })}
    </div>
  );
};
