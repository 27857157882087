import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { BlacklistedUsersType, BlacklistedUserAddType } from './types';

export const getBlacklistedUsers = createAsyncThunk(
  'blacklistedUser/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('wallet/user/blacklisted', {
      params
    });
    return response.data.data;
  }
);

export const addBlacklistUser = createAsyncThunk(
  'blacklistedUser/add',
  async (data: BlacklistedUserAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('wallet/user/blacklist', data);
      toast.success('User Id successfully added');

      return response.data.data.blacklistedUser as BlacklistedUsersType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const deleteBlacklistUser = createAsyncThunk(
  'blacklistedUser/delete',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `wallet/user/blacklist/${userId}`
      );
      toast.success(response.data.message);

      return userId;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export interface BlacklistedUsersTypeInterface {
  data: BlacklistedUsersType[];
  loading: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as BlacklistedUsersTypeInterface;

const blacklistedUsersSlice = createSlice({
  name: 'blacklistedUser',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getBlacklistedUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBlacklistedUsers.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data = payload.blacklistedUsers as BlacklistedUsersType[];
      state.totalCount = payload.count;
    });
    builder.addCase(getBlacklistedUsers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addBlacklistUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBlacklistUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.unshift(payload);
    });
    builder.addCase(addBlacklistUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteBlacklistUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBlacklistUser.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data.splice(
        state.data.findIndex((p) => p.userId === payload),
        1
      );
    });
    builder.addCase(deleteBlacklistUser.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default blacklistedUsersSlice.reducer;
