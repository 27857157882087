import Select from 'react-select';
import {
  handleFilterChange,
  handleSetUrlSearchParams
} from '../../helpers/helpers';

export const SelectFilter = ({
  searchParams,
  setSearchParams,
  param,
  options,
  placeholder,
  isClearable = true,
  hasPagination = true
}: {
  searchParams: URLSearchParams;
  setSearchParams: any;
  param: string;
  options: any;
  placeholder?: string;
  isClearable?: boolean;
  hasPagination?: boolean;
}) => {
  const getQuery = searchParams.get(param);
  const getValue = getQuery
    ? options.find((e: any) => e.value == getQuery)
    : '';

  return (
    <div className='mb-2 d-inline-block col-12'>
      <Select
        value={getValue}
        onChange={(e: any) => {
          if (hasPagination) {
            handleFilterChange(param, e?.value, setSearchParams);
          } else {
            handleSetUrlSearchParams(param, e?.value, setSearchParams);
          }
        }}
        options={options}
        placeholder={placeholder || 'Select'}
        isClearable={isClearable}
      />
    </div>
  );
};
