import { handleValidDate, handleValidTime } from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Transaction ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell.row.original.userId;
    }
  },
  {
    id: 3,
    Header: 'User',
    accessor: 'user',
    Cell: (cell: any) => {
      return (
        <>
          {cell.row.original.firstName} {cell.row.original.lastName}
        </>
      );
    }
  },
  {
    id: 4,
    Header: 'Transaction name',
    accessor: 'name',
    Cell: (cell: any) => {
      return cell.row.original.name;
    }
  },
  {
    id: 5,
    Header: 'Amount',
    accessor: 'comissionAmount',
    sortable: true,
    Cell: (cell: any) => {
      return cell.row.original.comissionAmount + ' USDT';
    }
  },
  {
    id: 6,
    Header: 'Reward Points',
    accessor: 'rewardPoints',
    sortable: true,
    Cell: (cell: any) => {
      return cell.row.original.rewardPoints + ' USDT';
    }
  },
  {
    id: 7,
    Header: 'Shopping Points',
    accessor: 'shoppingPoints',
    sortable: true,
    Cell: (cell: any) => {
      return cell.row.original.shoppingPoints + ' USDT';
    }
  },
  {
    id: 8,
    Header: 'ULX Points',
    accessor: 'ulxPoints',
    sortable: true,
    Cell: (cell: any) => {
      return cell.row.original.ulxPoints;
    }
  },
  {
    id: 9,
    Header: 'Remark',
    accessor: 'remark',
    Cell: (cell: any) => {
      return cell.row.original.remark;
    }
  },
  {
    id: 10,
    Header: 'Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  }
];
