import { useEffect, useState } from 'react';
import { Button, CardBody, Col, Row } from 'reactstrap';
import { SearchInputFilter } from '../../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../../Components/Custom/SearchSubmitButton';
import { Modal } from '../../../../Components/Custom/modal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { handleSetUrlSearchParams } from '../../../../helpers/helpers';
import {
  changePermissions,
  getSuperAdminById,
  removeUserById,
  updateSuperUserPermissions
} from '../slice';
import { Toggle } from '../../../../Components/Custom/Toggle';
import { SuperAdminUserPermissions } from '../types';

export const GetUserById = ({
  setParams,
  params
}: {
  setParams: any;
  params: URLSearchParams;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.superAdmin);
  const id = params.get('userId');
  const [userId, setUserId] = useState(id || '');

  useEffect(() => {
    if (userId) {
      dispatch(getSuperAdminById(userId));
    }
  }, [id]);

  const handleSearchUser = (e: any) => {
    e.preventDefault();
    setParams({ userId });
  };

  const handleCloseModal = () => {
    dispatch(removeUserById());
    handleSetUrlSearchParams('userId', '', setParams);
    setUserId('');
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    if (user?.permissions) {
      const permissions = { ...user?.permissions };
      permissions[name as keyof SuperAdminUserPermissions] =
        !permissions[name as keyof SuperAdminUserPermissions];
      dispatch(changePermissions(permissions));
    }
  };

  return (
    <>
      <form onSubmit={handleSearchUser}>
        <CardBody className='border border-dashed border-end-0 border-start-0'>
          <Row>
            <Col md={3} sm={4}>
              <SearchInputFilter
                placeholder={'Search User ID'}
                onChange={({ target }: any) => setUserId(target.value)}
                value={userId}
              />
            </Col>
            <Col md={3} sm={4}>
              <SearchSubmitButton />
            </Col>
          </Row>
        </CardBody>
      </form>

      <Modal
        title='User Permissions'
        isOpen={user ? true : false}
        setIsOpen={handleCloseModal}
        actions={
          <>
            <>
              <Button
                color='primary'
                outline
                onClick={handleCloseModal}
                className='m-1'>
                Cancel
              </Button>
              <Button
                color='primary'
                className='m-1'
                onClick={() => {
                  dispatch(updateSuperUserPermissions(user && user.id));
                  handleCloseModal();
                }}>
                Save
              </Button>
            </>
          </>
        }>
        <>
          <div className='d-flex justify-content-lg-between'>
            <p>Super Admin</p>
            <Toggle
              checked={user?.permissions.superAdmin}
              name='superAdmin'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Users</p>
            <Toggle
              checked={user?.permissions.users}
              name='users'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Package Orders</p>
            <Toggle
              checked={user?.permissions.packageOrders}
              name='packageOrders'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Ranking</p>
            <Toggle
              checked={user?.permissions.ranking}
              name='ranking'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Rank Reward</p>
            <Toggle
              checked={user?.permissions.rankRewards}
              name='rankRewards'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Withdrawals</p>
            <Toggle
              checked={user?.permissions.withdrawals}
              name='withdrawals'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Director Info</p>
            <Toggle
              checked={user?.permissions.director}
              name='director'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Teams</p>
            <Toggle
              checked={user?.permissions.teams}
              name='teams'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Bonuses</p>
            <Toggle
              checked={user?.permissions.bonuses}
              name='bonuses'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Shop</p>
            <Toggle
              checked={user?.permissions.shop}
              name='shop'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Campaigns</p>
            <Toggle
              checked={user?.permissions.campaign}
              name='campaign'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>News</p>
            <Toggle
              checked={user?.permissions.news}
              name='news'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Promotions</p>
            <Toggle
              checked={user?.permissions.promotions}
              name='promotions'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Content Blocks</p>
            <Toggle
              checked={user?.permissions.contentBlocks}
              name='contentBlocks'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Events</p>
            <Toggle
              checked={user?.permissions.events}
              name='events'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Event tickets</p>
            <Toggle
              checked={user?.permissions.eventTickets}
              name='eventTickets'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Help Desk</p>
            <Toggle
              checked={user?.permissions.helpDesk}
              name='helpDesk'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> FAQ</p>
            <Toggle
              checked={user?.permissions.faq}
              name='faq'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Licenses</p>
            <Toggle
              checked={user?.permissions.licences}
              name='licences'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Package Payment Admin</p>
            <Toggle
              checked={user?.permissions.packagePaymentAdmin}
              name='packagePaymentAdmin'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Balance change</p>
            <Toggle
              checked={user?.permissions.extraordinaryBonuses}
              name='extraordinaryBonuses'
              onChange={handleChange}
            />
          </div>
        </>
      </Modal>
    </>
  );
};
