import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { PackageRankHistory, PackageRankHistoryState } from './types';

export const getPackageRankHistory = createAsyncThunk(
  'packageRankHistory/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/package/package-rank/history', {
      params
    });
    return response.data.data;
  }
);

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as PackageRankHistoryState;

const packageRankHistorySlice = createSlice({
  name: 'packageRankHistory',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getPackageRankHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPackageRankHistory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.usersPackageRankHistory as PackageRankHistory[];
      state.totalCount = payload.count;
    });
    builder.addCase(getPackageRankHistory.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default packageRankHistorySlice.reducer;
