import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../helpers/api_helper';
import {
  AddEditCategory,
  AddEditQuestion,
  Category,
  FaqStateType,
  Question,
  QuestionById
} from './types';
import { toast } from 'react-toastify';

export const getQuestions = createAsyncThunk(
  'questions/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/support/question', {
      params
    });
    return response.data.data.questions as Question[];
  }
);

export const getCategories = createAsyncThunk('categories/fetch', async () => {
  const response = await axiosInstance.get('/support/category');
  return response.data.data.categories as Category[];
});

export const getQuestionById = createAsyncThunk(
  'questions/getQuestionById',
  async (id: number) => {
    const response = await axiosInstance.get(`/support/question/${id}`);

    const question = {
      ...response.data.data.question,
      keywords: response.data.data.question.keywords
        .map((key: any) => key.value)
        .join()
    };

    const newQuestion = {
      ...question,
      keywords: question.keywords.replace(/,/g, ' ')
    };

    return newQuestion as QuestionById;
  }
);

export const addQuestion = createAsyncThunk(
  'question/add',
  async (data: AddEditQuestion, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/support/question', data);
      toast.success('Successfully added question');
      return response.data.data.question as Question;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const editQuestion = createAsyncThunk(
  'question/edit',
  async (data: AddEditQuestion, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/support/question', data);
      toast.success('Successfully changed question');
      return response.data.data.question as Question;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const editCategory = createAsyncThunk(
  'category/edit',
  async (
    { data, image }: { data: AddEditCategory; image?: FormData },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosInstance.put('/support/category', data);

      if (image) {
        dispatch(
          editCategoryImage({ id: response.data.data.category.id, image })
        );
      } else {
        toast.success('Successfully changed a category');
      }

      return response.data.data.category as Category;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const addCategory = createAsyncThunk(
  'category/add',
  async (
    { data, image }: { data: AddEditCategory; image?: FormData },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosInstance.post('/support/category', data);

      if (image) {
        dispatch(
          addCategoryImage({ id: response.data.data.category.id, image })
        );
      } else {
        toast.success('Successfully added a category');
        return response.data.data.category as Category;
      }
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const addCategoryImage = createAsyncThunk(
  'category/addImage',
  async (
    { id, image }: { id: number; image: FormData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/support/category/${id}/image`,
        image
      );
      toast.success('Successfully added a category');
      return response.data.data.category as Category;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const editCategoryImage = createAsyncThunk(
  'category/editImage',
  async (
    { id, image }: { id: number; image: FormData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        `/support/category/${id}/image`,
        image
      );
      toast.success('Successfully edited a category');
      return response.data.data.category as Category;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/support/category/${id}`);
      toast.success('Successfully deleted a category');
      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  'category/deleteSubCategory',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/support/category/${id}`);
      toast.success('Successfully deleted a category');
      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  'category/deleteQuestion',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/support/question/${id}`);
      toast.success('Successfully deleted a question');
      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

const initialState = {
  questions: [],
  categories: [],
  subCategories: [],
  loading: false,
  questionForEdit: null,
  questionId: null,
  categoryForEdit: null,
  categoryIdForModal: null,
  categoryIdForModal2: null
} as FaqStateType;

const faqSlice = createSlice({
  name: 'getContentBlocks',
  initialState,
  reducers: {
    setQuestionId(state, { payload }) {
      state.questionId = payload;
    },
    removeQuestionId(state) {
      state.questionId = null;
    },
    removeQuestionForEdit(state) {
      state.questionForEdit = null;
    },
    setCategoryIdForModal(state, { payload }) {
      state.categoryIdForModal = payload;
    },
    setCategoryIdForModal2(state, { payload }) {
      state.categoryIdForModal2 = payload;
    },
    setCategoryForEdit(state, { payload }) {
      state.categoryForEdit = payload;
    },
    removeCategoryForEdit(state) {
      state.categoryForEdit = null;
    },
    removeCategoryIdForModal(state) {
      state.categoryIdForModal = null;
    },
    removeCategoryIdForModal2(state) {
      state.categoryIdForModal2 = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getQuestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuestions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.questions = payload;
    });
    builder.addCase(getQuestions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categories = payload;

      payload?.map((category) => {
        return category?.subCategories?.map((sc) => {
          state.subCategories.push(sc);
        });
      });
    });

    builder.addCase(getCategories.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getQuestionById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuestionById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.questionForEdit = payload;
    });
    builder.addCase(getQuestionById.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.questions.push(payload);
    });
    builder.addCase(addQuestion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(editQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.questions.findIndex((q) => q.id === payload.id);
      state.questions[index] = payload;
    });
    builder.addCase(editQuestion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload?.parentId) {
        state.subCategories.push(payload);
      } else {
        if (!payload) return;
        state.categories.push(payload);
      }
    });
    builder.addCase(addCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(editCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.parentId) {
        const index = state.subCategories.findIndex((c) => c.id === payload.id);
        state.subCategories[index] = payload;
      } else {
        const index = state.categories.findIndex((c) => c.id === payload.id);
        state.categories[index] = payload;
      }
    });
    builder.addCase(editCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addCategoryImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategoryImage.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categories.push(payload);
    });
    builder.addCase(addCategoryImage.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(editCategoryImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editCategoryImage.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.categories.findIndex((c) => c.id === payload.id);
      state.categories[index] = payload;
    });
    builder.addCase(editCategoryImage.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categories = state.categories.filter((obj) => obj.id !== payload);
    });
    builder.addCase(deleteCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteSubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSubCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subCategories = state.subCategories.filter(
        (obj) => obj.id !== payload
      );
    });
    builder.addCase(deleteSubCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.questions = state.questions.filter((obj) => obj.id !== payload);
    });
    builder.addCase(deleteQuestion.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const {
  removeQuestionForEdit,
  setQuestionId,
  removeQuestionId,
  setCategoryIdForModal,
  setCategoryIdForModal2,
  removeCategoryForEdit,
  removeCategoryIdForModal,
  removeCategoryIdForModal2,
  setCategoryForEdit
} = faqSlice.actions;

export default faqSlice.reducer;
