import af from '../assets/icons/flags/svg/af.svg';
import ax from '../assets/icons/flags/svg/ax.svg';
import al from '../assets/icons/flags/svg/al.svg';
import dz from '../assets/icons/flags/svg/dz.svg';
import ams from '../assets/icons/flags/svg/as.svg';
import ad from '../assets/icons/flags/svg/ad.svg';
import ao from '../assets/icons/flags/svg/ao.svg';
import ai from '../assets/icons/flags/svg/ai.svg';
import aq from '../assets/icons/flags/svg/aq.svg';
import ag from '../assets/icons/flags/svg/ag.svg';
import ar from '../assets/icons/flags/svg/ar.svg';
import am from '../assets/icons/flags/svg/am.svg';
import aw from '../assets/icons/flags/svg/aw.svg';
import au from '../assets/icons/flags/svg/au.svg';
import at from '../assets/icons/flags/svg/at.svg';
import az from '../assets/icons/flags/svg/az.svg';
import bs from '../assets/icons/flags/svg/bs.svg';
import bh from '../assets/icons/flags/svg/bh.svg';
import bd from '../assets/icons/flags/svg/bd.svg';
import bb from '../assets/icons/flags/svg/bb.svg';
import by from '../assets/icons/flags/svg/by.svg';
import be from '../assets/icons/flags/svg/be.svg';
import bz from '../assets/icons/flags/svg/bz.svg';
import bj from '../assets/icons/flags/svg/bj.svg';
import bm from '../assets/icons/flags/svg/bm.svg';
import bt from '../assets/icons/flags/svg/bt.svg';
import bo from '../assets/icons/flags/svg/bo.svg';
import ba from '../assets/icons/flags/svg/ba.svg';
import bw from '../assets/icons/flags/svg/bw.svg';
import br from '../assets/icons/flags/svg/br.svg';
import io from '../assets/icons/flags/svg/io.svg';
import bn from '../assets/icons/flags/svg/bn.svg';
import bg from '../assets/icons/flags/svg/bg.svg';
import bf from '../assets/icons/flags/svg/bf.svg';
import bi from '../assets/icons/flags/svg/bi.svg';
import kn from '../assets/icons/flags/svg/kn.svg';
import cm from '../assets/icons/flags/svg/cm.svg';
import ca from '../assets/icons/flags/svg/ca.svg';
import cv from '../assets/icons/flags/svg/cv.svg';
import ky from '../assets/icons/flags/svg/ky.svg';
import cf from '../assets/icons/flags/svg/cf.svg';
import td from '../assets/icons/flags/svg/td.svg';
import cl from '../assets/icons/flags/svg/cl.svg';
import cn from '../assets/icons/flags/svg/cn.svg';
import cx from '../assets/icons/flags/svg/cx.svg';
import cc from '../assets/icons/flags/svg/cc.svg';
import co from '../assets/icons/flags/svg/co.svg';
import km from '../assets/icons/flags/svg/km.svg';
import cg from '../assets/icons/flags/svg/cg.svg';
import cd from '../assets/icons/flags/svg/cd.svg';
import ck from '../assets/icons/flags/svg/ck.svg';
import cr from '../assets/icons/flags/svg/cr.svg';
import ci from '../assets/icons/flags/svg/ci.svg';
import hr from '../assets/icons/flags/svg/hr.svg';
import cu from '../assets/icons/flags/svg/cu.svg';
import cy from '../assets/icons/flags/svg/cy.svg';
import cz from '../assets/icons/flags/svg/cz.svg';
import dk from '../assets/icons/flags/svg/dk.svg';
import dj from '../assets/icons/flags/svg/dj.svg';
import dm from '../assets/icons/flags/svg/dm.svg';
import dor from '../assets/icons/flags/svg/do.svg';
import ec from '../assets/icons/flags/svg/ec.svg';
import eg from '../assets/icons/flags/svg/eg.svg';
import sv from '../assets/icons/flags/svg/sv.svg';
import gq from '../assets/icons/flags/svg/gq.svg';
import er from '../assets/icons/flags/svg/er.svg';
import ee from '../assets/icons/flags/svg/ee.svg';
import et from '../assets/icons/flags/svg/et.svg';
import fk from '../assets/icons/flags/svg/fk.svg';
import fo from '../assets/icons/flags/svg/fo.svg';
import fj from '../assets/icons/flags/svg/fj.svg';
import fi from '../assets/icons/flags/svg/fi.svg';
import fr from '../assets/icons/flags/svg/fr.svg';
import gf from '../assets/icons/flags/svg/gf.svg';
import pf from '../assets/icons/flags/svg/pf.svg';
import ga from '../assets/icons/flags/svg/ga.svg';
import gm from '../assets/icons/flags/svg/gm.svg';
import ge from '../assets/icons/flags/svg/ge.svg';
import de from '../assets/icons/flags/svg/de.svg';
import gh from '../assets/icons/flags/svg/gh.svg';
import gi from '../assets/icons/flags/svg/gi.svg';
import gr from '../assets/icons/flags/svg/gr.svg';
import gl from '../assets/icons/flags/svg/gl.svg';
import gd from '../assets/icons/flags/svg/gd.svg';
import gp from '../assets/icons/flags/svg/gp.svg';
import gu from '../assets/icons/flags/svg/gu.svg';
import gt from '../assets/icons/flags/svg/gt.svg';
import gg from '../assets/icons/flags/svg/gg.svg';
import gn from '../assets/icons/flags/svg/gn.svg';
import gw from '../assets/icons/flags/svg/gw.svg';
import gy from '../assets/icons/flags/svg/gy.svg';
import ht from '../assets/icons/flags/svg/ht.svg';
import va from '../assets/icons/flags/svg/va.svg';
import hn from '../assets/icons/flags/svg/hn.svg';
import hk from '../assets/icons/flags/svg/hk.svg';
import hu from '../assets/icons/flags/svg/hu.svg';
import is from '../assets/icons/flags/svg/is.svg';
import ind from '../assets/icons/flags/svg/in.svg';
import id from '../assets/icons/flags/svg/id.svg';
import ir from '../assets/icons/flags/svg/ir.svg';
import iq from '../assets/icons/flags/svg/iq.svg';
import ie from '../assets/icons/flags/svg/ie.svg';
import im from '../assets/icons/flags/svg/im.svg';
import il from '../assets/icons/flags/svg/il.svg';
import it from '../assets/icons/flags/svg/it.svg';
import jm from '../assets/icons/flags/svg/jm.svg';
import jp from '../assets/icons/flags/svg/jp.svg';
import je from '../assets/icons/flags/svg/je.svg';
import jo from '../assets/icons/flags/svg/jo.svg';
import kz from '../assets/icons/flags/svg/kz.svg';
import ke from '../assets/icons/flags/svg/ke.svg';
import ki from '../assets/icons/flags/svg/ki.svg';
import kp from '../assets/icons/flags/svg/kp.svg';
import kr from '../assets/icons/flags/svg/kr.svg';
import kw from '../assets/icons/flags/svg/kw.svg';
import kg from '../assets/icons/flags/svg/kg.svg';
import la from '../assets/icons/flags/svg/la.svg';
import lv from '../assets/icons/flags/svg/lv.svg';
import lb from '../assets/icons/flags/svg/lb.svg';
import ls from '../assets/icons/flags/svg/ls.svg';
import lr from '../assets/icons/flags/svg/lr.svg';
import ly from '../assets/icons/flags/svg/ly.svg';
import li from '../assets/icons/flags/svg/li.svg';
import lt from '../assets/icons/flags/svg/lt.svg';
import lu from '../assets/icons/flags/svg/lu.svg';
import mo from '../assets/icons/flags/svg/mo.svg';
import mk from '../assets/icons/flags/svg/mk.svg';
import mg from '../assets/icons/flags/svg/mg.svg';
import mw from '../assets/icons/flags/svg/mw.svg';
import my from '../assets/icons/flags/svg/my.svg';
import mv from '../assets/icons/flags/svg/mv.svg';
import ml from '../assets/icons/flags/svg/ml.svg';
import mt from '../assets/icons/flags/svg/mt.svg';
import mh from '../assets/icons/flags/svg/mh.svg';
import mq from '../assets/icons/flags/svg/mq.svg';
import mr from '../assets/icons/flags/svg/mr.svg';
import mu from '../assets/icons/flags/svg/mu.svg';
import yt from '../assets/icons/flags/svg/yt.svg';
import mx from '../assets/icons/flags/svg/mx.svg';
import fm from '../assets/icons/flags/svg/fm.svg';
import md from '../assets/icons/flags/svg/md.svg';
import mc from '../assets/icons/flags/svg/mc.svg';
import mn from '../assets/icons/flags/svg/mn.svg';
import me from '../assets/icons/flags/svg/me.svg';
import ms from '../assets/icons/flags/svg/ms.svg';
import ma from '../assets/icons/flags/svg/ma.svg';
import mz from '../assets/icons/flags/svg/mz.svg';
import mm from '../assets/icons/flags/svg/mm.svg';
import na from '../assets/icons/flags/svg/na.svg';
import nr from '../assets/icons/flags/svg/nr.svg';
import np from '../assets/icons/flags/svg/np.svg';
import nl from '../assets/icons/flags/svg/nl.svg';
import nc from '../assets/icons/flags/svg/nc.svg';
import nz from '../assets/icons/flags/svg/nz.svg';
import ni from '../assets/icons/flags/svg/ni.svg';
import ne from '../assets/icons/flags/svg/ne.svg';
import ng from '../assets/icons/flags/svg/ng.svg';
import nu from '../assets/icons/flags/svg/nu.svg';
import nf from '../assets/icons/flags/svg/nf.svg';
import mp from '../assets/icons/flags/svg/mp.svg';
import no from '../assets/icons/flags/svg/no.svg';
import om from '../assets/icons/flags/svg/om.svg';
import pk from '../assets/icons/flags/svg/pk.svg';
import pw from '../assets/icons/flags/svg/pw.svg';
import ps from '../assets/icons/flags/svg/ps.svg';
import pa from '../assets/icons/flags/svg/pa.svg';
import pg from '../assets/icons/flags/svg/pg.svg';
import py from '../assets/icons/flags/svg/py.svg';
import pe from '../assets/icons/flags/svg/pe.svg';
import ph from '../assets/icons/flags/svg/ph.svg';
import pn from '../assets/icons/flags/svg/pn.svg';
import pl from '../assets/icons/flags/svg/pl.svg';
import pt from '../assets/icons/flags/svg/pt.svg';
import pr from '../assets/icons/flags/svg/pr.svg';
import sl from '../assets/icons/flags/svg/sl.svg';
import qa from '../assets/icons/flags/svg/qa.svg';
import ro from '../assets/icons/flags/svg/ro.svg';
import ru from '../assets/icons/flags/svg/ru.svg';
import rw from '../assets/icons/flags/svg/rw.svg';
import re from '../assets/icons/flags/svg/re.svg';
import bl from '../assets/icons/flags/svg/bl.svg';
import sh from '../assets/icons/flags/svg/sh.svg';
import lc from '../assets/icons/flags/svg/lc.svg';
import mf from '../assets/icons/flags/svg/mf.svg';
import pm from '../assets/icons/flags/svg/pm.svg';
import vc from '../assets/icons/flags/svg/vc.svg';
import ws from '../assets/icons/flags/svg/ws.svg';
import sm from '../assets/icons/flags/svg/sm.svg';
import st from '../assets/icons/flags/svg/st.svg';
import sa from '../assets/icons/flags/svg/sa.svg';
import sn from '../assets/icons/flags/svg/sn.svg';
import rs from '../assets/icons/flags/svg/rs.svg';
import sc from '../assets/icons/flags/svg/sc.svg';
import sg from '../assets/icons/flags/svg/sg.svg';
import sk from '../assets/icons/flags/svg/sk.svg';
import sb from '../assets/icons/flags/svg/sb.svg';
import so from '../assets/icons/flags/svg/so.svg';
import za from '../assets/icons/flags/svg/za.svg';
import ss from '../assets/icons/flags/svg/ss.svg';
import gs from '../assets/icons/flags/svg/gs.svg';
import es from '../assets/icons/flags/svg/es.svg';
import lk from '../assets/icons/flags/svg/lk.svg';
import sd from '../assets/icons/flags/svg/sd.svg';
import sr from '../assets/icons/flags/svg/sr.svg';
import sj from '../assets/icons/flags/svg/sj.svg';
import sz from '../assets/icons/flags/svg/sz.svg';
import se from '../assets/icons/flags/svg/se.svg';
import ch from '../assets/icons/flags/svg/ch.svg';
import sy from '../assets/icons/flags/svg/sy.svg';
import tw from '../assets/icons/flags/svg/tw.svg';
import tj from '../assets/icons/flags/svg/tj.svg';
import tz from '../assets/icons/flags/svg/tz.svg';
import th from '../assets/icons/flags/svg/th.svg';
import tl from '../assets/icons/flags/svg/tl.svg';
import tg from '../assets/icons/flags/svg/tg.svg';
import tk from '../assets/icons/flags/svg/tk.svg';
import to from '../assets/icons/flags/svg/to.svg';
import tt from '../assets/icons/flags/svg/tt.svg';
import tn from '../assets/icons/flags/svg/tn.svg';
import tr from '../assets/icons/flags/svg/tr.svg';
import tm from '../assets/icons/flags/svg/tm.svg';
import tc from '../assets/icons/flags/svg/tc.svg';
import tv from '../assets/icons/flags/svg/tv.svg';
import ug from '../assets/icons/flags/svg/ug.svg';
import ua from '../assets/icons/flags/svg/ua.svg';
// import ae from '../assets/icons/flags/svg/ae.svg';
import gb from '../assets/icons/flags/svg/gb.svg';
// import us from '../assets/icons/flags/svg/us.svg';
import uy from '../assets/icons/flags/svg/uy.svg';
import uz from '../assets/icons/flags/svg/uz.svg';
import vu from '../assets/icons/flags/svg/vu.svg';
import ve from '../assets/icons/flags/svg/ve.svg';
import vn from '../assets/icons/flags/svg/vn.svg';
// import vg from '../assets/icons/flags/svg/vg.svg';
import vi from '../assets/icons/flags/svg/vi.svg';
import wf from '../assets/icons/flags/svg/wf.svg';
import ye from '../assets/icons/flags/svg/ye.svg';
import zm from '../assets/icons/flags/svg/zm.svg';
import zw from '../assets/icons/flags/svg/zw.svg';
// import si from '../assets/icons/flags/svg/si.svg';

const countries = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
    flag: af,
    checked: false
  },
  {
    name: 'Aland Islands',
    dial_code: '+358',
    code: 'AX',
    flag: ax,
    checked: false
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
    flag: al,
    checked: false
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
    flag: dz,
    checked: false
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1684',
    code: 'AS',
    flag: ams,
    checked: false
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
    flag: ad,
    checked: false
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
    flag: ao,
    checked: false
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'AI',
    flag: ai,
    checked: false
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'AQ',
    flag: aq,
    checked: false
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
    flag: ag,
    checked: false
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    flag: ar,
    checked: false
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    flag: am,
    checked: false
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
    flag: aw,
    checked: false
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
    flag: au,
    checked: false
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
    flag: at,
    checked: false
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    flag: az,
    checked: false
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS',
    flag: bs,
    checked: false
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
    flag: bh,
    checked: false
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
    flag: bd,
    checked: false
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB',
    flag: bb,
    checked: false
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    flag: by,
    checked: false
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    flag: be,
    checked: false
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
    flag: bz,
    checked: false
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
    flag: bj,
    checked: false
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'BM',
    flag: bm,
    checked: false
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
    flag: bt,
    checked: false
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO',
    flag: bo,
    checked: false
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
    flag: ba,
    checked: false
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
    flag: bw,
    checked: false
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    flag: br,
    checked: false
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
    flag: io,
    checked: false
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN',
    flag: bn,
    checked: false
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    flag: bg,
    checked: false
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
    flag: bf,
    checked: false
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
    flag: bi,
    checked: false
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
    flag: kn,
    checked: false
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
    flag: cm,
    checked: false
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
    flag: ca,
    checked: false
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
    flag: cv,
    checked: false
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'KY',
    flag: ky,
    checked: false
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
    flag: cf,
    checked: false
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
    flag: td,
    checked: false
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
    flag: cl,
    checked: false
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
    flag: cn,
    checked: false
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
    flag: cx,
    checked: false
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
    flag: cc,
    checked: false
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
    flag: co,
    checked: false
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
    flag: km,
    checked: false
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG',
    flag: cg,
    checked: false
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD',
    flag: cd,
    checked: false
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
    flag: ck,
    checked: false
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
    flag: cr,
    checked: false
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'CI',
    flag: ci,
    checked: false
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    flag: hr,
    checked: false
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
    flag: cu,
    checked: false
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
    flag: cy,
    checked: false
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    flag: cz,
    checked: false
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    flag: dk,
    checked: false
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
    flag: dj,
    checked: false
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM',
    flag: dm,
    checked: false
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO',
    flag: dor,
    checked: false
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
    flag: ec,
    checked: false
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
    flag: eg,
    checked: false
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
    flag: sv,
    checked: false
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
    flag: gq,
    checked: false
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
    flag: er,
    checked: false
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    flag: ee,
    checked: false
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
    flag: et,
    checked: false
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK',
    flag: fk,
    checked: false
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
    flag: fo,
    checked: false
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
    flag: fj,
    checked: false
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
    flag: fi,
    checked: false
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR',
    flag: fr,
    checked: false
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
    flag: gf,
    checked: false
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    flag: pf,
    checked: false
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
    flag: ga,
    checked: false
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
    flag: gm,
    checked: false
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    flag: ge,
    checked: false
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
    flag: de,
    checked: false
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    flag: gh,
    checked: false
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
    flag: gi,
    checked: false
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
    flag: gr,
    checked: false
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
    flag: gl,
    checked: false
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'GD',
    flag: gd,
    checked: false
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
    flag: gp,
    checked: false
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU',
    flag: gu,
    checked: false
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
    flag: gt,
    checked: false
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
    flag: gg,
    checked: false
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
    flag: gn,
    checked: false
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
    flag: gw,
    checked: false
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    code: 'GY',
    flag: gy,
    checked: false
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
    flag: ht,
    checked: false
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA',
    flag: va,
    checked: false
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
    flag: hn,
    checked: false
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
    flag: hk,
    checked: false
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    flag: hu,
    checked: false
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
    flag: is,
    checked: false
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
    flag: ind,
    checked: false
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    flag: id,
    checked: false
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    code: 'IR',
    flag: ir,
    checked: false
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
    flag: iq,
    checked: false
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    flag: ie,
    checked: false
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
    flag: im,
    checked: false
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
    flag: il,
    checked: false
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
    flag: it,
    checked: false
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM',
    flag: jm,
    checked: false
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
    flag: jp,
    checked: false
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
    flag: je,
    checked: false
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
    flag: jo,
    checked: false
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    code: 'KZ',
    flag: kz,
    checked: false
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    flag: ke,
    checked: false
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
    flag: ki,
    checked: false
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    code: 'KP',
    flag: kp,
    checked: false
  },
  {
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    code: 'KR',
    flag: kr,
    checked: false
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
    flag: kw,
    checked: false
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    flag: kg,
    checked: false
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'LA',
    flag: la,
    checked: false
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    flag: lv,
    checked: false
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
    flag: lb,
    checked: false
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
    flag: ls,
    checked: false
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
    flag: lr,
    checked: false
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY',
    flag: ly,
    checked: false
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
    flag: li,
    checked: false
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    flag: lt,
    checked: false
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    flag: lu,
    checked: false
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
    flag: mo,
    checked: false
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    code: 'MK',
    flag: mk,
    checked: false
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
    flag: mg,
    checked: false
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
    flag: mw,
    checked: false
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    flag: my,
    checked: false
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
    flag: mv,
    checked: false
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
    flag: ml,
    checked: false
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
    flag: mt,
    checked: false
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    flag: mh,
    checked: false
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
    flag: mq,
    checked: false
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
    flag: mr,
    checked: false
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
    flag: mu,
    checked: false
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
    flag: yt,
    checked: false
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    flag: mx,
    checked: false
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    code: 'FM',
    flag: fm,
    checked: false
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD',
    flag: md,
    checked: false
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
    flag: mc,
    checked: false
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
    flag: mn,
    checked: false
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
    flag: me,
    checked: false
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
    flag: ms,
    checked: false
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
    flag: ma,
    checked: false
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
    flag: mz,
    checked: false
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM',
    flag: mm,
    checked: false
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
    flag: na,
    checked: false
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
    flag: nr,
    checked: false
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
    flag: np,
    checked: false
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    flag: nl,
    checked: false
  },

  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
    flag: nc,
    checked: false
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
    flag: nz,
    checked: false
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
    flag: ni,
    checked: false
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
    flag: ne,
    checked: false
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    flag: ng,
    checked: false
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
    flag: nu,
    checked: false
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
    flag: nf,
    checked: false
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP',
    flag: mp,
    checked: false
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
    flag: no,
    checked: false
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
    flag: om,
    checked: false
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
    flag: pk,
    checked: false
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
    flag: pw,
    checked: false
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS',
    flag: ps,
    checked: false
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
    flag: pa,
    checked: false
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
    flag: pg,
    checked: false
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
    flag: py,
    checked: false
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
    flag: pe,
    checked: false
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
    flag: ph,
    checked: false
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    code: 'PN',
    flag: pn,
    checked: false
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
    flag: pl,
    checked: false
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    flag: pt,
    checked: false
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR',
    flag: pr,
    checked: false
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
    flag: qa,
    checked: false
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
    flag: ro,
    checked: false
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    flag: ru,
    checked: false
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
    flag: rw,
    checked: false
  },
  {
    name: 'Reunion',
    dial_code: '+262',
    code: 'RE',
    flag: re,
    checked: false
  },
  {
    name: 'Saint Barthelemy',
    dial_code: '+590',
    code: 'BL',
    flag: bl,
    checked: false
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH',
    flag: sh,
    checked: false
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN',
    flag: kn,
    checked: false
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'LC',
    flag: lc,
    checked: false
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF',
    flag: mf,
    checked: false
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
    flag: pm,
    checked: false
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC',
    flag: vc,
    checked: false
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
    flag: ws,
    checked: false
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
    flag: sm,
    checked: false
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST',
    flag: st,
    checked: false
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    flag: sa,
    checked: false
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
    flag: sn,
    checked: false
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
    flag: rs,
    checked: false
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
    flag: sc,
    checked: false
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
    flag: sl,
    checked: false
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    flag: sg,
    checked: false
  },
  // {
  //   name: 'Slovenia',
  //   dial_code: '+386',
  //   code: 'SI',
  //   flag: si,
  //   checked: false,
  // },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    flag: sk,
    checked: false
  },

  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    flag: sb,
    checked: false
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
    flag: so,
    checked: false
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    flag: za,
    checked: false
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS',
    flag: ss,
    checked: false
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
    flag: gs,
    checked: false
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
    flag: es,
    checked: false
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
    flag: lk,
    checked: false
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
    flag: sd,
    checked: false
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
    flag: sr,
    checked: false
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ',
    flag: sj,
    checked: false
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
    flag: sz,
    checked: false
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    flag: se,
    checked: false
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    flag: ch,
    checked: false
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY',
    flag: sy,
    checked: false
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    code: 'TW',
    flag: tw,
    checked: false
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    flag: tj,
    checked: false
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    code: 'TZ',
    flag: tz,
    checked: false
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
    flag: th,
    checked: false
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL',
    flag: tl,
    checked: false
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
    flag: tg,
    checked: false
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
    flag: tk,
    checked: false
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
    flag: to,
    checked: false
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT',
    flag: tt,
    checked: false
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
    flag: tn,
    checked: false
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    flag: tr,
    checked: false
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    flag: tm,
    checked: false
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC',
    flag: tc,
    checked: false
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
    flag: tv,
    checked: false
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
    flag: ug,
    checked: false
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    flag: ua,
    checked: false
  },
  // {
  //   name: 'United Arab Emirates',
  //   dial_code: '+971',
  //   code: 'AE',
  //   flag: ae,
  //   checked: false,
  // },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    flag: gb,
    checked: false
  },
  // {
  //   name: 'United States',
  //   dial_code: '+1',
  //   code: 'US',
  //   flag: us,
  //   checked: false,
  // },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
    flag: uy,
    checked: false
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    flag: uz,
    checked: false
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
    flag: vu,
    checked: false
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    code: 'VE',
    flag: ve,
    checked: false
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN',
    flag: vn,
    checked: false
  },
  // {
  //   name: 'Virgin Islands, British',
  //   dial_code: '+1284',
  //   code: 'VG',
  //   flag: vg,
  //   checked: false,
  // },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    code: 'VI',
    flag: vi,
    checked: false
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
    flag: wf,
    checked: false
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
    flag: ye,
    checked: false
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
    flag: zm,
    checked: false
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
    flag: zw,
    checked: false
  }
];

export default countries;
