import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import {
  PackageRankReward,
  PackageRankRewardStatus,
  PackageRankRewardsState
} from './types';
import { toast } from 'react-toastify';

export const getPackageRankRewards = createAsyncThunk(
  'packageRankRewards/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/package/package-rank/reward', {
      params
    });
    return response.data.data;
  }
);

export const markPackageRankRewardDelivered = createAsyncThunk(
  'packageRankRewards/delivered',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        '/package/package-rank/reward/status/delivered',
        {
          userPackageRankRewardId: id
        }
      );
      toast.success(response.data.message);

      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as PackageRankRewardsState;

const packageRankRewardsSlice = createSlice({
  name: 'packageRankRewards',
  initialState,
  reducers: {
    ordered: (state) => {
      state.loading = true;
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getPackageRankRewards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPackageRankRewards.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.usersPackageRankRewards as PackageRankReward[];
      state.totalCount = payload.count;
    });
    builder.addCase(getPackageRankRewards.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(markPackageRankRewardDelivered.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      markPackageRankRewardDelivered.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        const index = state.data.findIndex((p) => p.id === payload);
        state.data[index].status = PackageRankRewardStatus.DELIVERED;
      }
    );
    builder.addCase(markPackageRankRewardDelivered.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { ordered } = packageRankRewardsSlice.actions;
export default packageRankRewardsSlice.reducer;
