import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { GlobalPoolBonusState, GlobalPoolBonusType } from './types';
import { toast } from 'react-toastify';

export const getGlobalPoolBonus = createAsyncThunk(
  'getGlobalPoolBonus/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(`/bonus/global-pool`, {
      params
    });
    if (response.data.data.userGlobalPoolBonus === null) {
      toast.error('Please type valid Order ID');
    }
    return response.data.data as GlobalPoolBonusType;
  }
);

const initialState = {
  data: {},
  loading: false
} as GlobalPoolBonusState;

const getGlobalPoolBonusSlice = createSlice({
  name: 'getGlobalPoolBonus',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalPoolBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGlobalPoolBonus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getGlobalPoolBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default getGlobalPoolBonusSlice.reducer;
