import moment from 'moment';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'Type',
    accessor: 'type',
    Cell: (cell: any) => {
      return (
        <span className='badge text-uppercase badge-soft-primary'>
          {cell.row.original.name}
        </span>
      );
    }
  },
  {
    id: 3,
    Header: 'User ID',
    accessor: 'bonusUserId',
    Cell: (cell: any) => {
      return <>{cell.row.original.bonusUserId || ''}</>;
    }
  },
  {
    id: 4,
    Header: 'Username',
    accessor: 'username',
    Cell: (cell: any) => {
      return <>{cell.row.original.username || '---'}</>;
    }
  },

  {
    id: 5,
    Header: 'Amount',
    accessor: 'amount',
    Cell: (cell: any) => {
      return cell?.value ? usdFormatter.format(cell?.value) : '---';
    }
  },

  {
    id: 6,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },

  {
    id: 7,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Pending
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Paid
            </span>
          );

        default:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              Not paid
            </span>
          );
      }
    }
  }
];
