import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import {
  ContentBlockAddType,
  ContentBlockStateType,
  ContentBlockType
} from './types';
import { toast } from 'react-toastify';

export const getContentBlocks = createAsyncThunk(
  'getContentBlocks/fetch',
  async () => {
    const response = await axiosInstance.get('/content-block');
    return response.data.data.contentBlocks as ContentBlockType[];
  }
);

export const getContentBlockById = createAsyncThunk(
  'getContentBlocks/getById',
  async ({ id }: { id: number }) => {
    const response = await axiosInstance.get(`/content-block/${id}/1`);
    return response.data.data.contentBlock as ContentBlockType;
  }
);

export const addContentBlock = createAsyncThunk(
  'getContentBlocks/add',
  async (data: ContentBlockAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/content-block', data);
      toast.success('Successfully added content block');
      return response.data.data.contentBlock as ContentBlockType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const editContentBlock = createAsyncThunk(
  'getContentBlocks/edit',
  async (data: ContentBlockAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/content-block', data);
      toast.success('Successfully changed content block');
      return response.data.data.contentBlock as ContentBlockType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

const initialState = {
  data: [],
  contentBlockForEdit: null,
  loading: false
} as ContentBlockStateType;

const contentBlocksSlice = createSlice({
  name: 'getContentBlocks',
  initialState,
  reducers: {
    removeContentBlockForEdit(state) {
      state.contentBlockForEdit = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getContentBlocks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContentBlocks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getContentBlocks.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addContentBlock.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addContentBlock.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.push(payload);
    });
    builder.addCase(addContentBlock.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getContentBlockById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContentBlockById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.contentBlockForEdit = payload;
    });
    builder.addCase(getContentBlockById.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(editContentBlock.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editContentBlock.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index] = payload;
    });
    builder.addCase(editContentBlock.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { removeContentBlockForEdit } = contentBlocksSlice.actions;

export default contentBlocksSlice.reducer;
