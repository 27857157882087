import React from 'react';
import { useAppSelector } from '../../../../../app/hooks';

import { PageHeader } from '../../../../../Components/Custom/PageHeader';

import {
  Icon,
  IconArrowPolygon,
  IconCheckRanking,
  IconTier5,
  IconTier6,
  IconTier7,
  IconTier8
} from '../../../../../styles/Icons';

import { CaptionText, FooterText } from '../../../../../styles/FontStyles';

import { color } from '../../../../../styles/GlobalStyles';

import {
  ArrowTier,
  CheckboxRank,
  TierBars,
  TierCircle,
  TierReward
} from '../BangkokEvent/BangkokEventStyled';

import { formatUSD } from '../../../../../helpers/helpers';

import { Col, Progress } from 'reactstrap';

import '../BangkokEvent/event.css';

export const CruisesEvent: React.FC = () => {
  const { cruisesPromotion } = useAppSelector((state) => state.userDetails);

  const IconTiers = (id: number) => {
    if (id === 1) {
      return <IconTier5 />;
    } else if (id === 2) {
      return <IconTier6 />;
    } else if (id === 3) {
      return <IconTier7 />;
    } else if (id === 4) {
      return <IconTier8 />;
    }
  };

  const getProgressLabelPosition = (id: number) => {
    if (id === 1) {
      return { position: 'absolute', left: 0, top: -22 };
    } else if (id === 2) {
      return { position: 'absolute', right: '65%', top: -22 };
    } else if (id === 3) {
      return { position: 'absolute', right: '40%', top: -22 };
    } else if (id === 4) {
      return { position: 'absolute', right: '0', top: -22 };
    }
  };

  const getProgressWidth = (id: number) => {
    if (id === 1) {
      return '5%';
    } else if (id === 2) {
      return '20%';
    } else if (id === 3) {
      return '25%';
    } else if (id === 4) {
      return '50%';
    }
  };

  const showPersonalSalesTierAmount = (i: number, item: any) => {
    if (i === 0 && cruisesPromotion?.personalSalesAmount <= 7000) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.personalSalesAmount)}</div>
      );
    } else if (
      i === 1 &&
      cruisesPromotion?.personalSalesAmount > 7000 &&
      cruisesPromotion?.personalSalesAmount <= 10000
    ) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.personalSalesAmount)}</div>
      );
    } else if (
      i === 2 &&
      cruisesPromotion?.personalSalesAmount > 10000 &&
      cruisesPromotion?.personalSalesAmount <= 20000
    ) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.personalSalesAmount)}</div>
      );
    } else if (
      i === 3 &&
      cruisesPromotion?.personalSalesAmount > 20000 &&
      cruisesPromotion?.personalSalesAmount <= 25000
    ) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.personalSalesAmount)}</div>
      );
    } else if (
      i === 3 &&
      cruisesPromotion?.personalSalesAmount >
        item.personalSalesProgress.required
    ) {
      return (
        <div key={i}>{formatUSD(item.personalSalesProgress.required)}</div>
      );
    }
  };

  const showDirectVolumeTierAmount = (i: number, item: any) => {
    if (cruisesPromotion?.directVolumeAmount <= 28000 && i === 0) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.directVolumeAmount)}</div>
      );
    } else if (
      i === 1 &&
      cruisesPromotion?.directVolumeAmount > 28000 &&
      cruisesPromotion?.directVolumeAmount <= 40000
    ) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.directVolumeAmount)}</div>
      );
    } else if (
      i === 2 &&
      cruisesPromotion?.directVolumeAmount > 40000 &&
      cruisesPromotion?.directVolumeAmount <= 80000
    ) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.directVolumeAmount)}</div>
      );
    } else if (
      i === 3 &&
      cruisesPromotion?.directVolumeAmount > 80000 &&
      cruisesPromotion?.directVolumeAmount <= 100000
    ) {
      return (
        <div key={i}>{formatUSD(cruisesPromotion?.directVolumeAmount)}</div>
      );
    } else if (
      i === 3 &&
      cruisesPromotion?.directVolumeAmount > item.directVolumeProgres.required
    ) {
      return <div key={i}>{formatUSD(item.directVolumeProgres.required)}</div>;
    }
  };

  const showPaidTeamTierAmount = (i: number, item: any) => {
    if (cruisesPromotion?.paidTeamAmount <= 28000 && i === 0) {
      return <div key={i}>{formatUSD(cruisesPromotion?.paidTeamAmount)}</div>;
    } else if (
      i === 1 &&
      cruisesPromotion?.paidTeamAmount > 28000 &&
      cruisesPromotion?.paidTeamAmount <= 40000
    ) {
      return <div key={i}>{formatUSD(cruisesPromotion?.paidTeamAmount)}</div>;
    } else if (
      i === 2 &&
      cruisesPromotion?.paidTeamAmount > 40000 &&
      cruisesPromotion?.paidTeamAmount <= 80000
    ) {
      return <div key={i}>{formatUSD(cruisesPromotion?.paidTeamAmount)}</div>;
    } else if (
      i === 3 &&
      cruisesPromotion?.paidTeamAmount > 80000 &&
      cruisesPromotion?.paidTeamAmount <= 100000
    ) {
      return <div key={i}>{formatUSD(cruisesPromotion?.paidTeamAmount)}</div>;
    } else if (
      i === 3 &&
      cruisesPromotion?.paidTeamAmount > item.paidTeamProgres.required
    ) {
      return <div key={i}>{formatUSD(item.paidTeamProgres.required)}</div>;
    }
  };

  const showLabelAmount = (i: number, item: any) => {
    if (i === 0 && cruisesPromotion?.personalSalesAmount <= 2500) {
      return 'label';
    } else if (
      i === 1 &&
      cruisesPromotion?.personalSalesAmount > 2500 &&
      cruisesPromotion?.personalSalesAmount <= 5000
    ) {
      return 'label';
    } else if (
      i === 2 &&
      cruisesPromotion?.personalSalesAmount > 5000 &&
      cruisesPromotion?.personalSalesAmount <= 15000
    ) {
      return 'label';
    } else if (
      i === 3 &&
      cruisesPromotion?.personalSalesAmount > 15000 &&
      cruisesPromotion?.personalSalesAmount <= 25000
    ) {
      return 'label';
    } else if (
      i === 3 &&
      cruisesPromotion?.personalSalesAmount >
        item.personalSalesProgress.required
    ) {
      return '';
    }
  };

  interface HasConditionProps {
    hasCondition: boolean;
  }

  const IconArrow: React.FC<HasConditionProps> = ({ hasCondition }) => {
    return (
      <Icon
        style={{ transform: 'rotate(270deg)' }}
        color={hasCondition ? '#4830C1' : '#A9A9A9'}>
        <IconArrowPolygon />
      </Icon>
    );
  };

  return (
    <Col lg={12}>
      <div className='d-flex justify-content-lg-around mb-5'>
        <div>
          <h6>PROMOTION STARTS</h6>
          <h6 style={{ fontWeight: 'bold' }}>February 5, 2024, 00:00 UTC</h6>
        </div>
        <div>
          <h6>PROMOTION ENDS</h6>
          <h6 style={{ fontWeight: 'bold' }}>April 5, 2024, 23:59 UTC</h6>
        </div>
        <div>
          <h6>VALID</h6>
          <h6 style={{ fontWeight: 'bold' }}>60 days</h6>
        </div>
      </div>
      <div>
        <h6>REWARDS</h6>

        {cruisesPromotion?.tiers?.map((item, i) => {
          return (
            <TierReward
              color={item.hasConditionForTier ? '#4830C1' : '#A9A9A9'}
              key={i}>
              <span>
                <Icon>{IconTiers(i + 1)}</Icon>
              </span>
              <div
                style={{
                  color: item.hasConditionForTier ? '#4830C1' : '#A9A9A9'
                }}>
                {item.name} - {item.reward}
              </div>
              {item.hasConditionForTier && (
                <CheckboxRank color={'#4830C1'}>
                  <Icon color={'#FFFFFF'} size='10px'>
                    <IconCheckRanking />
                  </Icon>
                </CheckboxRank>
              )}
            </TierReward>
          );
        })}

        <div
          style={{
            display: 'flex',
            marginTop: '50px',
            alignItems: 'center',
            position: 'relative',
            height: 100
          }}>
          <div
            style={{
              position: 'absolute',
              left: 'calc(5% - 22px)',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <TierCircle
              color={
                cruisesPromotion?.tiers &&
                cruisesPromotion?.tiers[0]?.hasConditionForTier
                  ? '#4830C1'
                  : '#A9A9A9'
              }>
              <Icon>{IconTiers(1)}</Icon>
            </TierCircle>
            <PageHeader title='Tier 1' />
          </div>
          <div
            style={{
              position: 'absolute',
              left: 'calc(25% - 22px)',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <TierCircle
              color={
                cruisesPromotion?.tiers &&
                cruisesPromotion?.tiers[1]?.hasConditionForTier
                  ? '#4830C1'
                  : '#A9A9A9'
              }>
              <Icon>{IconTiers(2)}</Icon>
            </TierCircle>
            <PageHeader title='Tier 2' />
          </div>
          <div
            style={{
              position: 'absolute',
              left: 'calc(50% - 22px)',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <TierCircle
              color={
                cruisesPromotion?.tiers &&
                cruisesPromotion?.tiers[2]?.hasConditionForTier
                  ? '#4830C1'
                  : '#A9A9A9'
              }>
              <Icon>{IconTiers(3)}</Icon>
            </TierCircle>
            <PageHeader title='Tier 3' />
          </div>
          <div
            style={{
              position: 'absolute',
              left: 'calc(95% - 22px)',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <TierCircle
              color={
                cruisesPromotion?.tiers &&
                cruisesPromotion?.tiers[3]?.hasConditionForTier
                  ? '#4830C1'
                  : '#A9A9A9'
              }>
              <Icon>{IconTiers(4)}</Icon>
            </TierCircle>
            <PageHeader title='Tier 4' />
          </div>
          <ArrowTier
            style={{
              position: 'absolute',
              left: 'calc(5% + 10px)',
              right: 'calc(75% + 10px)'
            }}
            hasCondition={
              cruisesPromotion?.tiers &&
              cruisesPromotion?.tiers[0]?.hasConditionForTier
            }></ArrowTier>
          <ArrowTier
            style={{
              position: 'absolute',
              left: 'calc(25% + 10px)',
              right: 'calc(50% + 10px)'
            }}
            hasCondition={
              cruisesPromotion?.tiers &&
              cruisesPromotion?.tiers[1]?.hasConditionForTier
            }
          />
          <ArrowTier
            style={{
              position: 'absolute',
              left: 'calc(50% + 10px)',
              right: 'calc(95% + 10px)',
              width: 'calc(45% - 60px)'
            }}
            hasCondition={
              cruisesPromotion?.tiers &&
              cruisesPromotion?.tiers[2].hasConditionForTier
            }
          />
          <div
            style={{
              position: 'absolute',
              right: 'calc(75%  + 20px)'
            }}>
            <IconArrow
              hasCondition={
                cruisesPromotion?.tiers &&
                cruisesPromotion?.tiers[0]?.hasConditionForTier
              }
            />
          </div>
          <div
            style={{
              position: 'absolute',
              right: 'calc(50%  + 20px)'
            }}>
            <IconArrow
              hasCondition={
                cruisesPromotion?.tiers &&
                cruisesPromotion?.tiers[1]?.hasConditionForTier
              }
            />
          </div>
          <div
            style={{
              position: 'absolute',
              right: 'calc(5%  + 20px)'
            }}>
            <IconArrow
              hasCondition={
                cruisesPromotion?.tiers &&
                cruisesPromotion?.tiers[2]?.hasConditionForTier
              }
            />
          </div>
        </div>
      </div>
      <CaptionText caps mb='12' mt='50'>
        Track your progress
      </CaptionText>
      <h6 className='mb-3'> Personal Sales</h6>
      <CaptionText color={color.colors.neutral900} mb='32'>
        Current: {formatUSD(cruisesPromotion?.personalSalesAmount)} USDT
      </CaptionText>
      <div style={{ position: 'relative' }}>
        {cruisesPromotion?.tiers?.map((item, i) => {
          return (
            <FooterText
              key={i}
              style={getProgressLabelPosition(i + 1)}
              color={color.colors.neutral700}>
              Required: {formatUSD(item.personalSalesProgress.required)} USDT
            </FooterText>
          );
        })}
      </div>
      <TierBars>
        {cruisesPromotion?.tiers?.map((item: any, i: number) => {
          return (
            <>
              <div style={{ width: getProgressWidth(i + 1) }}>
                <Progress
                  value={item.personalSalesProgress.percentage}
                  color={'success'}
                  className='animated-progess custom-progess progress-label'>
                  <div
                    className={showLabelAmount(i, item)}
                    style={{
                      backgroundColor: '#09B29C'
                    }}>
                    {showPersonalSalesTierAmount(i, item)}
                  </div>
                </Progress>
              </div>
            </>
          );
        })}
      </TierBars>
      <h6 className='mb-3 mt-5'>Unilevel Volume</h6>
      <CaptionText color={color.colors.neutral900} mb='32'>
        Current: {formatUSD(cruisesPromotion?.directVolumeAmount)} USDT
      </CaptionText>
      <div style={{ position: 'relative' }}>
        {cruisesPromotion?.tiers?.map((item, i) => {
          return (
            <FooterText
              key={i}
              style={getProgressLabelPosition(i + 1)}
              color={color.colors.neutral700}>
              Required: {formatUSD(item.directVolumeProgres.required)} USDT
            </FooterText>
          );
        })}
      </div>
      <TierBars>
        {cruisesPromotion?.tiers?.map((item: any, i: number) => {
          return (
            <>
              <div style={{ width: getProgressWidth(i + 1) }}>
                <Progress
                  value={item.directVolumeProgres.percentage}
                  color={'success'}
                  className='animated-progess custom-progess progress-label'>
                  <div
                    className={showLabelAmount(i, item)}
                    style={{
                      backgroundColor: '#09B29C'
                    }}>
                    {showDirectVolumeTierAmount(i, item)}
                  </div>
                </Progress>
              </div>
            </>
          );
        })}
      </TierBars>
      <h6 className='mb-3 mt-5'>Paid Team</h6>
      <CaptionText color={color.colors.neutral900} mb='32'>
        Current: {formatUSD(cruisesPromotion?.paidTeamAmount)} USDT
      </CaptionText>
      <div style={{ position: 'relative' }}>
        {cruisesPromotion?.tiers?.map((item, i) => {
          return (
            <FooterText
              key={i}
              style={getProgressLabelPosition(i + 1)}
              color={color.colors.neutral700}>
              Required: {formatUSD(item.paidTeamProgres.required)} USDT
            </FooterText>
          );
        })}
      </div>
      <TierBars>
        {cruisesPromotion?.tiers?.map((item, i) => {
          return (
            <>
              <div style={{ width: getProgressWidth(i + 1) }}>
                <Progress
                  value={item.paidTeamProgres.percentage}
                  color={'success'}
                  className='animated-progess custom-progess progress-label'>
                  <div
                    className={showLabelAmount(i, item)}
                    style={{
                      backgroundColor: '#09B29C'
                    }}>
                    {showPaidTeamTierAmount(i, item)}
                  </div>
                </Progress>
              </div>
            </>
          );
        })}
      </TierBars>
    </Col>
  );
};
