import {
  currencyTypeRender,
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';

import { SetAutostakeDate } from './actions/setAutostakeDate';

import PackageOrdersActions from './actions';
import { calculationStatusForTable } from './slice';
import { Link } from 'react-router-dom';

import { NetworkType, OrderType } from './types';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

export const columns = [
  {
    id: 1,
    Header: 'Order ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'user',
    Cell: (cell: any) => {
      return (
        <Link to={`/users/${cell.value.id}`} state={{ tab: '3' }}>
          {cell.value.id}
        </Link>
      );
    }
  },
  {
    id: 3,
    Header: 'User',
    accessor: 'user',
    Cell: (cell: any) => {
      return (
        <>
          {cell.value.name} {cell.value.surname}
        </>
      );
    }
  },

  {
    id: 4,
    Header: 'Package Name',
    accessor: 'package',
    Cell: (cell: any) => {
      return cell?.value?.name;
    }
  },

  {
    id: 5,
    Header: 'Type',
    accessor: 'orderType',
    Cell: (cell: any) => {
      switch (cell.value) {
        case OrderType.ORDER:
          return <div>Order</div>;

        case OrderType.PACKAGE:
          return <div>Package</div>;

        case OrderType.LICENCE:
          return <div>Licence</div>;

        case OrderType.COMBO:
          return <div>Combo</div>;
      }
    }
  },

  {
    id: 6,
    Header: 'Amount',
    accessor: 'package',
    Cell: (cell: any) => {
      return cell?.value?.price
        ? usdFormatter.format(cell?.value?.price)
        : '---';
    }
  },

  {
    id: 7,
    Header: 'Payment address',
    accessor: 'paymentAddress',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },

  {
    id: 8,
    Header: 'Network type',
    accessor: 'networkType',
    Cell: (cell: any) => {
      return (
        <>
          {cell?.value ? (
            cell.value
          ) : (
            <>
              Multiple{' '}
              <i
                id={`tooltipTop${cell?.row?.original?.id}`}
                className='ri-information-line'></i>
              <UncontrolledTooltip
                placement='top'
                target={`tooltipTop${cell?.row?.original?.id}`}
                className='mytooltip'>
                {cell?.row?.original?.payments.map(
                  (payment: any, i: number) => (
                    <tr key={i}>
                      <td>
                        {payment.amount}{' '}
                        {currencyTypeRender(payment.currencyType)}
                      </td>
                      <td>
                        {moment(payment.dateCreated).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </td>
                    </tr>
                  )
                )}
                {cell.row.original?.amountToPay > 0 && (
                  <tr>
                    <td>{cell.row.original?.amountToPay} USDT (Tether)</td>
                    <td>PENDING</td>
                  </tr>
                )}
              </UncontrolledTooltip>
            </>
          )}
        </>
      );
    }
  },
  {
    id: 9,
    Header: 'Promo',
    accessor: 'isPromo',
    Cell: (cell: any) => {
      return (
        <div>
          {cell?.row?.original?.package?.isPromo ? (
            <span>&#9989;</span>
          ) : (
            <span>&#10060;</span>
          )}
        </div>
      );
    }
  },
  {
    id: 10,
    Header: 'Order Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell?.value)},
        <small className='text-muted'> {handleValidTime(cell?.value)}</small>
      </>
    )
  },

  {
    id: 11,
    Header: 'Payment Date',
    accessor: 'datePaid',
    Cell: (cell: any) => (
      <>
        {cell.value ? (
          <>
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        ) : (
          '---'
        )}
      </>
    )
  },

  {
    id: 12,
    Header: 'Lock Date',
    accessor: 'dateLimitForAutoStake',
    Cell: (cell: any) => {
      return <SetAutostakeDate {...cell.row.original} />;
    }
  },

  {
    id: 13,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              PENDING
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              {calculationStatusForTable(cell.row.original.calculationStatus)}
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              CANCELED
            </span>
          );

        case 4:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              CANCELED BY USER
            </span>
          );
      }
    }
  },

  {
    id: 14,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      return <PackageOrdersActions {...cell} />;
    }
  }
];
