import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
  CardHeader
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';
import {
  getTeamDirectById,
  getTeamStructureById,
  getTeamTradingById
} from './slice';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';
import classnames from 'classnames';
import { Direct } from './direct';
import { Structure } from './structure';
import '../../../assets/scss/customStyles/teams.scss';
import TradingStructure from './tradingStructure';

const PAGE_TITLE = 'Teams';

const TeamsPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('1');
  const [id, setId] = useState('');

  const { loading, structure, direct } = useAppSelector((state) => state.teams);

  const userId = searchParams.get('id');

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (userId) {
      dispatch(getTeamStructureById(userId));
      dispatch(getTeamDirectById(userId));
      dispatch(getTeamTradingById(userId));
    }
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader loading={loading} title={PAGE_TITLE} />
              <CardHeader>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSearchParams({ id });
                  }}>
                  <Row>
                    <Col md={3} sm={4}>
                      <SearchInputFilter
                        onChange={(e: any) => setId(e.target.value)}
                        value={id}
                        placeholder='Search User ID'
                      />
                    </Col>
                    <Col md={3} sm={4}>
                      <SearchSubmitButton />
                    </Col>
                  </Row>
                </form>
              </CardHeader>

              {structure?.id && direct?.user?.id && (
                <>
                  <CardHeader>
                    <Nav
                      className='nav-tabs-custom rounded card-header-tabs border-bottom-0'
                      role='tablist'>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => {
                            tabChange('1');
                          }}>
                          <i className='fas fa-home'></i>
                          Direct
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '2' })}
                          onClick={() => {
                            tabChange('2');
                          }}>
                          <i className='fas fa-home'></i>
                          Structure
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '3' })}
                          onClick={() => {
                            tabChange('3');
                          }}>
                          <i className='fas fa-home'></i>
                          Trading Structure
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId='1'>
                        <Direct />
                      </TabPane>
                      <TabPane tabId='2'>
                        <Structure />
                      </TabPane>
                      <TabPane tabId='3'>
                        <TradingStructure />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TeamsPage;
