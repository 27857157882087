import classnames from 'classnames';
import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setDocumentTitle } from '../../helpers/helpers';
import { Categories } from './categories';
import QuestionsAnswers from './QuestionsAnswers';
import { getCategories } from './slice';
import { SubCategories } from './subCategories';

const PAGE_TITLE = 'Faq';

const Faq = () => {
  const dispatch = useAppDispatch();
  const { loading, categories } = useAppSelector((state) => state.faq);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  setDocumentTitle(PAGE_TITLE);

  const categoriesOptions = categories.map((category) => {
    return {
      value: category.id,
      label: category.name
    };
  });

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <CardHeader className='border-0'>
                <Row className='align-items-center gy-3'>
                  <div className='col-sm d-flex gap-3 flex-wrap align-items-center'>
                    <h5 className='card-title mb-0'>{PAGE_TITLE}</h5>
                    {loading && <Spinner size='sm' className='primary' />}
                  </div>
                  <div className='col-sm-auto'>
                    <div className='d-flex gap-3 flex-wrap align-items-center'>
                      {/* <AddContentBlock /> */}
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardHeader className='d-flex'>
                <Nav
                  className='nav-tabs-custom rounded card-header-tabs border-bottom-0'
                  role='tablist'>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        tabChange('1');
                      }}>
                      <i className='fas fa-home'></i>
                      Questions/Answers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        tabChange('2');
                      }}>
                      <i className='fas fa-home'></i>
                      Categories
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        tabChange('3');
                      }}>
                      <i className='fas fa-home'></i>
                      Subcategories
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
            </Card>

            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                <QuestionsAnswers />
              </TabPane>
              <TabPane tabId='2'>
                <Categories />
              </TabPane>
              <TabPane tabId='3'>
                <SubCategories />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Faq;
