import {
  dateNowPlusOneDay,
  handleDateRangeChange
} from '../../helpers/helpers';
import Flatpickr from 'react-flatpickr';

export const DateRangeFilter = ({
  searchParams,
  setSearchParams,
  param
}: {
  searchParams: URLSearchParams;
  setSearchParams: any;
  param?: string;
}) => {
  const from = searchParams.get(param || 'fromDate') || '';
  const to = searchParams.get(param || 'toDate') || '';
  return (
    <div className='mb-2 d-inline-block col-12'>
      <Flatpickr
        className='form-control'
        id='datepicker-publish-input'
        placeholder='Select a date'
        options={{
          altInput: true,
          altFormat: 'j F, Y',
          mode: 'range',
          dateFormat: 'd.m.y',
          maxDate: dateNowPlusOneDay
        }}
        onChange={(e) => {
          handleDateRangeChange(e, setSearchParams);
        }}
        value={[from, to]}
      />
    </div>
  );
};
