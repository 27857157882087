import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { KycVerificationStatusEnum, User } from './types';
import { handleValidDate, handleValidTime } from '../../../helpers/helpers';

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/user/mlm/filter', {
      params
    });
    return response.data.data;
  }
);

export const exportUsers = createAsyncThunk(
  'users/exportUsers',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/user/mlm/filter/export', {
      params
    });

    const kycStatusFormat = (value: number) => {
      switch (value) {
        case KycVerificationStatusEnum.NOT_ENTERED:
          return 'not entered';

        case KycVerificationStatusEnum.NOT_VERIFIED:
          return 'not verified';

        case KycVerificationStatusEnum.VERIFIED:
          return 'verified';
      }
    };

    const dataForExport = response.data.data.users.map((u: User) => {
      const status = kycStatusFormat(u.kycVerificationStatus);

      return {
        id: u.id,
        user: `${u.name} ${u.surname}`,
        email: u.email,
        username: u.username,
        rank: u?.packageRank.name,
        kycVerificationStatus: `${status}`,
        country: u.country,
        dateCreated: u.dateCreated
          ? `${handleValidDate(u.dateCreated)}, ${handleValidTime(
              u.dateCreated
            )}`
          : ''
      };
    });

    return dataForExport;
  }
);

export const exportHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User', key: 'user' },
  { label: 'Email', key: 'email' },
  { label: 'Username', key: 'username' },
  { label: 'Rank', key: 'rank' },
  { label: 'Kyc Verification Status', key: 'kycVerificationStatus' },
  { label: 'Country', key: 'country' },
  { label: 'Date Created', key: 'dateCreated' }
];

export interface UsersState {
  data: User[];
  loading: boolean;
  totalCount: number;
  dataForExport: User[];
  loadingExport: boolean;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  dataForExport: [],
  loadingExport: false
} as UsersState;

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    removeUsersForExport(state) {
      state.dataForExport = [];
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.users as User[];
      state.totalCount = payload.count;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportUsers.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(exportUsers.fulfilled, (state, { payload }) => {
      state.loadingExport = false;
      state.dataForExport = payload;
    });
    builder.addCase(exportUsers.rejected, (state) => {
      state.loadingExport = false;
    });
  }
});

export const { removeUsersForExport } = usersSlice.actions;

export default usersSlice.reducer;
