import { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { HubsStakingPackage, StakingHubType } from '../../types';
import {
  LP,
  formatUSD,
  formatUSDNoDecimals,
  handleValidDate,
  showImages
} from '../../../../../helpers/helpers';
import Countdown from 'react-countdown';

import bonusHub from '../../../../../assets/customAssets/hubImages/bonusHub.jpg';
import legacyHub from '../../../../../assets/customAssets/hubImages/legacyHub.jpg';
import moment from 'moment';
import { PackageConverted } from './PackageDisabled';

export const StakingHub: FC<any> = ({ data }: { data: HubsStakingPackage }) => {
  const renderer = ({
    days,
    hours,
    minutes
  }: {
    days: any;
    hours: any;
    minutes: any;
  }) => {
    if (days === 0 && hours === 0 && minutes === 0) {
      return (
        <>
          {days} days {hours}h {minutes}min*
        </>
      );
    } else {
      return (
        <>
          {days} days {hours}h {minutes}min
        </>
      );
    }
  };

  const nextUnlockDate = new Date(data.nextUnlockDate * 1000);

  const packageType = (type: any) => {
    switch (type) {
      case 'Bonus':
        return 'Bonus hub';
      case 'Legacy':
        return 'Legacy hub';
      default:
        break;
    }
  };

  const packageTypeForIcons = (type: any) => {
    switch (type) {
      case 'Bonus':
        return bonusHub;
      case 'Legacy':
        return legacyHub;
      default:
        break;
    }
  };

  if (data.type !== 'Bonus') {
    return (
      <div
        className='d-flex mb-3  border border-primary'
        style={{ position: 'relative' }}>
        <img width={150} height={266.6} src={showImages(data?.packageId)} />
        <div className='d-flex  w-100 align-items-center'>
          <div className=' p-3 w-100 '>
            <h4>{data?.name}</h4>
            <Row>
              <Col lg={2} className=' border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>PRICE</h5>
                <h4 className='fs-15 mb-0'>
                  {formatUSDNoDecimals(data.price)}
                  <span className='text-muted fs-13'> USDT</span>
                </h4>
              </Col>
              <Col lg={2} className=' border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>
                  DATE OF PURCHASE
                </h5>
                <h4 className='fs-15 mb-0'>
                  {moment(data?.dateOfPurchase).format('DD MMM YYYY')}
                </h4>
              </Col>
              <Col lg={2} className=' border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>BASE STAKE</h5>
                <h4 className='fs-15 mb-0'>
                  {formatUSDNoDecimals(data.baseStake)}{' '}
                  <span className='text-muted fs-13'>wUlX</span>
                </h4>
              </Col>
              <Col lg={2} className='border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>TOTAL STAKE</h5>
                <h4 className='fs-15 mb-0'>
                  {formatUSDNoDecimals(data.totalStake)}
                  <span className='text-muted fs-13'> wUlX</span>
                </h4>
              </Col>
              <Col lg={2} className=' p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>MY EARNINGS</h5>
                <h4 className='fs-15 mb-0'>
                  {formatUSDNoDecimals(data.myEarnings)}
                  <span className='text-muted fs-13'> wUlX</span>
                </h4>
              </Col>
            </Row>
            <div className='d-flex justify-content-between'>
              <Row>
                <Col lg={4} className='p-3'>
                  <p className='mb-0'>STAKING HUB DETAILS</p>
                </Col>
                <Col lg={4} className='p-3'>
                  <p className='mb-0'>
                    ORDER ID: <b>{data?.id}</b>
                  </p>
                </Col>
                <Col lg={4} className='p-3'>
                  <p className='mb-0'>
                    NFT ID: <b>{data?.nftId}</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className='p-3'>
                  <AutoStake data={data} />
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg={4} className=' border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>
                  AVAILABLE STAKE
                </h5>
                <h4 className='fs-15 mb-0'>
                  {' '}
                  {formatUSDNoDecimals(data.availableStake)}
                  <span className='text-muted fs-13'> UlX</span>
                </h4>
              </Col>
              <Col lg={4} className=' border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>
                  NEXT UNLOCK COUNTDOWN
                </h5>
                <h4 className='fs-15 mb-0'>
                  {nextUnlockDate && (
                    <Countdown date={nextUnlockDate} renderer={renderer} />
                  )}
                </h4>
              </Col>
              <Col lg={4} className=' border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>
                  NEXT UNLOCK AMOUNT
                </h5>
                <h4 className='fs-15 mb-0'>
                  {' '}
                  {formatUSDNoDecimals(data.nextUnlockAmount)}{' '}
                  <span className='text-muted fs-13'>UlX</span>
                </h4>
              </Col>
            </Row>
          </div>
        </div>
        <PackageConverted data={data} />
      </div>
    );
  } else if (data.type === 'Bonus') {
    return (
      <div className='d-flex mb-3  border border-primary'>
        <img width={150} height={266.6} src={packageTypeForIcons(data?.type)} />
        <div className='d-flex  w-100 align-items-center'>
          <div className=' p-3 w-100 '>
            <h4>{packageType(data.type)}</h4>
            <Row>
              <Col lg={3} className=' border-end p-3  '>
                <h4>Transactions</h4>
              </Col>
              <Col lg={3} className=' border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>
                  DATE OF CREATION
                </h5>
                <h4 className='fs-15 mb-0'>
                  {moment(data?.dateOfPurchase).format('DD MMM YYYY')}
                </h4>
              </Col>
              {/* <Col lg={2} className=' border-end p-3  '>
              <h5 className='text-muted text-uppercase fs-13'>BASE STAKE</h5>
              <h4 className='fs-15 mb-0'>
                {formatUSDNoDecimals(data.price)}{' '}
                <span className='text-muted fs-13'>wUlX</span>
              </h4>
            </Col> */}
              <Col lg={3} className='border-end p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>TOTAL STAKE</h5>
                <h4 className='fs-15 mb-0'>
                  {formatUSDNoDecimals(data.totalStake)}
                  <span className='text-muted fs-13'> wUlX</span>
                </h4>
              </Col>
              <Col lg={3} className=' p-3  '>
                <h5 className='text-muted text-uppercase fs-13'>MY EARNINGS</h5>
                <h4 className='fs-15 mb-0'>
                  {formatUSDNoDecimals(data.myEarnings)}
                  <span className='text-muted fs-13'> wUlX</span>
                </h4>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};

const AutoStake = ({ data }: { data: HubsStakingPackage }) => {
  const activeSince = (date: any) => {
    const now = moment(new Date()); //todays date
    const end =
      data.lastAutocompoundTimestamp > 0
        ? moment.unix(data.lastAutocompoundTimestamp)
        : moment(data?.dateOfCreation);

    const duration = moment.duration(now.diff(end));

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (years > 0) {
      return `${years} ${years === 1 ? 'year' : 'years'} ${months} ${
        months === 1 ? 'month' : 'months'
      }  ${days} days ${hours}h ${minutes}min`;
    } else if (months > 0) {
      return `${months} ${
        months === 1 ? 'month' : 'months'
      }  ${days} days ${hours}h ${minutes}min`;
    } else if (days > 0) {
      return `${days} days ${hours}h ${minutes}min`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}min`;
    } else {
      return `${minutes}min`;
    }
  };

  const deactivatedSince = () => {
    const now = moment(new Date()); //todays date
    const start = now.format('DD MMM YYYY');

    const dayINeed = 8;

    const end = moment().day(dayINeed).format('DD MMM YYYY');

    const day = Math.abs(
      moment(start).startOf('day').diff(moment(end).startOf('day'), 'days')
    );

    return `${day} ${day === 1 ? 'day' : 'days'}`;
  };

  const showAutoStake = (data: any) => {
    const dateNow = moment(new Date()).toISOString();

    if (
      data?.dateLimitForAutoStake === null ||
      data?.dateLimitForAutoStake <= dateNow
    ) {
      return <></>;
    } else {
      return (
        <p className='mb-0'>
          Auto-stake switch locked until:{' '}
          {moment(data.dateLimitForAutoStake).format('DD. MMM YYYY.')}
        </p>
      );
    }
  };
  const showTimer = (data: any) => {
    if (!data.autocompound && !data.disableAtNextWeek) {
      return <div></div>;
    } else if (!data.dateLimitForAutoStake) {
      return <i className='ri-timer-fill'></i>;
    }
  };

  const showText = (data: any) => {
    if (!data.autocompound && !data.disableAtNextWeek) {
      return <div></div>;
    } else {
      if (
        data.autocompound &&
        !data.disableAtNextWeek &&
        !data.dateLimitForAutoStake
      ) {
        return (
          <p className='mb-0'>
            Auto-stake active since <b>{activeSince(data)}</b>
          </p>
        );
      } else if (data.autocompound && data.disableAtNextWeek) {
        return (
          <p className='mb-0'>
            Auto stake change will be processed in <b>{deactivatedSince()}</b>.
          </p>
        );
      }
    }
  };
  return (
    <div style={{ flex: 'none' }}>
      <div>
        <div className='d-flex'>
          {showTimer(data)}
          <div>{showText(data)}</div>
        </div>
      </div>

      {showAutoStake(data)}
    </div>
  );
};
