import { Link } from 'react-router-dom';
import {
  NO_INFO,
  getInitials,
  handleValidDate,
  handleValidTime
} from '../../../helpers/helpers';
import { KycVerificationStatusEnum } from './types';

const renderProfileImage = (name: string, surname: string, image: string) => {
  const fullName = `${name} ${surname}`;

  if (image) {
    return <img src={image} className='rounded-circle avatar-xs' />;
  } else {
    return (
      <div className='avatar-xs '>
        <div className='avatar-title rounded-circle bg-soft-secondary text-secondary'>
          {getInitials(fullName || NO_INFO)}
        </div>
      </div>
    );
  }
};

export const columns = [
  {
    id: 1,
    Header: 'User ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return (
        <Link className='text-secondary' to={`/users/${cell?.value}`}>
          {cell?.value}
        </Link>
      );
    }
  },

  {
    id: 2,
    Header: 'Name',
    Cell: (cell: any) => {
      return (
        <div className='d-flex align-items-center gap-2'>
          {renderProfileImage(
            cell.row.original.name,
            cell.row.original.surname,
            cell.row.original.profileImageUrl
          )}
          {cell.row.original.name} {cell.row.original.surname}
        </div>
      );
    }
  },
  {
    id: 3,
    Header: 'Email',
    accessor: 'email',
    Cell: (cell: any) => {
      return cell.value || NO_INFO;
    }
  },

  {
    id: 4,
    Header: 'Username',
    Cell: (cell: any) => {
      return cell.row.original.username || NO_INFO;
    }
  },
  {
    id: 5,
    Header: 'Rank',
    Cell: (cell: any) => {
      return cell?.row?.original?.packageRank?.name || NO_INFO;
    }
  },
  {
    id: 6,
    Header: 'Kyc status',
    accessor: 'kycVerificationStatus',
    Cell: (cell: any) => {
      switch (cell.value) {
        case KycVerificationStatusEnum.NOT_ENTERED:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              not entered
            </span>
          );

        case KycVerificationStatusEnum.NOT_VERIFIED:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              not verified
            </span>
          );

        case KycVerificationStatusEnum.VERIFIED:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              verified
            </span>
          );
      }
    }
  },

  {
    id: 7,
    Header: 'Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  }
];
