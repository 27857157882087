import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';

import { CaptionText } from '../../../../../styles/FontStyles';

import { color } from '../../../../../styles/GlobalStyles';

import { Col } from 'reactstrap';
import styled from 'styled-components';
import { Flexed } from '../../../../../styles/GlobalComponents';
import { BootcampProgressType } from '../../types';
import { DotLineArrows } from './DotLineArrows/DotLineArrows';
import {
  ProgressComponent,
  ProgressComponentDiamond
} from './ProgressComponentBootcamp';
import {
  UserProfileBootcamp,
  UserProfileBootcampSmall
} from './UserProfileBootcamp';
import { useMatch } from 'react-router';
import { getBootcampEvent } from '../../slice';

export const MavieRank = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
    padding: 32px;
  }
  > img {
    width: 100%;
  }
  border-radius: 15px;
  margin-bottom: 40px;
  @media (max-width: 800px) {
    border-radius: 8px;
    margin-bottom: 20px;
    > div {
      padding: 20px;
    }
  }
  overflow: hidden;
  @media (max-width: 800px) {
    box-shadow: none;
    border-radius: 0;
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
`;

export const BootcampEvent: React.FC = () => {
  const dispatch = useAppDispatch();

  const { bootcampEvent } = useAppSelector((state) => state.userDetails);

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    dispatch(getBootcampEvent(Number(userId)));
  }, []);

  return (
    <Col lg={12}>
      <div className='d-flex justify-content-lg-around mb-5'>
        <div>
          <h6>PROMOTION STARTS</h6>
          <h6 style={{ fontWeight: 'bold' }}>February 25, 2024, 00:00 UTC</h6>
        </div>
        <div>
          <h6>PROMOTION ENDS</h6>
          <h6 style={{ fontWeight: 'bold' }}>April 30, 2024, 23:59 UTC</h6>
        </div>
        <div>
          <h6>VALID</h6>
          <h6 style={{ fontWeight: 'bold' }}>65 days</h6>
        </div>
      </div>
      {bootcampEvent !== null && (
        <>
          {bootcampEvent.progressType === BootcampProgressType.Simple && (
            <>
              {' '}
              <Flexed between>
                <CaptionText>
                  Country:{' '}
                  <b style={{ color: color.colors.primary900 }}>
                    {bootcampEvent?.user?.country}
                  </b>
                </CaptionText>

                <CaptionText>
                  Sponsor:{' '}
                  <b style={{ color: color.colors.primary900 }}>
                    {bootcampEvent?.user?.sponsor}
                  </b>
                </CaptionText>
              </Flexed>
              <MavieRank>
                <Flexed width='100%' gap='32px' rowSM>
                  <UserProfileBootcamp user={bootcampEvent?.user} />
                  <ProgressComponent
                    progress={bootcampEvent?.user?.progress}
                    label='your'
                  />
                </Flexed>
              </MavieRank>
              <DotLineArrows disabled={false} />
              <Flexed>
                <Flexed column center width='100%' m='40px' mSM='20px 10px'>
                  <UserProfileBootcampSmall
                    user={bootcampEvent?.directUsers[0]}
                  />

                  <ProgressComponent
                    progress={bootcampEvent?.directUsers[0]?.progress}
                    direct
                    label='user'
                  />
                </Flexed>
                <Flexed column center width='100%' m='40px' mSM='20px 10px'>
                  <UserProfileBootcampSmall
                    user={bootcampEvent?.directUsers[1]}
                  />

                  <ProgressComponent
                    progress={bootcampEvent?.directUsers[1]?.progress}
                    direct
                    label='user'
                  />
                </Flexed>
              </Flexed>
            </>
          )}

          {/* DIAMOND */}

          {bootcampEvent.progressType === BootcampProgressType.Complex && (
            <>
              <MavieRank>
                <Flexed width='100%' gap='32px' rowSM>
                  <UserProfileBootcamp user={bootcampEvent.user} />
                  <ProgressComponentDiamond
                    isDiamond={bootcampEvent?.user?.isDiamond}
                    progress={bootcampEvent?.user?.progress}
                  />
                </Flexed>
              </MavieRank>
              <DotLineArrows disabled={false} />
              <Flexed>
                <Flexed column center width='100%' m='40px' mSM='10px'>
                  <UserProfileBootcampSmall
                    user={bootcampEvent?.directUsers[0]}
                  />
                </Flexed>
                <Flexed column center width='100%' m='40px' mSM='10px'>
                  <UserProfileBootcampSmall
                    user={bootcampEvent?.directUsers[1]}
                  />
                </Flexed>
              </Flexed>
            </>
          )}
        </>
      )}
    </Col>
  );
};
