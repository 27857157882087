import moment from 'moment';
import {
  handleValidDate,
  handleValidTime,
  formatUSD
} from '../../../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Date',
    accessor: 'date',
    Cell: (cell: any) => {
      return (
        <div>
          {moment.utc(cell?.row?.original.dateCreated).format('DD/MM/YY')}
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'Invoice ID',
    accessor: 'id',
    Cell: (cell: any) => <div>#{cell?.row?.original?.id}</div>
  },
  {
    id: 3,
    Header: 'Type',
    accessor: 'groupType',
    Cell: (cell: any) => {
      switch (cell?.row?.original?.status) {
        case 1:
          return 'withdraw';
        case 2:
          return 'commission';
        case 3:
          return 'exchange';
        case 4:
          return 'deposit';
        default:
          return '';
      }
    }
  },

  {
    id: 4,
    Header: 'Amount',
    accessor: 'amount',
    Cell: (cell: any) => {
      switch (cell?.row?.original?.groupType) {
        case 1:
          return (
            cell?.row?.original?.rewardPoints &&
            `${formatUSD(cell?.row?.original?.rewardPoints)} USDT`
          );
        case 2:
          return (
            <div>
              {`${formatUSD(cell?.row?.original?.rewardPoints)} USDT`}
              <br />
              {`${formatUSD(cell?.row?.original?.shoppingPoints)} CLUB USDT`}
              <br />
              {`${formatUSD(
                cell?.row?.original?.ulxPoints
                  ? cell?.row?.original?.ulxPoints
                  : 0
              )} BONUS HUB`}
            </div>
          );
        case 3:
          return (
            cell?.row?.original?.shoppingPoints &&
            `${formatUSD(cell?.row?.original?.shoppingPoints)} USDT`
          );
        case 4:
          return (
            cell?.row?.original?.shoppingPoints &&
            `${formatUSD(cell?.row?.original?.shoppingPoints)} USDT`
          );
        case null:
          return ``;
        default:
          return '';
      }
    }
  },

  {
    id: 5,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell?.row?.original?.status) {
        case 1:
          return 'processing';
        case 2:
          return 'completed';
        case 3:
          return 'refunded';
        default:
      }
    }
  }
];
