import { handleValidDate, handleValidTime } from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Bonus ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 3,
    Header: 'Rank',
    accessor: 'packageRank',
    Cell: (cell: any) => {
      return <>{cell?.value?.name}</>;
    }
  },
  {
    id: 4,
    Header: 'Amount',
    accessor: 'amount',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 5,
    Header: 'RP',
    accessor: 'rewardPoints',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 6,
    Header: 'RP %',
    accessor: 'rewardPointsPercent',
    Cell: (cell: any) => {
      return <>{cell?.value} %</>;
    }
  },

  {
    id: 7,
    Header: 'SP',
    accessor: 'shoppingPoints',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },

  {
    id: 8,
    Header: 'Monthly Income',
    accessor: 'monthlyIncome',
    Cell: (cell: any) => {
      return (
        <>
          {handleValidDate(cell.value?.monthDate)},
          <small className='text-muted'>
            {' '}
            {handleValidTime(cell.value?.monthDate)}
          </small>
        </>
      );
    }
  },

  {
    id: 11,
    Header: 'Calc Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  }
];
