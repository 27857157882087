import { Dispatch, FC, SetStateAction, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { Button, Spinner } from 'reactstrap';
import {
  dateNow,
  handleValidDate,
  handleValidTime
} from '../../../../helpers/helpers';
import { Modal } from '../../../../Components/Custom/modal';
import { editDateForAutostake } from '../slice';
import { useAppDispatch } from '../../../../app/hooks';

interface SetAutostakeDateProps {
  id: number;
  dateLimitForAutoStake: string | null;
}

export const SetAutostakeDate: FC<SetAutostakeDateProps> = ({
  id,
  dateLimitForAutoStake
}) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [limitDate, setLimitDate] = useState('');

  const onSubmit = (id: number, limitDate: string | null) => {
    dispatch(editDateForAutostake({ id, limitDate }));
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        size='sm'
        color='secondary'
        className='btn btn-soft-secondary btn-sm'
        outline
        active={isOpen}
        onClick={() => setIsOpen(!isOpen)}>
        {dateLimitForAutoStake !== null ? (
          <div>
            {handleValidDate(dateLimitForAutoStake)},{' '}
            <small>{handleValidTime(dateLimitForAutoStake)}</small>
          </div>
        ) : (
          'No lock date'
        )}
      </Button>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title='Edit date'
        actions={
          <>
            <Button
              color='primary'
              className='m-1'
              onClick={() => onSubmit(id, limitDate)}>
              Edit
            </Button>
            <Button
              color='primary'
              className='m-1'
              onClick={() => onSubmit(id, null)}>
              Delete
            </Button>
            <Button
              color='primary'
              outline
              onClick={() => setIsOpen(!isOpen)}
              className='m-1'>
              Cancel
            </Button>
          </>
        }>
        <Flatpickr
          id='startDate'
          name='startDate'
          className='form-control'
          placeholder='Date'
          onChange={(date: any) => setLimitDate(date[0])}
          value={limitDate}
          options={{
            enableTime: false,
            minDate: dateNow,
            dateFormat: 'd.m.Y',
            inline: true
          }}
        />
      </Modal>
    </>
  );
};
