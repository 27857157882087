import moment from 'moment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { CsvExport } from '../../../Components/Custom/CsvExport';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { paramsForExport, setDocumentTitle } from '../../../helpers/helpers';
import { columns } from './columns';
import { Filters } from './filters';
import {
  exportHeaders,
  exportWithdrawals,
  getWithdrawals,
  removeWithdrawalsForExport
} from './slice';

const PAGE_TITLE = 'Withdrawals';

const INITIAL_PARAMS: {
  sortBy: string;
  sortOrder: string;
  countPerPage: string;
  numberOfPage: string;
} = {
  sortBy: 'dateCreated',
  sortOrder: 'DESC',
  countPerPage: '10',
  numberOfPage: '1'
};

const WithdrawalsPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);

  const { loading, data, totalCount, loadingExport, dataForExport } =
    useAppSelector((state) => state.withdrawals);

  useEffect(() => {
    dispatch(getWithdrawals(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading || loadingExport}
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
                actions={
                  <CsvExport
                    action={() =>
                      dispatch(exportWithdrawals(paramsForExport(searchParams)))
                    }
                    data={dataForExport}
                    headers={exportHeaders}
                    disabled={loadingExport}
                    fileName={`withdrawals-${moment().format('DD-MM-YYYY')}`}
                    removeDataAction={removeWithdrawalsForExport}
                  />
                }
              />
              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />

                <TableContainer
                  columns={columns}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WithdrawalsPage;
