import React from 'react';
import { Button, Col, Input, Label, Row, Progress } from 'reactstrap';

import {
  formatUSDT,
  calculatePercentage
} from '../../../../../helpers/helpers';

import { RankType } from '../../types';

import check from '../../../../../assets/customAssets/ranking/Vector.svg';

import './ranking.css';

const RankComponent: React.FC<RankType> = ({ rank }) => {
  return (
    <div className={rank.isFutureRank ? 'ranking-background' : ''}>
      <Col lg={12}>
        <div className='card-overlay card'>
          <img className='card-img img-fluid' src={rank.imageUrl} alt='' />
          <div className='card-img-overlay'>
            <div className='rank-title'>{rank.name}</div>
            {!rank.isFutureRank && (
              <div className='rank-status'>
                <div className='current-status'>Current Rank Status</div>
                <div className='d-flex align-items-center'>
                  <div style={{ fontWeight: 700 }}>
                    {rank.isRankStatusActive ? 'ACTIVE' : 'INACTIVE'}
                  </div>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      marginLeft: 10,
                      backgroundColor: rank.isRankStatusActive
                        ? '#09B29C'
                        : '#EF6547'
                    }}
                    className='rounded-circle'></div>
                </div>
              </div>
            )}
          </div>

          <div className='card-body'>
            <div className='d-flex mb-5'>
              <div style={{ width: 250 }}>
                <h6 className=''>CAP RULE TEAM BONUS</h6>
                <h6 style={{ fontWeight: 'bold' }}>
                  {formatUSDT.format(rank.weekCapRuleLimit)} USDT / week
                </h6>
              </div>
              <div>
                <h6 className=''>REWARDS</h6>
                <h6 style={{ fontWeight: 'bold' }}>
                  {rank.rewards.map((reward) => reward.reward.name)}
                </h6>
              </div>
            </div>

            {rank.name !== 'Qualified Partner' ? (
              <Col>
                <Row>
                  <div className='d-flex justify-content-between'>
                    <p>Weak team</p>
                    <div className='d-flex'>
                      <p style={{ marginRight: 5 }}>Required:</p>
                      {formatUSDT.format(rank?.paidTeamLimit)} BV
                    </div>
                  </div>
                </Row>
                <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                  <div className='flex-grow-1'>
                    <div>
                      <Progress
                        value={calculatePercentage(
                          rank?.paidTeamCurrent,
                          rank?.paidTeamLimit
                        )}
                        color={
                          calculatePercentage(
                            rank?.paidTeamCurrent,
                            rank?.paidTeamLimit
                          ) > 99
                            ? 'success'
                            : 'warning'
                        }
                        className='animated-progess custom-progress progress-label'>
                        <div
                          className='label'
                          style={{
                            backgroundColor:
                              calculatePercentage(
                                rank?.paidTeamCurrent,
                                rank?.paidTeamLimit
                              ) > 99
                                ? '#09B29C'
                                : '#F6B84B'
                          }}>
                          {' '}
                          {calculatePercentage(
                            rank?.paidTeamCurrent,
                            rank?.paidTeamLimit
                          )}
                          %
                        </div>{' '}
                      </Progress>
                    </div>
                  </div>
                </div>

                <Row>
                  <div className='d-flex justify-content-between'>
                    <p>40 / 40 / 20 Direct Volume</p>
                    <div className='d-flex'>
                      <p style={{ marginRight: 5 }}>Required:</p>
                      {formatUSDT.format(rank?.directVolumeLimit)} UV
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                    <div className='flex-grow-1'>
                      <div>
                        <Progress
                          value={calculatePercentage(
                            rank?.directVolumeCurrent,
                            rank?.directVolumeLimit
                          )}
                          color={
                            calculatePercentage(
                              rank?.directVolumeCurrent,
                              rank?.directVolumeLimit
                            ) > 99
                              ? 'success'
                              : 'warning'
                          }
                          className='animated-progess custom-progress progress-label'>
                          <div
                            className='label'
                            style={{
                              backgroundColor:
                                calculatePercentage(
                                  rank?.directVolumeCurrent,
                                  rank?.directVolumeLimit
                                ) > 99
                                  ? '#09B29C'
                                  : '#F6B84B'
                            }}>
                            {' '}
                            {calculatePercentage(
                              rank?.directVolumeCurrent,
                              rank?.directVolumeLimit
                            )}
                            %
                          </div>{' '}
                        </Progress>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='d-flex justify-content-between'>
                    <p>20% In Strong Team</p>
                    <div className='d-flex'>
                      <p style={{ marginRight: 5 }}>Required:</p>
                      {formatUSDT.format(rank?.strongTeamLimit)} UV
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                    <div className='flex-grow-1'>
                      <div>
                        <Progress
                          value={calculatePercentage(
                            rank?.strongTeamCurrent,
                            rank?.strongTeamLimit
                          )}
                          color={
                            calculatePercentage(
                              rank?.strongTeamCurrent,
                              rank?.strongTeamLimit
                            ) > 99
                              ? 'success'
                              : 'warning'
                          }
                          className='animated-progess custom-progress progress-label'>
                          <div
                            className='label'
                            style={{
                              backgroundColor:
                                calculatePercentage(
                                  rank?.strongTeamCurrent,
                                  rank?.strongTeamLimit
                                ) > 99
                                  ? '#09B29C'
                                  : '#F6B84B'
                            }}>
                            {' '}
                            {calculatePercentage(
                              rank?.strongTeamCurrent,
                              rank?.strongTeamLimit
                            )}
                            %
                          </div>{' '}
                        </Progress>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='d-flex justify-content-between'>
                    <p>Personal Orders (Auto Stake ON)</p>
                    <div className='d-flex'>
                      <p style={{ marginRight: 5 }}>Required:</p>
                      {formatUSDT.format(rank?.personalOrderLimit)} PO
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                    <div className='flex-grow-1'>
                      <div>
                        <Progress
                          value={calculatePercentage(
                            rank?.personalOrderAmount,
                            rank?.personalOrderLimit
                          )}
                          color={
                            calculatePercentage(
                              rank?.personalOrderAmount,
                              rank?.personalOrderLimit
                            ) > 99
                              ? 'success'
                              : 'warning'
                          }
                          className='animated-progess custom-progress progress-label'>
                          <div
                            className='label'
                            style={{
                              backgroundColor:
                                calculatePercentage(
                                  rank?.personalOrderAmount,
                                  rank?.personalOrderLimit
                                ) > 99
                                  ? '#09B29C'
                                  : '#F6B84B'
                            }}>
                            {' '}
                            {calculatePercentage(
                              rank?.personalOrderAmount,
                              rank?.personalOrderLimit
                            )}
                            %
                          </div>{' '}
                        </Progress>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
            ) : (
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center'>
                  <div
                    className={
                      rank.isRegisteredMember
                        ? 'partner-checked'
                        : 'partner-unchecked'
                    }>
                    <img src={check} alt='' />
                  </div>
                  <div>Registered Member</div>
                </div>
                <div className='d-flex align-items-center'>
                  <div
                    className={
                      rank.hasPaidStakingHub
                        ? 'partner-checked'
                        : 'partner-unchecked'
                    }>
                    <img src={check} alt='' />
                  </div>
                  <div>Own one or more Staking Hub</div>
                </div>
                <div className='d-flex align-items-center'>
                  <div
                    className={
                      rank.hasPartnerWithPaidPackageInRightLeg
                        ? 'partner-checked'
                        : 'partner-unchecked'
                    }>
                    <img src={check} alt='' />
                  </div>
                  <div>1x Active direct - Right Leg</div>
                </div>
                <div className='d-flex align-items-center'>
                  <div
                    className={
                      rank.hasPartnerWithPaidPackageInLeftLeg
                        ? 'partner-checked'
                        : 'partner-unchecked'
                    }>
                    <img src={check} alt='' />
                  </div>
                  <div>1x Active direct - Left Leg</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Col>
    </div>
  );
};

export default RankComponent;
