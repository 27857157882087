import styled from 'styled-components';
import { DisableSelection } from './stylesStyled';
import { Icon } from './Icons';
import { colors } from './GlobalStyles';
import { SomeUserNameInitials, numberInRange } from '../helpers/helpers';

export const Flexed = styled.div<any>`
  display: flex;
  ${(props) => props.between && 'justify-content: space-between'};
  ${(props) => props.end && 'justify-content: end'};
  ${(props) => props.justifyCenter && 'justify-content: center'};
  ${(props) => props.center && 'align-items:  center'};
  ${(props) => props.alignStart && 'align-items:  flex-start'};
  ${(props) => props.alignEnd && 'align-items:  flex-end'};

  ${(props) => props.column && 'flex-direction: column'};
  ${(props) => props.gap && `gap:${props.gap}`};
  ${(props) => props.pointer && `cursor: pointer`};
  ${(props) => props.wrap && `flex-wrap: wrap`};
  ${(props) => props.grow && `flex-grow:${props.grow}`};
  ${(props) => props.flexNone && `flex: none`};
  ${(props) => props.wrap && `flex-wrap: wrap`};
  ${(props) => props.spaceAround && `justify-content: space-around`};

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  gap: ${(props) => props.gap}rem;
  width: ${(props) => props.width};
  ${(props) => props.position && 'position:' + props.position};

  @media (max-width: 800px) {
    ${(props) => props.rowSM && 'flex-direction: column'};
    ${(props) => props.alignStartSM && 'align-items: start'};
    ${(props) => props.gapSM && `gap: ${props.gapSM}`};
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;
    width: ${(props) => props.widthSM};

    max-width: ${(props) => props.maxWidthSM};
  }

  ${({ desktop }) =>
    desktop &&
    `
    @media (max-width: 800px) {
    display: none;
  }

  `};

  ${({ mobile }) =>
    mobile &&
    `
    @media (min-width: 800px) {
    display: none;
  }
  
  `}
`;

export const ProfilePicture = styled.div<any>`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  ${DisableSelection}
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${DisableSelection};
`;

export const ProfileImagePlaceholder = styled.div<any>`
  font-size: ${(props) => (props.font ? props.font : '102px')};
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (!props.disabled ? '#4830C1' : '#F3F3F3')};
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};
  border-radius: 50%;

  @media (max-width: 800px) {
    font-weight: 600;
  }
`;

export const UserImageCircle = ({
  image,
  name,
  surname,
  size,
  margin,
  fontSize,
  avatarSize,
  avatar,
  disabled
}: any) => {
  return (
    <ProfilePicture
      size={size ? size : '56px'}
      style={{
        margin: margin
      }}>
      {image ? (
        <ProfileImage src={image} alt='Upload' />
      ) : (
        <ProfileImagePlaceholder
          disabled={disabled}
          size={size ? size : '56px'}
          font={fontSize}>
          {avatar ? (
            <Icon color={!disabled ? '#FFFFFF' : '#A9A9A9'}>
              <svg
                width={avatarSize ?? '24px'}
                height={avatarSize ?? '25px'}
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.9999 12.0631C15.3138 12.0631 18.0002 9.3767 18.0002 6.06286C18.0002 2.74902 15.3138 0.0626221 11.9999 0.0626221C8.68608 0.0626221 5.99968 2.74902 5.99968 6.06286C5.99968 9.3767 8.68608 12.0631 11.9999 12.0631Z'
                  fill='#8B8CFE'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M12.0005 13.5626C7.99532 13.5626 0 15.5727 0 19.5628V22.5629C0 23.388 0.675027 24.063 1.50006 24.063H22.5009C23.3259 24.063 24.001 23.388 24.001 22.5629V19.5628C24.001 15.5727 16.0056 13.5626 12.0005 13.5626Z'
                  fill='#8B8CFE'
                />
              </svg>
            </Icon>
          ) : (
            SomeUserNameInitials(name ?? '-', surname ?? '-')
          )}
        </ProfileImagePlaceholder>
      )}
    </ProfilePicture>
  );
};

export const SectionSeparatorVertical = styled.div<any>`
  flex: none;
  width: 1px !important;
  height: auto;
  margin: ${(props) => (props.margin ? props.margin : '0 50px')};
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    ${(props) => props.theme.colors.secondary100}30 50%,
    rgba(0, 0, 0, 0) 100%
  ); */
  background: ${(props) => props.theme.colors.secondary100}30;
`;

//////////////
// PROGRESS BAR

export const ProgressBar = styled.div<any>`
  width: 100%;
  padding: 2px 3px;
  background: ${(props) => props.theme.colors.neutral50};
  border: 1px solid rgba(45, 45, 45, 0.02);
  box-sizing: border-box;
  border-radius: 13px;
  div {
    width: ${(props) => (props.percentage ? props.percentage : 0)};
    height: 8px;
    background: ${(props) => props.theme.colors.primary900};
    border-radius: 6px;
  }
`;

// PROGRESS BAR with percentage

export const ProgressBarWithPercentage = styled.div<any>`
  width: 100%;
  /* padding: 2px 3px; */
  background: '#F3F3F3';
  border: 1px solid rgba(45, 45, 45, 0.02);
  box-sizing: border-box;
  border-radius: 13px;
  margin-bottom: 20px;
  height: 12px;
  > div {
    position: relative;
    width: ${(props) =>
      props.percentage ? `${props.percentage + '%'}` : '0%'};
    height: 12px;
    background: ${(props) => props.color};
    border-radius: 6px;
    > div {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -7px;
      border: 2px solid ${(props) => props.color};
      box-shadow: ${(props) => props.color};
      background: '#FFFFFF';
      color: ${(props) => props.color};
      border-radius: 9px;
      white-space: nowrap;
      flex: none;
      padding: 0 5px;
      height: 26px;
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
      ${({ percentage, progressWidth, progressValueWidth }) =>
        percentageBoxPosition(percentage, progressWidth, progressValueWidth)};
    }
  }
`;

export const percentageBoxPosition = (
  percentage: any,
  progressWidth: any,
  progressValueWidth: any
) => {
  if (numberInRange(percentage, 0, 10)) {
    if (progressWidth >= progressValueWidth) {
      return 'right: -20px;';
    } else {
      return 'left: 0;';
    }
  }

  if (numberInRange(percentage, 11, 69)) return 'right: -20px;';
  if (numberInRange(percentage, 70, 101)) return 'right: 0;';
};

export const StructurePlusBox = styled.div`
  flex: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.secondary100};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
