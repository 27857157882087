import styled from 'styled-components';

export const UserLine = styled.div`
  border-left: 1px dashed ${(props) => props.theme.colors.neutral900};
  height: 18px;
`;

export const UserCircle = styled.span`
  background: ${(props) => props.theme.colors.neutral900};
  width: 5px;
  height: 5px;
  border-radius: 50%;
`;

export const BigArrows = styled.div`
  border-top: 1px dashed ${(props) => props.theme.colors.neutral900};
  border-left: 1px dashed ${(props) => props.theme.colors.neutral900};
  border-right: 1px dashed ${(props) => props.theme.colors.neutral900};
  border-radius: 5px 5px 0 0;
  width: 50%;
  height: 18px;
  position: relative;
`;
