import moment from 'moment';
import styled from 'styled-components';
import { layoutModeTypes } from '../../../../../Components/constants/layout';
import { LP, formatUSD } from '../../../../../helpers/helpers';
import { getTheme } from '../../../../../helpers/localstorage-helper';

const PackageConvertedStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) =>
    getTheme() === layoutModeTypes.DARKMODE
      ? theme.colors.neutral900
      : theme.colors.neutral0}cc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
`;

export const PackageConverted = ({ data }: { data: any }) => {
  if (data?.dateConverted) {
    return (
      <PackageConvertedStyled>
        <h3>Converted to Loyalty Points</h3>
        <p>
          Date of conversion:{' '}
          {moment.utc(data?.dateConverted).format('MMM DD, yyyy')} <br />{' '}
          Loyalty Points Received: {formatUSD(data?.loyaltyPoints)} {LP}
        </p>
      </PackageConvertedStyled>
    );
  } else return null;
};
