import React from 'react';
import { Col, Progress, Row } from 'reactstrap';
import { useAppSelector } from '../../../../../app/hooks';

import arrow from '../../../../../assets/customAssets/unilevel/arrow-right.png';
import logo from '../../../../../assets/customAssets/unilevel/logoOnlyLetter.svg';
import {
  calculatePercentage,
  formatUSDT
} from '../../../../../helpers/helpers';

import TableContainer from '../../../../../Components/Common/TableContainer';

import { Link } from 'react-router-dom';
import { columns } from './columns';

export const Unilevel: React.FC = () => {
  const { unilevel } = useAppSelector((state) => state.userDetails);

  return (
    <>
      <Row className='mb-5'>
        <Col
          lg={12}
          className='justify-content-between d-flex align-items-center mb-3 mt-4'>
          <Col lg={3}>
            <div className='d-flex align-items-center'>
              <img
                src={logo}
                style={{ width: 70, marginBottom: 18, marginRight: 10 }}
                alt=''
              />
              <div>
                <h5>Current Rank</h5>
                <p>{unilevel?.currentPackageRank?.name}</p>
              </div>
            </div>
          </Col>
          {unilevel?.currentPackageRank?.packageRankId !== 20 && (
            <>
              <Col lg={6} className='mb-3 '>
                <div className='d-flex align-items-center'>
                  <div
                    style={{
                      borderBottom: '1px dashed #5a5a5a',
                      width: '100%'
                    }}></div>
                  <img
                    src={arrow}
                    style={{ width: 11, marginRight: 20 }}
                    alt=''
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className='d-flex align-items-center'>
                  <img
                    src={logo}
                    style={{ width: 70, marginBottom: 18, marginRight: 10 }}
                    alt=''
                  />
                  <div>
                    <h5>Next Rank</h5>
                    <p>{unilevel?.nextPackageRank?.name}</p>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Col>

        {!unilevel?.currentPackageRank && (
          <Link to='ranking'>
            <div
              style={{ cursor: 'pointer' }}
              className='d-flex justify-content-center'>
              Check ranking progress
            </div>
          </Link>
        )}

        {unilevel?.currentPackageRank !== null &&
          unilevel?.currentPackageRank?.name !== 'Customer' &&
          unilevel?.currentPackageRank?.name !== 'Partner' &&
          unilevel?.currentPackageRank?.packageRankId !== 20 && (
            <Col>
              <h6 className='mb-3'>COMPLETE FOR THE NEXT RANK</h6>
              <Row>
                <p>Weak team</p>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Current:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.paidTeamCurrent
                        ? unilevel?.nextPackageRank?.paidTeamCurrent
                        : 0
                    )}{' '}
                    BV
                  </div>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Required:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.paidTeamLimit
                    )}{' '}
                    BV
                  </div>
                </div>
              </Row>
              <Row>
                <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                  <div className='flex-grow-1'>
                    <div>
                      <Progress
                        value={calculatePercentage(
                          unilevel?.nextPackageRank?.paidTeamCurrent,
                          unilevel?.nextPackageRank?.paidTeamLimit
                        )}
                        color={
                          calculatePercentage(
                            unilevel?.nextPackageRank?.paidTeamCurrent,
                            unilevel?.nextPackageRank?.paidTeamLimit
                          ) > 99
                            ? 'success'
                            : 'warning'
                        }
                        className='animated-progess custom-progress progress-label'>
                        <div
                          className='label'
                          style={{
                            backgroundColor:
                              calculatePercentage(
                                unilevel?.nextPackageRank?.paidTeamCurrent,
                                unilevel?.nextPackageRank?.paidTeamLimit
                              ) > 99
                                ? '#09B29C'
                                : '#F6B84B'
                          }}>
                          {' '}
                          {calculatePercentage(
                            unilevel?.nextPackageRank?.paidTeamCurrent,
                            unilevel?.nextPackageRank?.paidTeamLimit
                          )}
                          %
                        </div>{' '}
                      </Progress>
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <p>40 / 40 / 20 Unilevel</p>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Current:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.directVolumeCurrent
                        ? unilevel?.nextPackageRank?.directVolumeCurrent
                        : 0
                    )}{' '}
                    UV
                  </div>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Required:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.directVolumeLimit
                    )}{' '}
                    UV
                  </div>
                </div>
              </Row>
              <Row>
                <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                  <div className='flex-grow-1'>
                    <div>
                      <Progress
                        value={calculatePercentage(
                          unilevel?.nextPackageRank?.directVolumeCurrent,
                          unilevel?.nextPackageRank?.directVolumeLimit
                        )}
                        color={
                          calculatePercentage(
                            unilevel?.nextPackageRank?.directVolumeCurrent,
                            unilevel?.nextPackageRank?.directVolumeLimit
                          ) > 99
                            ? 'success'
                            : 'warning'
                        }
                        className='animated-progess custom-progress progress-label'>
                        <div
                          className='label'
                          style={{
                            backgroundColor:
                              calculatePercentage(
                                unilevel?.nextPackageRank?.directVolumeCurrent,
                                unilevel?.nextPackageRank?.directVolumeLimit
                              ) > 99
                                ? '#09B29C'
                                : '#F6B84B'
                          }}>
                          {' '}
                          {calculatePercentage(
                            unilevel?.nextPackageRank?.directVolumeCurrent,
                            unilevel?.nextPackageRank?.directVolumeLimit
                          )}
                          %
                        </div>{' '}
                      </Progress>
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <p>20% In Strong Team</p>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Current:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.strongTeamCurrent
                        ? unilevel?.nextPackageRank?.strongTeamCurrent
                        : 0
                    )}{' '}
                    UV
                  </div>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Required:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.strongTeamLimit
                    )}{' '}
                    UV
                  </div>
                </div>
              </Row>
              <Row>
                <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                  <div className='flex-grow-1'>
                    <div>
                      <Progress
                        value={calculatePercentage(
                          unilevel?.nextPackageRank?.strongTeamCurrent,
                          unilevel?.nextPackageRank?.strongTeamLimit
                        )}
                        color={
                          calculatePercentage(
                            unilevel?.nextPackageRank?.strongTeamCurrent,
                            unilevel?.nextPackageRank?.strongTeamLimit
                          ) > 99
                            ? 'success'
                            : 'warning'
                        }
                        className='animated-progess custom-progress progress-label'>
                        <div
                          className='label'
                          style={{
                            backgroundColor:
                              calculatePercentage(
                                unilevel?.nextPackageRank?.strongTeamCurrent,
                                unilevel?.nextPackageRank?.strongTeamLimit
                              ) > 99
                                ? '#09B29C'
                                : '#F6B84B'
                          }}>
                          {' '}
                          {calculatePercentage(
                            unilevel?.nextPackageRank?.strongTeamCurrent,
                            unilevel?.nextPackageRank?.strongTeamLimit
                          )}
                          %
                        </div>{' '}
                      </Progress>
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <p>Personal Orders (Auto Stake ON)</p>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Current:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.personalOrderAmount
                    )}{' '}
                    PO
                  </div>
                  <div className='d-flex'>
                    <p style={{ marginRight: 5 }}>Required:</p>
                    {formatUSDT.format(
                      unilevel?.nextPackageRank?.personalOrderLimit
                    )}{' '}
                    PO
                  </div>
                </div>
              </Row>
              <Row>
                <div className='d-flex align-items-center pb-2 mt-3 mb-4'>
                  <div className='flex-grow-1'>
                    <div>
                      <Progress
                        value={calculatePercentage(
                          unilevel?.nextPackageRank?.personalOrderAmount,
                          unilevel?.nextPackageRank?.personalOrderLimit
                        )}
                        color={
                          calculatePercentage(
                            unilevel?.nextPackageRank?.personalOrderAmount,
                            unilevel?.nextPackageRank?.personalOrderLimit
                          ) > 99
                            ? 'success'
                            : 'warning'
                        }
                        className='animated-progess custom-progress progress-label'>
                        <div
                          className='label'
                          style={{
                            backgroundColor:
                              calculatePercentage(
                                unilevel?.nextPackageRank?.personalOrderAmount,
                                unilevel?.nextPackageRank?.personalOrderLimit
                              ) > 99
                                ? '#09B29C'
                                : '#F6B84B'
                          }}>
                          {' '}
                          {calculatePercentage(
                            unilevel?.nextPackageRank?.personalOrderAmount,
                            unilevel?.nextPackageRank?.personalOrderLimit
                          )}
                          %
                        </div>{' '}
                      </Progress>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          )}
      </Row>
      <Row>
        <Col lg={12}>
          <TableContainer
            columns={columns}
            data={unilevel?.directTeamUsers || []}
            divClass='table-responsive table-card mb-1'
            tableClass='align-middle table-nowrap'
            theadClass='table-light text-muted'
          />
        </Col>
      </Row>
    </>
  );
};
