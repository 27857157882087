import { Col } from 'reactstrap';

export const SeparatorWithButton = ({
  buttonText,
  onClick,
  disabled,
  noButton
}: {
  buttonText: string;
  onClick: Function;
  disabled?: boolean;
  noButton?: boolean;
}) => {
  return (
    <>
      {!noButton && (
        <Col lg={12}>
          <div className='hstack gap-2 justify-content-end'>
            <button
              onClick={() => onClick()}
              type='button'
              className='btn btn-primary'
              disabled={disabled}>
              {buttonText}
            </button>
          </div>
        </Col>
      )}

      <div className='w-100 border border-dashed border-end-0 border-start-0 my-4'></div>
    </>
  );
};
