import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Pagination } from '../../../Components/Custom/pagination';
import { columns } from './columns';
import { Filters } from './filters';
import { getPackageRankHistory } from './slice';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { setDocumentTitle } from '../../../helpers/helpers';

const PAGE_TITLE = 'Package Rank History';

const INITIAL_PARAMS: {
  countPerPage: string;
  numberOfPage: string;
} = {
  countPerPage: '10',
  numberOfPage: '1'
};

const PackageRankHistoryPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);
  const { loading, data, totalCount } = useAppSelector(
    (state) => state.packageRankHistory
  );

  useEffect(() => {
    dispatch(getPackageRankHistory(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading}
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
              />

              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />

                <TableContainer
                  columns={columns}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PackageRankHistoryPage;
