import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { TeamMember } from './teamMember';

export const Direct = () => {
  const { direct } = useAppSelector((state) => state.teams);
  const [_, setSearchParams] = useSearchParams();

  return (
    <div>
      {direct.user && (
        <>
          <div className='d-flex flex-column align-items-center '>
            <div>
              <TeamMember id={direct?.user?.id} user={direct?.user} />
            </div>
            <div className='d-flex justify-content-between w-100'>
              <h4>Left team</h4>
              <h4>Right team</h4>
            </div>
          </div>
          <div className='d-flex gap-3 mt-2 align-items-start'>
            <div className='direct-container border-dashed border'>
              {direct?.leftDirectUsers?.map((team, i) => (
                <div key={i}>
                  <TeamMember
                    id={team.id}
                    user={team}
                    onClick={() => setSearchParams({ id: team.id.toString() })}
                  />
                </div>
              ))}
            </div>
            <div className='direct-container-end  border-dashed border'>
              {direct?.rightDirectUsers?.map((team, i) => (
                <div key={i}>
                  <TeamMember
                    key={i}
                    user={team}
                    id={team.id}
                    onClick={() => setSearchParams({ id: team.id.toString() })}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
