import { DefaultTheme, createGlobalStyle } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      background: '#fafafa';
      backgroundLight: '#fefefe';
      primary900: '#4830C1';
      secondary100: '#4830C1';
      secondaryBlue: '#F2F6FF';
      neutral0: '#ffffff';
      neutral50: '#FFFFFF';
      neutral100: '#F3F3F3';
      neutral400: '#A9A9A9';
      neutral500: '#8E8E8E';
      neutral700: '#5A5A5A';
      neutral900: '#1F1F1F';
      success500: '#18B915';
      error500: '#EF4444';
      warning: '#fbca12';
      gradient1: 'linear-gradient(152.26deg, #7B61FF 9.97%, #3B159C 87.8%)';
      extra: '#79FCBA';
    };
    sizes: {
      s1: 4;
      s2: 8;
      s3: 12;
      s4: 16;
      s5: 20;
      s6: 24;
      s7: 28;
      s8: 32;
      s9: 36;
      s10: 40;
    };
    shadows: {
      shadowXS: '0px 4px 8px rgba(0, 0, 0, 0.05)';
      shadowSM: '0px 6px 12px rgba(0, 0, 0, 0.11)';
      shadowMD: ' 0px 9px 18px rgba(0, 0, 0, 0.15)';
      shadowLG: '0px 13px 37px rgba(0, 0, 0, 0.21)';
      shadowXL: '0px 20px 56px rgba(0, 0, 0, 0.29)';
      shadow: '0px 1px 12px #7B61FF';
    };

    //BUTTON
    buttonHeight: string;
    buttonBackground: string;
    buttonNegativeBackground: string;
    buttonFontSize: string;
    buttonBorder: string;
    buttonNegativeBorder: string;
    buttonBorderRadius: string;
    buttonFontColor: string;
    buttonNegativeFontColor: string;
    buttonDisabledBackground: string;
    buttonDisabledBorder: string;
    buttonDisabledFontColor: string;

    //AUTH BUTTON
    authButtonHeight: string;
    authButtonBackground: string;
    authButtonDisabledBackground: string;
    authButtonFontSize: string;
    authButtonBorder: string;
    authButtonDisabledBorder: string;
    authButtonBorderRadius: string;
    authButtonFontColor: string;
    authButtonDisabledFontColor: string;

    //INPUT
    inputHeight: string;
    inputBackground: string;
    inputFontSize: string;
    inputBorder: string;
    inputBorderRadius: string;
    inputFontColor: string;
    inputPlaceholderFontColor: string;
    inputShadow: string;
    inputLabelColor: string;
    inputFileUploadRestrictionsText: string;

    // AUTH LAYOUT
    authLayoutLogoWidth: string;
    authLayoutBackground: string;
    authLayoutWrapperBackground: string;
    authLayoutWrapperBorderRadius: string;
    authLayoutWrapperShadow: string;
    authLayoutTitle: string;
    authLayoutLabel: string;

    // KYC LAYOUT
    kycLayoutBackground: string;
    kycNavBackground: string;
    kycNavBorder: string;
    kycNavFontColor: string;
    kycNavActiveFontColor: string;
    kycNavDotColor: string;

    // KYC
    kycBrowseFilesButtonBackground: string;
    kycBrowseFilesButtonFontColor: string;
    kycBrowseFilesButtonBorder: string;
    kycCamBorder: string;
    kycCamCapturedBorder: string;
    kycTitleColor: string;
    kycSubTitleColor: string;
    kycFontColor: string;

    tabs: {
      color: string;
      colorSelected: string;
      borderColor: string;
      borderColorSelected: string;
      background: string;
      backgroundSelected: string;
      backgroundSelectedLight: string;
    };

    toggleTabs: {
      color: string;
      colorSelected: string;
      backgroundSelected: string;
      wrapper: string;
    };

    appLayout: {
      layoutBackground: string;

      navBackground: string;
      navFontColor: string;
      navIconColor: string;

      sideNavBackground: string;
      sideNavFontColor: string;
      sideNavIconColor: string;

      footerBackground: string;
      footerFontColor: string;
      footerIconColor: string;

      notificationsFontColor: string;

      sidebarModalBackground: string;
      sidebarModalTitleColor: string;
      sidebarModalCloseIconColor: string;

      pageTitleColor: string;
    };

    settings: {
      fontColor: string;
      iconColor: string;
      navLinkTitleColor: string;
      navLinkSubTitleColor: string;
      navLinkIconColor: string;
      vrColor: string;
      boxBackground: string;
      walletBackground: string;
      walletBackgroundSelected: string;
      walletBorderColor: string;
      labelColor: string;
    };

    helpdesk: {
      fontColor: string;
      navFontColor: string;
      navFontColorActive: string;
      navBackground: string;
      inputBackground: string;
      breadcrumbsFontColor: string;
    };

    dashboard: {
      background: string;
      fontColor: string;
      usernameBackground: string;
      iconColor: string;
    };

    iconColor: string;
  }
}

export const sizes = {
  s1: 4,
  s2: 8,
  s3: 12,
  s4: 16,
  s5: 20,
  s6: 24,
  s7: 28,
  s8: 32,
  s9: 36,
  s10: 40
};

export const colors = {
  background: '#fafafa',
  backgroundLight: '#fefefe',

  primary900: '#4830C1',

  secondary100: '#4830C1',

  secondaryBlue: '#F2F6FF',

  neutral0: '#ffffff',
  neutral50: '#FFFFFF',
  neutral100: '#F3F3F3',
  neutral400: '#A9A9A9',
  neutral500: '#8E8E8E',
  neutral700: '#5A5A5A',
  neutral900: '#1F1F1F',

  success400: '#00BA00',
  success500: '#18B915',

  error400: '#FF0000',

  error500: '#EF4444',

  warning: '#fbca12',

  gradient1: 'linear-gradient(152.26deg, #7B61FF 9.97%, #3B159C 87.8%)',

  extra: '#79FCBA'
};

export const colorsDark = {
  background: '#000',
  backgroundLight: '#fefefe',

  primary900: '#4830C1',

  secondary100: '#4830C1',

  secondaryBlue: '#F2F6FF',

  neutral0: '#ffffff',
  neutral50: '#FFFFFF',
  neutral100: '#F3F3F3',
  neutral400: '#A9A9A9',
  neutral500: '#8E8E8E',
  neutral700: '#5A5A5A',
  neutral900: '#1F1F1F',

  success400: '#00BA00',
  success500: '#18B915',

  error400: '#FF0000',

  error500: '#EF4444',

  warning: '#fbca12',

  gradient1: 'linear-gradient(152.26deg, #7B61FF 9.97%, #3B159C 87.8%)',

  extra: '#79FCBA'
};

export const shadows = {
  shadowXS: '0px 4px 8px rgba(0, 0, 0, 0.05)',
  shadowSM: '0px 6px 12px rgba(0, 0, 0, 0.11)',
  shadowMD: ' 0px 9px 18px rgba(0, 0, 0, 0.15)',
  shadowLG: '0px 13px 37px rgba(0, 0, 0, 0.21)',
  shadowXL: '0px 20px 56px rgba(0, 0, 0, 0.29)',
  shadow: '0px 1px 12px #7B61FF'
};

export const lightTheme = {
  sizes,
  colors,
  shadows,

  //BUTTON
  buttonHeight: '50px;',
  buttonBackground: colors.primary900,
  buttonNegativeBackground: colors.neutral50,
  buttonFontSize: '15px;',
  buttonBorder: `2px solid ${colors.primary900};`,
  buttonNegativeBorder: `2px solid ${colors.secondary100};`,
  buttonBorderRadius: '8px;',
  buttonFontColor: colors.neutral50,
  buttonNegativeFontColor: colors.neutral900,
  buttonDisabledBackground: colors.neutral100,
  buttonDisabledBorder: `none`,
  buttonDisabledFontColor: colors.neutral400,

  //AUTH BUTTON
  authButtonHeight: '50px;',
  authButtonBackground: colors.primary900,
  authButtonDisabledBackground: colors.neutral100,
  authButtonFontSize: '15px;',
  authButtonBorder: `2px solid ${colors.primary900};`,
  authButtonDisabledBorder: `none`,
  authButtonBorderRadius: '8px;',
  authButtonFontColor: colors.neutral50,
  authButtonDisabledFontColor: colors.neutral400,

  //INPUT
  inputHeight: '50px;',
  inputBackground: colors.neutral50,
  inputFontSize: '15px;',
  inputBorder: `2px solid #F3F3F3`,
  inputBorderRadius: '8px;',
  inputFontColor: colors.neutral700,
  inputPlaceholderFontColor: colors.neutral500,
  inputShadow: '2px 2px 8px rgba(0, 0, 0, 0.05);',
  inputLabelColor: colors.neutral700,
  inputFileUploadRestrictionsText: colors.neutral500,

  // AUTH LAYOUT
  authLayoutLogoWidth: '200px;',
  authLayoutBackground: colors.backgroundLight,
  authLayoutWrapperBackground: colors.neutral0,
  authLayoutWrapperBorderRadius: '20px',
  authLayoutWrapperShadow: shadows.shadowSM,
  authLayoutTitle: colors.neutral900,
  authLayoutLabel: colors.neutral700,

  // KYC LAYOUT
  kycLayoutBackground: colors.backgroundLight,
  kycNavBackground: colors.neutral50,
  kycNavBorder: `1px solid ${colors.neutral100}`,
  kycNavFontColor: colors.neutral500,
  kycNavActiveFontColor: colors.neutral900,
  kycNavDotColor: colors.primary900,

  // KYC
  kycBrowseFilesButtonBackground: colors.neutral0,
  kycBrowseFilesButtonFontColor: colors.primary900,
  kycBrowseFilesButtonBorder: `1px solid ${colors.primary900};`,
  kycCamBorder: `3px dashed ${colors.primary900}`,
  kycCamCapturedBorder: `3px solid ${colors.primary900}`,
  kycTitleColor: `${colors.neutral900}`,
  kycSubTitleColor: `${colors.neutral500}`,
  kycFontColor: `${colors.neutral700}`,

  tabs: {
    color: colors.neutral900,
    colorSelected: colors.neutral50,
    borderColor: colors.neutral100,
    borderColorSelected: colors.primary900,
    background: colors.neutral50,
    backgroundSelected: colors.primary900,
    backgroundSelectedLight: colors.secondary100
  },

  toggleTabs: {
    color: colors.primary900,
    colorSelected: colors.neutral50,
    backgroundSelected: colors.primary900,
    wrapper: colors.neutral100
  },

  appLayout: {
    layoutBackground: colors.background,

    navBackground: colors.neutral50,
    navFontColor: colors.neutral900,
    navIconColor: colors.neutral900,

    sideNavBackground: colors.neutral50,
    sideNavFontColor: colors.neutral900,
    sideNavIconColor: colors.primary900,

    footerBackground: colors.neutral50,
    footerFontColor: colors.neutral900,
    footerIconColor: colors.neutral900,

    notificationsFontColor: colors.neutral900,

    sidebarModalBackground: colors.neutral50,
    sidebarModalTitleColor: colors.neutral900,
    sidebarModalCloseIconColor: colors.neutral900,

    pageTitleColor: colors.neutral900
  },

  settings: {
    fontColor: colors.neutral900,
    iconColor: colors.neutral900,
    navLinkTitleColor: colors.neutral900,
    navLinkSubTitleColor: colors.neutral900,
    navLinkIconColor: colors.neutral900,
    vrColor: `linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      ${colors.neutral900} 50%,
      rgba(255, 255, 255, 0) 100%
    );`,
    boxBackground: colors.neutral50,
    walletBackground: colors.neutral100,
    walletBackgroundSelected: colors.primary900,
    walletBorderColor: colors.neutral100,
    labelColor: colors.neutral500
  },

  helpdesk: {
    fontColor: colors.neutral700,
    navFontColor: colors.neutral500,
    navFontColorActive: colors.neutral900,
    navBackground: colors.neutral50,
    inputBackground: colors.neutral100,
    breadcrumbsFontColor: colors.neutral400
  },

  dashboard: {
    background: colors.neutral50,
    fontColor: colors.neutral900,
    usernameBackground: colors.neutral50,
    iconColor: colors.neutral900
  },

  iconColor: colors.neutral900
};

export const darkTheme = {
  sizes,
  colors: colorsDark,
  shadows,

  //BUTTON
  buttonHeight: '50px;',
  buttonBackground: colors.primary900,
  buttonNegativeBackground: colors.neutral50,
  buttonFontSize: '15px;',
  buttonBorder: `2px solid ${colors.primary900};`,
  buttonNegativeBorder: `2px solid ${colors.secondary100};`,
  buttonBorderRadius: '8px;',
  buttonFontColor: colors.neutral50,
  buttonNegativeFontColor: colors.neutral900,
  buttonDisabledBackground: colors.neutral100,
  buttonDisabledBorder: `none`,
  buttonDisabledFontColor: colors.neutral400,

  //AUTH BUTTON
  authButtonHeight: '50px;',
  authButtonBackground: colors.primary900,
  authButtonDisabledBackground: colors.neutral100,
  authButtonFontSize: '15px;',
  authButtonBorder: `2px solid ${colors.primary900};`,
  authButtonDisabledBorder: `none`,
  authButtonBorderRadius: '8px;',
  authButtonFontColor: colors.neutral50,
  authButtonDisabledFontColor: colors.neutral400,

  //INPUT
  inputHeight: '50px;',
  inputBackground: colors.neutral50,
  inputFontSize: '15px;',
  inputBorder: `2px solid #F3F3F3`,
  inputBorderRadius: '8px;',
  inputFontColor: colors.neutral700,
  inputPlaceholderFontColor: colors.neutral500,
  inputShadow: '2px 2px 8px rgba(0, 0, 0, 0.05);',
  inputLabelColor: colors.neutral700,
  inputFileUploadRestrictionsText: colors.neutral500,

  // AUTH LAYOUT
  authLayoutLogoWidth: '200px;',
  authLayoutBackground: colors.backgroundLight,
  authLayoutWrapperBackground: colors.neutral0,
  authLayoutWrapperBorderRadius: '20px',
  authLayoutWrapperShadow: shadows.shadowSM,
  authLayoutTitle: colors.neutral900,
  authLayoutLabel: colors.neutral700,

  // KYC LAYOUT
  kycLayoutBackground: colors.backgroundLight,
  kycNavBackground: colors.neutral50,
  kycNavBorder: `1px solid ${colors.neutral100}`,
  kycNavFontColor: colors.neutral500,
  kycNavActiveFontColor: colors.neutral900,
  kycNavDotColor: colors.primary900,

  // KYC
  kycBrowseFilesButtonBackground: colors.neutral0,
  kycBrowseFilesButtonFontColor: colors.primary900,
  kycBrowseFilesButtonBorder: `1px solid ${colors.primary900};`,
  kycCamBorder: `3px dashed ${colors.primary900}`,
  kycCamCapturedBorder: `3px solid ${colors.primary900}`,
  kycTitleColor: `${colors.neutral900}`,
  kycSubTitleColor: `${colors.neutral500}`,
  kycFontColor: `${colors.neutral700}`,

  tabs: {
    color: colors.neutral900,
    colorSelected: colors.neutral50,
    borderColor: colors.neutral100,
    borderColorSelected: colors.primary900,
    background: colors.neutral50,
    backgroundSelected: colors.primary900,
    backgroundSelectedLight: colors.secondary100
  },

  toggleTabs: {
    color: colors.primary900,
    colorSelected: colors.neutral50,
    backgroundSelected: colors.primary900,
    wrapper: colors.neutral100
  },

  appLayout: {
    layoutBackground: colors.background,

    navBackground: colors.neutral50,
    navFontColor: colors.neutral900,
    navIconColor: colors.neutral900,

    sideNavBackground: colors.neutral50,
    sideNavFontColor: colors.neutral900,
    sideNavIconColor: colors.primary900,

    footerBackground: colors.neutral50,
    footerFontColor: colors.neutral900,
    footerIconColor: colors.neutral900,

    notificationsFontColor: colors.neutral900,

    sidebarModalBackground: colors.neutral50,
    sidebarModalTitleColor: colors.neutral900,
    sidebarModalCloseIconColor: colors.neutral900,

    pageTitleColor: colors.neutral900
  },

  settings: {
    fontColor: colors.neutral900,
    iconColor: colors.neutral900,
    navLinkTitleColor: colors.neutral900,
    navLinkSubTitleColor: colors.neutral900,
    navLinkIconColor: colors.neutral900,
    vrColor: `linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      ${colors.neutral900} 50%,
      rgba(255, 255, 255, 0) 100%
    );`,
    boxBackground: colors.neutral50,
    walletBackground: colors.neutral100,
    walletBackgroundSelected: colors.primary900,
    walletBorderColor: colors.neutral100,
    labelColor: colors.neutral500
  },

  helpdesk: {
    fontColor: colors.neutral700,
    navFontColor: colors.neutral500,
    navFontColorActive: colors.neutral900,
    navBackground: colors.neutral50,
    inputBackground: colors.neutral100,
    breadcrumbsFontColor: colors.neutral400
  },

  dashboard: {
    background: colors.neutral50,
    fontColor: colors.neutral900,
    usernameBackground: colors.neutral50,
    iconColor: colors.neutral900
  },

  iconColor: colors.neutral900
};

export const GlobalStyles = createGlobalStyle`

`;

export const theme = localStorage.getItem('givvoBackOfficeTheme');

export const setTheme = () => {
  if (theme === 'light') {
    localStorage.setItem('givvoBackOfficeTheme', 'dark');
    window.location.reload();
  } else if (theme === 'dark') {
    localStorage.setItem('givvoBackOfficeTheme', 'light');
    window.location.reload();
  } else if (!theme) {
    localStorage.setItem('givvoBackOfficeTheme', 'dark');
    window.location.reload();
  }
};

export const chooseTheme = () => {
  if (theme === 'light') {
    return lightTheme as DefaultTheme;
  } else if (theme === 'dark') {
    return darkTheme as DefaultTheme;
  } else {
    return lightTheme as DefaultTheme;
  }
};

export const color = chooseTheme();
