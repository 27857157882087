import { formatUSDT } from '../../../helpers/helpers';

export const TeamMember = ({
  id,
  image,
  onClick,
  user,
  rightLegTotalVolume,
  leftLegTotalVolume,
  structure
}: {
  id: number;
  image?: string;
  onClick?: any;
  user?: any;
  rightLegTotalVolume?: number;
  leftLegTotalVolume?: number;
  structure?: boolean;
}) => {
  return (
    <div
      className={`${!structure && 'm-2'} ${
        !structure ? 'p-2' : 'p-1'
      } border d-flex flex-column align-items-center text-center ${
        onClick && 'cursor-pointer'
      }`}
      onClick={onClick}>
      <div className='profile-user position-relative d-inline-block mx-auto'>
        {/* {!structure && (
          <img
            src={image || userDummy}
            className='rounded-circle avatar-md  img-thumbnail user-profile-image mb-2'
            alt='user-profile'
          />
        )} */}

        <h5 className='mb-2 '>{id || ''}</h5>

        <p className='mb-1 fs-11'>
          {formatUSDT.format(user?.personalOrderAmount)} PO
        </p>
        <p className='mb-1 fs-11'>
          {formatUSDT.format(user?.personalVolume)} PV
        </p>
        <p className='mb-1 fs-11'>
          {formatUSDT.format(user?.unilevelVolume)} UV
        </p>
        <p className='mb-1 fs-11'>
          {leftLegTotalVolume ? formatUSDT.format(leftLegTotalVolume) : 0} BVL
        </p>
        <p className='mb-1 fs-11'>
          {rightLegTotalVolume ? formatUSDT.format(rightLegTotalVolume) : 0} BVR
        </p>
      </div>
    </div>
  );
};
