// import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';

import { CaptionText, P3 } from '../../../../../styles/FontStyles';
import { Flexed } from '../../../../../styles/GlobalComponents';
import { color } from '../../../../../styles/GlobalStyles';
import { FlexCenter } from '../../../../../styles/stylesStyled';

import { BV, PO, UV, formatVP } from '../../../../../helpers/helpers';
import { Icon, IconUnilevelInfo } from '../../../../../styles/Icons';
import styled from 'styled-components';
import { UserProgress } from '../../types';
import { Progress } from 'reactstrap';

export const ProgressComponent = ({
  progress,
  direct,
  label
}: {
  progress: UserProgress;
  direct?: boolean;
  label?: string | null;
}) => {
  if (!progress) return null;

  return (
    <Flexed width='100%' column mt='20'>
      <CaptionText center={direct} caps mb='20' color={color.colors.neutral500}>
        <b>
          {label} progress to reach {progress.rankName}
        </b>
      </CaptionText>

      <FlexCenter>
        <P3 color={color.colors.neutral700} mb='10' semiBold>
          Paid Team
        </P3>
        <Icon
          ml='10'
          mb='11'
          style={{ cursor: 'pointer' }}
          onClick={() => {}}
          data-tip
          data-for={'paidTeam'}>
          <IconUnilevelInfo />
        </Icon>
      </FlexCenter>

      <Flexed center between mb='7'>
        <CaptionText color={color.colors.neutral700}>
          Current: {formatVP(progress.paidTeam.current)} {BV}
        </CaptionText>
        <CaptionText color={color.colors.neutral700}>
          Required: {formatVP(progress.paidTeam.required)} {BV}
        </CaptionText>
      </Flexed>
      <Progress
        value={progress.paidTeam.percentage}
        color={progress.paidTeam.percentage > 99 ? 'success' : 'warning'}
        className='animated-progess custom-progress progress-label'>
        <div
          className='label'
          style={{
            backgroundColor:
              progress.paidTeam.percentage > 99 ? '#09B29C' : '#F6B84B'
          }}>
          {' '}
          {progress.paidTeam.percentage}%
        </div>{' '}
      </Progress>

      <FlexCenter>
        <P3 color={color.colors.neutral700} mb='10' semiBold>
          40 / 40 / 20 Unilevel
        </P3>
        <Icon
          ml='10'
          mb='11'
          style={{ cursor: 'pointer' }}
          onClick={() => {}}
          data-tip
          data-for={'directVolume'}>
          <IconUnilevelInfo />
        </Icon>
      </FlexCenter>

      <Flexed center between mb='7'>
        <CaptionText color={color.colors.neutral700}>
          Current: {formatVP(progress.directVolume.current)} {UV}
        </CaptionText>
        <CaptionText color={color.colors.neutral700}>
          Required: {formatVP(progress.directVolume.required)} {UV}
        </CaptionText>
      </Flexed>

      <Progress
        value={progress.directVolume.percentage}
        color={progress.directVolume.percentage > 99 ? 'success' : 'warning'}
        className='animated-progess custom-progress progress-label'>
        <div
          className='label'
          style={{
            backgroundColor:
              progress.directVolume.percentage > 99 ? '#09B29C' : '#F6B84B'
          }}>
          {' '}
          {progress.directVolume.percentage}%
        </div>{' '}
      </Progress>

      <FlexCenter>
        <P3 color={color.colors.neutral700} mb='10' semiBold>
          20% In Strong Team
        </P3>
        <Icon
          ml='10'
          mb='11'
          style={{ cursor: 'pointer' }}
          onClick={() => {}}
          data-tip
          data-for={'strongTeam'}>
          <IconUnilevelInfo />
        </Icon>
      </FlexCenter>

      <Flexed center between mb='7'>
        <CaptionText color={color.colors.neutral700}>
          Current: {formatVP(progress.strongTeam.current)} {UV}
        </CaptionText>
        <CaptionText color={color.colors.neutral700}>
          Required: {formatVP(progress.strongTeam.required)}
          {UV}
        </CaptionText>
      </Flexed>

      <Progress
        value={progress.strongTeam.percentage}
        color={progress.strongTeam.percentage > 99 ? 'success' : 'warning'}
        className='animated-progess custom-progress progress-label'>
        <div
          className='label'
          style={{
            backgroundColor:
              progress.strongTeam.percentage > 99 ? '#09B29C' : '#F6B84B'
          }}>
          {' '}
          {progress.strongTeam.percentage}%
        </div>{' '}
      </Progress>

      <P3 color={color.colors.neutral700} mb='10' semiBold>
        Personal Orders (Auto Stake ON)
      </P3>
      <Flexed center between mb='7'>
        <CaptionText color={color.colors.neutral700}>
          Current: {formatVP(progress.personalOrder.current)} {PO}
        </CaptionText>
        <CaptionText color={color.colors.neutral700}>
          Required: {formatVP(progress.personalOrder.required)} {PO}
        </CaptionText>
      </Flexed>
      <Progress
        value={progress.personalOrder.percentage}
        color={progress.personalOrder.percentage > 99 ? 'success' : 'warning'}
        className='animated-progess custom-progress progress-label'>
        <div
          className='label'
          style={{
            backgroundColor:
              progress.personalOrder.percentage > 99 ? '#09B29C' : '#F6B84B'
          }}>
          {' '}
          {progress.personalOrder.percentage}%
        </div>{' '}
      </Progress>
    </Flexed>
  );
};

const Achivement = styled.div`
  background-color: #000;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 11px;
  display: inline-block;
  color: #fff;
  margin-bottom: 16px;
  max-width: 215px;
`;

const Status = styled.div<{ isDiamond: boolean }>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${({ theme, isDiamond }) =>
    isDiamond ? '#18B915' : '#EF4444'};
  display: inline-block;
`;

export const ProgressComponentDiamond = ({
  progress,
  isDiamond
}: {
  progress: UserProgress;
  isDiamond: boolean;
}) => {
  return (
    <Flexed width='100%' column>
      <P3 color={color.colors.neutral500} mb='10' semiBold>
        QUALIFIER 1
      </P3>

      <Achivement>
        Achieved DIAMOND RANK OR ABOVE:
        <br />
        <b>
          {isDiamond ? (
            <>
              Achieved <Status isDiamond={isDiamond} />
            </>
          ) : (
            <>
              Not Achieved <Status isDiamond={isDiamond} />
            </>
          )}
        </b>
      </Achivement>

      <P3 color={color.colors.neutral500} mb='10' semiBold>
        QUALIFIER 2
      </P3>

      <P3 color={color.colors.neutral700} mb='10' semiBold>
        Create 50,000 NEW BV Sales in Your PAID LEG
      </P3>

      <Flexed center between mb='7'>
        <CaptionText color={color.colors.neutral700}>
          Current: {formatVP(progress.businessVolume.current)} {BV}
        </CaptionText>
        <CaptionText color={color.colors.neutral700}>
          Required: {formatVP(progress.businessVolume.required)} {BV}
        </CaptionText>
      </Flexed>
      <Progress
        value={progress.businessVolume.percentage}
        color={progress.businessVolume.percentage > 99 ? 'success' : 'warning'}
        className='animated-progess custom-progress progress-label'>
        <div
          className='label'
          style={{
            backgroundColor:
              progress.businessVolume.percentage > 99 ? '#09B29C' : '#F6B84B'
          }}>
          {' '}
          {progress.businessVolume.percentage}%
        </div>{' '}
      </Progress>
      <P3 color={color.colors.neutral500} mb='10' semiBold>
        QUALIFIER 3
      </P3>
      <P3 color={color.colors.neutral700} mb='10' semiBold>
        Mentor of two Cabo event qualified members.
      </P3>
    </Flexed>
  );
};
