import { Button, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { KycImage } from './KycImage';
import { verifyKyc } from '../../slice';

export const KycPreview = () => {
  const { loading, data, editState } = useAppSelector(
    (state) => state.userDetails
  );

  const dispatch = useAppDispatch();

  const verified = data?.kycStatus?.isKycVerified;

  return (
    <>
      <Col lg={12} className='justify-content-between d-flex mb-3'>
        <h4>KYC</h4>
        {/* <Button color='primary' onClick={() => dispatch(toggleEditState())}>
            Edit user
          </Button> */}
      </Col>
      <Row>
        <Col lg={4}>
          <KycImage
            image={data?.kycStatus?.selfieImageUrl}
            label='Selfie'
            noImageText='SELFIE IMAGE NOT PROVIDED'
          />
        </Col>
        <Col lg={4}>
          {data?.kycStatus?.documentImageUrls?.[0] &&
            data?.kycStatus?.documentType === 1 && (
              <KycImage
                image={data?.kycStatus?.documentImageUrls?.[0]}
                label='ID Front'
                noImageText='ID FRONT IMAGE NOT PROVIDED'
              />
            )}
          {data?.kycStatus?.documentImageUrls?.[0] &&
            data?.kycStatus?.documentType === 3 && (
              <KycImage
                image={data?.kycStatus?.documentImageUrls?.[0]}
                label='Passport'
                noImageText='PASSPORT IMAGE NOT PROVIDED'
              />
            )}
        </Col>
        <Col lg={4}>
          {data?.kycStatus?.documentImageUrls?.[1] &&
            data?.kycStatus?.documentType === 1 && (
              <KycImage
                image={data?.kycStatus?.documentImageUrls?.[1]}
                label='ID Back'
                noImageText='ID BACK IMAGE NOT PROVIDED'
              />
            )}
        </Col>
        <Col lg={4}>
          {data?.kycStatus?.documentImageUrls?.[0] &&
            data?.kycStatus?.documentType === 2 && (
              <KycImage
                image={data?.kycStatus?.documentImageUrls?.[0]}
                label='Drivers License'
                noImageText='DRIVERS LICENSE IMAGE NOT PROVIDED'
              />
            )}
        </Col>
      </Row>
      <Row>
        <Col className='d-flex gap-3 justify-content-end'>
          {verified ? (
            <Button color='success' className='mr-2' disabled>
              Verified
            </Button>
          ) : (
            <>
              <Button
                color='danger'
                className='mr-2'
                onClick={() =>
                  dispatch(verifyKyc({ userId: data.id, isValid: false }))
                }>
                Repeat KYC
              </Button>
              <Button
                onClick={() =>
                  dispatch(verifyKyc({ userId: data.id, isValid: true }))
                }
                color='primary'>
                Verify KYC
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
