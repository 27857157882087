import moment from 'moment';
import { formatUSDT } from '../../../../../helpers/helpers';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useAppSelector } from '../../../../../app/hooks';

export const PointsCards = () => {
  const { data } = useAppSelector((state) => state.userDetails);

  const cards = [
    { label: 'USDT Balance', value: data?.userTickets?.availableRewardPoints },
    { label: 'Club Balance', value: data?.userTickets?.shoppingPoints },
    { label: 'Loyalty Points', value: data?.userTickets?.loyaltyPoints },

    {
      label: 'PO',
      value: data?.personalOrderAmount
    },
    {
      label: 'PO active',
      value: data?.personalOrderAmountWithAutostakeIntact
    },
    {
      label: 'PO for UV',
      value: data?.poForUv
    },

    { label: 'PV', value: data?.personalVolume },

    { label: 'UV', value: data?.directTotalVolume },
    { label: 'BV Left Total', value: data?.leftLegTotalVolume },
    { label: 'BV Right Total', value: data?.rightLegTotalVolume },
    { label: 'Direct Bonus', value: data?.directBonusCommissionPercentage },
    {
      label: 'Backoffice untill',
      value: data?.licenceEndDate
    },
    { label: 'BV Left Current', value: data?.leftLegVolume },
    { label: 'BV Right Current', value: data?.rightLegVolume },

    { label: 'TV Left Total', value: data?.leftLegTotalTradingVolume },
    { label: 'TV Right Total', value: data?.rightLegTotalTradingVolume },
    { label: 'TV Left Current', value: data?.leftLegTradingVolume },
    { label: 'TV Right Current', value: data?.rightLegTradingVolume },

    { label: 'Shadow Left', value: data?.leftLegShadowVolume },
    { label: 'Shadow Right', value: data?.rightLegShadowVolume }
  ];

  const renderCards = (card: any) => {
    if (card.label === 'Direct Bonus') {
      return card.value ? `${card.value} %` : '---';
    } else if (card.label === 'Backoffice untill') {
      return card?.value ? moment(card?.value).format('DD.MM.YYYY') : '---';
    } else {
      return card?.value ? formatUSDT.format(card.value) : '---';
    }
  };

  const cardSizeByIndex = (i: number) => (i < 6 ? 4 : 3);

  return (
    <Row>
      {cards.map((card, i) => (
        <Col xl={cardSizeByIndex(i)} lg={cardSizeByIndex(i)} md={4} key={i}>
          <Card>
            <CardBody className='p-2'>
              <h5>{renderCards(card)}</h5>
              <p className='mb-0'>{card.label}</p>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
