interface StatusType {
  statusId: number;
  statusName: string;
}

interface CategoryType {
  categoryId: number;
  categoryName: string;
}

interface UserType {
  id: number;
  email: string;
  name: string;
  surname: string;
  dateCreated: string;
}

export interface TaskListType {
  index?: number;
  id: number;
  title: string;
  dateCreated: string;
  description: string | null;
  status: StatusType;
  category: CategoryType;
  assignedTasks: [AssignedTask];
  user: UserType;
}

export interface TaskAddType {
  userId: number | undefined;
  statusId: number;
  uiCategoryId: number;
  title: string;
  description: string;
}

export interface AssignedTask {
  id: number;
  assignedUser: {
    id: number;
    name: string;
    surname: string;
    email: string;
    dateCreated: string;
  };
  dateAssigned: string;
}

export enum TaskStatus {
  TODO = 1,
  IN_PROGRESS,
  COMPLETED
}
