import moment from 'moment';
import {
  handleValidDate,
  handleValidTime,
  formatUSD
} from '../../../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Date',
    accessor: 'date',
    Cell: (cell: any) => {
      return (
        <div>
          {moment.utc(cell?.row?.original.dateCreated).format('DD/MM/YY')}
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'ID',
    accessor: 'id',
    Cell: (cell: any) => <div>#{cell?.row?.original?.conversionId}</div>
  },
  {
    id: 3,
    Header: 'Amount',
    accessor: 'amount',
    Cell: (cell: any) => (
      <div>{formatUSD(cell?.row?.original?.amount)} USDT</div>
    )
  },
  {
    id: 4,
    Header: 'Type',
    accessor: 'type',
    Cell: (cell: any) => <div>{cell?.row?.original?.type}</div>
  },
  {
    id: 5,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => <div>{cell?.row?.original?.status} </div>
  }
];
