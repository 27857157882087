import moment from 'moment';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'Wallet Address',
    accessor: 'address',
    Cell: (cell: any) => {
      if (cell.row.original?.isBlacklisted) {
        return (
          <div
            style={{ color: 'red' }}>{`! ${cell?.row?.original?.address}`}</div>
        );
      } else {
        return <div>{cell?.row?.original?.address}</div>;
      }
    }
  },

  {
    id: 3,
    Header: 'Label',
    accessor: 'label',
    Cell: (cell: any) => {
      return <>{cell?.row?.original?.label || ''}</>;
    }
  },

  {
    id: 4,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell?.row?.original?.status) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Active
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              Inactive
            </span>
          );

        default:
          return (
            <span className='badge text-uppercase badge-soft-danger'></span>
          );
      }
    }
  },

  {
    id: 5,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  }
];
