import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { DiscountCodeModel, DiscountCodeStateModel } from './types';

export const getDiscountCode = createAsyncThunk(
  'discountCode/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/discount/user-discount-codes', {
      params
    });
    return response.data.data;
  }
);

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as DiscountCodeStateModel;

const discountCodeSlice = createSlice({
  name: 'discountCode',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getDiscountCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDiscountCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.userDiscountCodes as DiscountCodeModel[];
      state.totalCount = payload.count;
    });
    builder.addCase(getDiscountCode.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default discountCodeSlice.reducer;
