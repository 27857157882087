import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { BlacklistedWalletType, BlacklistedWalletAddType } from './types';

export const getBlacklistedWalleets = createAsyncThunk(
  'blacklisted/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/wallet/address/blacklisted', {
      params
    });
    return response.data.data;
  }
);

export const addblacklistWallet = createAsyncThunk(
  'blacklisted/add',
  async (data: BlacklistedWalletAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/wallet/address/blacklist',
        data
      );
      toast.success('Wallet successfully added');

      return response.data.data
        .blacklistedWalletAddress as BlacklistedWalletType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const deleteblacklistWallet = createAsyncThunk(
  'blacklisted/delete',
  async (address: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/wallet/address/blacklist/${address}`
      );
      toast.success(response.data.message);

      return address;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export interface BlacklistedWalletsInterface {
  data: BlacklistedWalletType[];
  loading: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as BlacklistedWalletsInterface;

const blacklistedWalletsSlice = createSlice({
  name: 'blacklisted',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getBlacklistedWalleets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBlacklistedWalleets.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data =
        payload.blacklistedWalletAddresses as BlacklistedWalletType[];
      state.totalCount = payload.count;
    });
    builder.addCase(getBlacklistedWalleets.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addblacklistWallet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addblacklistWallet.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.unshift(payload);
    });
    builder.addCase(addblacklistWallet.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteblacklistWallet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteblacklistWallet.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data.splice(
        state.data.findIndex((p) => p.address === payload),
        1
      );
    });
    builder.addCase(deleteblacklistWallet.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default blacklistedWalletsSlice.reducer;
