import { Link } from 'react-router-dom';
import { formatUSDT } from '../../../../../helpers/helpers';
import countries from '../../../../../helpers/countries';

export const columns = [
  {
    id: 1,
    Header: 'USER ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return (
        <Link className='text-secondary' to={`/users/${cell?.value}`}>
          {cell?.value}
        </Link>
      );
    }
  },

  {
    id: 2,
    Header: 'USER',
    Cell: (cell: any) => {
      return (
        <div className='d-flex align-items-center gap-2'>
          {countries.map(
            (country, i) =>
              country.code === cell.row.original.countryCode && (
                <img
                  key={i}
                  src={country.flag}
                  alt='flag'
                  style={{
                    width: '28px',
                    height: '20px',
                    marginRight: '5px'
                  }}
                />
              )
          )}{' '}
          {cell.row.original.username}
        </div>
      );
    }
  },
  {
    id: 3,
    Header: 'UNILEVEL + PO',
    Cell: (cell: any) => {
      return `${formatUSDT.format(cell.row.original.totalVolume)} UV`;
    }
  },

  {
    id: 4,
    Header: 'EFFECTIVE VOLUME',
    Cell: (cell: any) => {
      return `${formatUSDT.format(cell.row.original.effectiveVolume)} UV`;
    }
  },
  {
    id: 5,
    Header: 'PERCENTAGE',
    Cell: (cell: any) => {
      return (
        <div
          className={
            cell?.row?.original?.percentage > 100
              ? 'text-danger'
              : 'text-success'
          }>
          {`${
            cell?.row?.original?.percentage
              ? cell?.row?.original?.percentage
              : 0
          } %`}
        </div>
      );
    }
  },
  {
    id: 6,
    Header: 'TEAM SIDE',
    Cell: (cell: any) => {
      return cell?.row?.original?.leg === 1 ? 'Right' : 'Left';
    }
  }
];
