import { Dispatch, FC, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import { Modal } from './modal';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  onSubmit: any;
}

export const AreYouSureModal: FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  onSubmit
}) => {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      actions={
        <>
          <Button
            color='primary'
            outline
            onClick={() => setIsOpen(!isOpen)}
            className='m-1'>
            Cancel
          </Button>
          <Button
            color='primary'
            className='m-1'
            onClick={() => {
              setIsOpen(isOpen);
              onSubmit();
            }}>
            Yes
          </Button>
        </>
      }>
      <div className='mb-3'>
        <div className='avatar-lg mx-auto'>
          <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
            <i className='ri-error-warning-fill '></i>
          </div>
        </div>
      </div>
      <h2>Are you sure?</h2>
      <p>You won’t be able to revert this!</p>
    </Modal>
  );
};
