import { Label, Media } from 'reactstrap';

export const KycImage = ({
  image,
  noImageText,
  label
}: {
  image?: string;
  noImageText: string;
  label: string;
}) => {
  return (
    <div className='mb-3'>
      <Label className='form-label'>{label}</Label>
      {image ? (
        <Media
          style={{ width: '100%', height: 'auto' }}
          object
          className='rounded mr-50 mb-2'
          src={image}
          alt='Kyc selfie'
          height='264'
          width='264'
        />
      ) : (
        <div style={{ width: '100%', aspectRatio: '1/1' }}>
          <div className='avatar-title rounded bg-soft-secondary text-secondary'>
            {noImageText}
          </div>
        </div>
      )}
    </div>
  );
};
