import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import { RenderSorting } from '../../../Components/Custom/RenderSorting';
import {
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SELECT
} from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
}

export const Filters: FC<FiltersProps> = ({ params, setParams }) => {
  const sortOrderOptions = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' }
  ];

  const sortByOptions = [
    { label: 'User Package ID', value: 'userPackageId' },
    { label: 'User ID', value: 'userId' },
    { label: 'Price', value: 'price' },
    { label: 'Date Created', value: 'dateCreated' }
  ];

  const sortingInputs = [
    { param: 'sortBy', type: INPUT_TYPE_SELECT, options: sortByOptions },
    { param: 'sortOrder', type: INPUT_TYPE_SELECT, options: sortOrderOptions }
  ];

  const filterInputs = [
    {
      param: 'userPackageId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Order ID'
    },

    {
      param: 'userId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search User ID'
    }
  ];

  return (
    <>
      <RenderSorting
        sortingInputs={sortingInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
    </>
  );
};
