export interface EventTickets {
  id: number;
  currencyType: number;
  dateCreated: string;
  datePaid: string;
  eventTicketTitle: string;
  networkType: string;
  paymentAddress: string;
  price: string;
  status: EventTicketStatus;
  userId: number;
  userName: string;
  userSurname: string;
}

export enum EventTicketStatus {
  PENDING = 1,
  PAID,
  CANCELED,
  CANCELED_BY_USER
}

export interface EventTicketsState {
  data: EventTickets[];
  loading: boolean;
  totalCount: number;
}

export enum OPENED_MODAL {
  CLOSED,
  MARK_AS_PAID,
  CANCEL
}

export enum CalculationStatus {
  CALCULATE = 1
}
