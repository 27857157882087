import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  handleSetUrlSearchParams,
  setDocumentTitle
} from '../../../helpers/helpers';
import { columns } from './columns';
import { getFastStartBonusList } from './slice';

const PAGE_TITLE = 'Fast Start Bonus';

const INITIAL_PARAMS: {
  countPerPage: string;
  numberOfPage: string;
} = {
  countPerPage: '10',
  numberOfPage: '1'
};

const FastStartBonusPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);
  const { loading, list } = useAppSelector((state) => state.fastStartBonus);
  const userId = searchParams.get('userId');

  const [id, setId] = useState(userId || '');

  useEffect(() => {
    if (userId) {
      dispatch(getFastStartBonusList(searchParams));
    }
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Bonuses' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader loading={loading} title={PAGE_TITLE} />
              <CardBody className='pt-0'>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (id) {
                      handleSetUrlSearchParams('userId', id, setSearchParams);
                    } else {
                      toast.error('Please insert User ID');
                    }
                  }}>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row>
                      <Col md={3} sm={4}>
                        <SearchInputFilter
                          onChange={(e: any) => setId(e.target.value)}
                          value={id}
                          placeholder='Search User ID'
                        />
                      </Col>
                      <Col md={3} sm={4}>
                        <SearchSubmitButton />
                      </Col>
                    </Row>
                  </CardBody>
                </form>
              </CardBody>
            </Card>
            {userId && (
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={
                      list?.usersWithCashbackBonuses?.[0]?.cashbackBonuses
                        .length
                        ? list?.usersWithCashbackBonuses?.[0]?.cashbackBonuses
                        : list?.usersWithCashbackBonuses || []
                    }
                    divClass='table-responsive table-card mb-1'
                    tableClass='align-middle table-nowrap'
                    theadClass='table-light text-muted'
                  />
                  <Pagination
                    totalCount={list.count}
                    data={
                      list?.usersWithCashbackBonuses?.[0]?.cashbackBonuses ||
                      list?.usersWithCashbackBonuses
                    }
                    params={searchParams}
                    setParams={setSearchParams}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FastStartBonusPage;
