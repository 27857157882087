import React, { Fragment } from 'react';
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { Button, CardBody, Col, Input, Row, Table } from 'reactstrap';
import {
  CompaniesGlobalFilter,
  ContactsGlobalFilter,
  CryptoOrdersGlobalFilter,
  CustomersGlobalFilter,
  InvoiceListGlobalSearch,
  LeadsGlobalFilter,
  NFTRankingGlobalFilter,
  OrderGlobalFilter,
  ProductsGlobalFilter,
  TaskListGlobalFilter,
  TicketsListGlobalFilter
} from '../../Components/Common/GlobalSearchFilter';
import { DefaultColumnFilter } from './filters';

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isProductsFilter,
  isLeadsFilter,
  SearchPlaceholder
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody className='border border-dashed border-end-0 border-start-0'>
        <form>
          <Row>
            <Col sm={5}>
              <div
                className={
                  isProductsFilter ||
                  isContactsFilter ||
                  isCompaniesFilter ||
                  isNFTRankingFilter
                    ? 'search-box me-2 mb-2 d-inline-block'
                    : 'search-box me-2 mb-2 d-inline-block col-12'
                }>
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id='search-bar-0'
                  type='text'
                  className='form-control search /'
                  placeholder={SearchPlaceholder}
                  value={value || ''}
                />
                <i className='bx bx-search-alt search-icon'></i>
              </div>
            </Col>
            {isProductsFilter && <ProductsGlobalFilter />}
            {isCustomerFilter && <CustomersGlobalFilter />}
            {isOrderFilter && <OrderGlobalFilter />}
            {isContactsFilter && <ContactsGlobalFilter />}
            {isCompaniesFilter && <CompaniesGlobalFilter />}
            {isLeadsFilter && <LeadsGlobalFilter />}
            {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
            {isInvoiceListFilter && <InvoiceListGlobalSearch />}
            {isTicketsListFilter && <TicketsListGlobalFilter />}
            {isNFTRankingFilter && <NFTRankingGlobalFilter />}
            {isTaskListFilter && <TaskListGlobalFilter />}
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

const TableContainer = ({
  columns,
  data,
  divClass,
  tableClass,
  theadClass,
  trClass = undefined,
  thClass = undefined,
  isGlobalSearch = undefined,
  isGlobalFilter = undefined,
  isProductsFilter = undefined,
  isCustomerFilter = undefined,
  isOrderFilter = undefined,
  isContactsFilter = undefined,
  isCompaniesFilter = undefined,
  isLeadsFilter = undefined,
  isCryptoOrdersFilter = undefined,
  isInvoiceListFilter = undefined,
  isTicketsListFilter = undefined,
  isNFTRankingFilter = undefined,
  isTaskListFilter = undefined,
  isAddOptions = undefined,
  isAddUserList = undefined,
  handleOrderClicks = undefined,
  handleUserClick = undefined,
  handleCustomerClick = undefined,
  isAddCustList = undefined,
  SearchPlaceholder = undefined
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageSize: 200
      }
    },

    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' ' : '') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className='mb-3'>
        {isGlobalSearch && (
          <Col md={1}>
            <select
              className='form-select'
              value={pageSize}
              onChange={onChangeInSelect}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isTaskListFilter={isTaskListFilter}
            SearchPlaceholder={SearchPlaceholder}
          />
        )}
        {isAddOptions && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button
                type='button'
                color='success'
                className='btn-rounded  mb-2 me-2'
                onClick={handleOrderClicks}>
                <i className='mdi mdi-plus me-1' />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button
                type='button'
                color='primary'
                className='btn mb-2 me-2'
                onClick={handleUserClick}>
                <i className='mdi mdi-plus-circle-outline me-1' />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button
                type='button'
                color='success'
                className='btn-rounded mb-2 me-2'
                onClick={handleCustomerClick}>
                <i className='mdi mdi-plus me-1' />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className={divClass} style={{ minHeight: '600px' }}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={thClass}
                    {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {1 === 0 && (
        <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button
                color='primary'
                onClick={previousPage}
                disabled={!canPreviousPage}>
                {'<'}
              </Button>
            </div>
          </Col>
          <Col className='col-md-auto d-none d-md-block'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className='col-md-auto'>
            <Input
              type='number'
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button
                color='primary'
                onClick={nextPage}
                disabled={!canNextPage}>
                {'>'}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default TableContainer;
