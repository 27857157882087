import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';
import { columns } from './columns';
import { Filters } from './filters';
import { getTaskList, addTask } from './slice';
import { AddTask } from './addTask';
import { TaskStatus, TaskListType } from './types';

import { getUserFromLS } from '../../../helpers/localstorage-helper';

const PAGE_TITLE = 'Task List';

const INITIAL_PARAMS: {
  countPerPage: string;
  numberOfPage: string;
} = {
  countPerPage: '10',
  numberOfPage: '1'
};

const TaskList = () => {
  const dispatch = useAppDispatch();
  const user = getUserFromLS();

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);

  const [title, setTitle] = useState<string>('');

  const { loading, data, totalCount } = useAppSelector(
    (state) => state.taskList
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data = {
      userId: user?.id,
      statusId: TaskStatus.IN_PROGRESS,
      title,
      description: '',
      uiCategoryId: 2
    };

    dispatch(addTask(data));
    handleClearState();
  };

  const handleClearState = () => {
    setTitle('');
  };

  const findInProgressTask = () => {
    const task = data.find(
      (task) => task?.status?.statusId === TaskStatus.IN_PROGRESS
    );

    if (
      user?.id === task?.user?.id &&
      task?.status?.statusId === TaskStatus.IN_PROGRESS
    ) {
      return (
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            Active task: <b>{task.title}</b>
          </div>
          <div className='d-flex'>
            <button className='btn btn-primary' type='submit'>
              <i className='ri-pause-line'></i>
            </button>
            <div style={{ width: 5 }}></div>
            <button className='btn btn-primary' type='submit'>
              <i className='ri-task-fill'></i>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <form onSubmit={handleSubmit}>
          <div className='d-flex'>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                aria-label="Recipient's username with two button addons"
                placeholder='Add Task'
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
            </div>
            <div style={{ width: 10 }}></div>

            <button className='btn btn-primary' type='submit'>
              <i className='ri-play-line'></i>
            </button>
          </div>
        </form>
      );
    }
  };

  useEffect(() => {
    findInProgressTask();
    dispatch(getTaskList(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card>
              <div className='col-6 m-2'>{findInProgressTask()}</div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading}
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
                actions={<div>{/* <AddTask /> */}</div>}
              />
              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />

                <TableContainer
                  columns={columns}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TaskList;
