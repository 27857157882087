import moment from 'moment';

import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { CsvExport } from '../../../Components/Custom/CsvExport';
import ExportCSVModal from '../../../Components/Custom/ExportCSVModal';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { paramsForExport, setDocumentTitle } from '../../../helpers/helpers';

import { columns } from './columns';
import { Filters } from './filters';
import {
  exportHeaders,
  exportPackageOrders,
  getPackageOrders,
  removePackageOrdersForExport
} from './slice';

const PAGE_TITLE = 'Package Orders';

const INITIAL_PARAMS: {
  sortBy: string;
  sortOrder: string;
  countPerPage: string;
  numberOfPage: string;
} = {
  sortBy: 'dateCreated',
  sortOrder: 'DESC',
  countPerPage: '10',
  numberOfPage: '1'
};

const PackageOrders = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);

  const { loading, data, totalCount, loadingExport, dataForExport } =
    useAppSelector((state) => state.packageOrders);

  const [csvModal, setCsvModal] = useState(false);

  useEffect(() => {
    dispatch(getPackageOrders(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading}
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
                actions={
                  <>
                    <Button
                      style={{ marginRight: 10 }}
                      color='primary'
                      onClick={() => navigate('/conversions')}>
                      Conversions
                    </Button>
                    <CsvExport
                      action={() =>
                        dispatch(
                          exportPackageOrders(paramsForExport(searchParams))
                        )
                      }
                      data={dataForExport}
                      headers={exportHeaders}
                      disabled={loadingExport}
                      fileName={`package-orders-${moment().format(
                        'DD-MM-YYYY'
                      )}`}
                      removeDataAction={removePackageOrdersForExport}
                    />
                  </>
                }
              />
              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />

                <TableContainer
                  columns={columns}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ExportCSVModal
        isOpen={csvModal}
        setIsOpen={setCsvModal}
        data={dataForExport}
        fileName='package-orders-data'
      />
    </div>
  );
};

export default PackageOrders;
