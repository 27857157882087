export const CustomInput = ({
  placeholder,
  onChange,
  value,
  type,
  name
}: {
  searchParams?: URLSearchParams;
  setSearchParams?: any;
  param?: string;
  placeholder?: string;
  onChange?: any;
  value?: string;
  type?: string;
  name?: string;
}) => {
  return (
    <div className={'me-2 mb-2 d-inline-block col-12'}>
      <input
        onChange={(e) => onChange(e)}
        value={value}
        type={type || 'text'}
        className='form-control search /'
        placeholder={placeholder || 'Search'}
        name={name}
      />
    </div>
  );
};
