/* eslint-disable no-unused-vars */
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner
} from 'reactstrap';
import withRouter from '../../../Components/Common/withRouter';
// import logo from '../../../assets/customAssets/ultronLogo.svg';
import ParticlesAuth from '../ParticlesAuth';
import { AddTwoStepVerificationModal } from './AddTwoStepVerifivationModal';
import { ChangePasswordModal } from './ChangePasswordModal';
import { TwoStepVerificationModal } from './TwoStepVerificationModal';
import { handleSignIn } from './logic';

interface LoginProps {
  router: any;
}

const Login: FC<LoginProps> = ({ router }) => {
  const navigate = router.navigate;
  const [passwordShow, setPasswordShow] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [twoFaCode, setTwoFaCode] = useState('');
  const [twoFaModal, setTwoFaModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [addTwoFaModal, setAddTwoFaModal] = useState(false);

  const [twoFaData, setTwoFaData] = useState(null);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!email) {
      toast.error('Please fill in email input');
      return;
    }
    if (!password) {
      toast.error('Please fill in password input');
      return;
    }

    handleSignIn({
      navigate,
      email,
      password,
      twoFaCode,
      setTwoFaCode,
      setTwoFaModal,
      setLoading,
      setAddTwoFaModal,
      setTwoFaData,
      setChangePasswordModal
    });
  };

  document.title = 'Login | Ultron Admin';
  return (
    <React.Fragment>
      <TwoStepVerificationModal
        isOpen={twoFaModal}
        setIsOpen={setTwoFaModal}
        onSubmit={onSubmit}
        setTwoFaCode={setTwoFaCode}
        loading={loading}
        twoFaCode={twoFaCode}
      />

      <AddTwoStepVerificationModal
        isOpen={addTwoFaModal}
        setIsOpen={setAddTwoFaModal}
        loading={loading}
        twoFaData={twoFaData}
      />

      <ChangePasswordModal
        isOpen={changePasswordModal}
        setIsOpen={setChangePasswordModal}
        twoFaData={twoFaData}
        loading={loading}
      />

      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                  <div>
                    <Link to='/' className='d-inline-block auth-logo'>
                      {/* <img src={logo} alt='' height={50} /> */}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Welcome Back!</h5>
                      <p className='text-muted'>
                        Sign in to continue to Ultron Admin.
                      </p>
                    </div>

                    <div className='p-2 mt-4'>
                      <Form
                        onSubmit={(e) => {
                          onSubmit(e);
                        }}
                        action='#'>
                        <div className='mb-3'>
                          <Label htmlFor='email' className='form-label'>
                            Email
                          </Label>
                          <Input
                            name='email'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className='mb-3'>
                          <Label
                            className='form-label'
                            htmlFor='password-input'>
                            Password
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              placeholder='Enter Password'
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type={passwordShow ? 'text' : 'password'}
                              className='form-control pe-5'
                            />

                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                              type='button'
                              id='password-addon'
                              onClick={() => setPasswordShow(!passwordShow)}>
                              <i className='ri-eye-fill align-middle'></i>
                            </button>
                          </div>
                        </div>

                        <div className='mt-4'>
                          <Button
                            color='success'
                            className='btn btn-success w-100'
                            type='submit'>
                            {loading ? (
                              <Spinner size='sm' className='me-2' />
                            ) : null}
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
