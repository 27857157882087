import { TraidingTeamMember } from '../traidingTeamMember';
import { TeamStructureUserType } from '../types';
import { Arrows } from './common';

const SecondLevel = ({
  rootTeamMember
}: {
  rootTeamMember: TeamStructureUserType;
}) => {
  const leftChild = rootTeamMember?.leftChildUser;
  const rightChild = rootTeamMember?.rightChildUser;

  const childExist = (child: any) => {
    return child !== null && child !== undefined;
  };

  if (!childExist(leftChild) && !childExist(rightChild)) {
    return (
      <div style={{ width: '100%', marginTop: 15, display: 'flex' }}></div>
    );
  }

  if (childExist(leftChild) && !childExist(rightChild)) {
    // rightLegTotalVolume

    return (
      <div style={{ width: '100%', marginTop: 15, display: 'flex' }}>
        <div className='teams-structure'>
          <TraidingTeamMember
            structure
            id={leftChild.id}
            user={leftChild}
            leftLegTotalVolume={leftChild.leftLegTotalVolume}
            rightLegTotalVolume={leftChild.rightLegTotalVolume}
          />
          <Arrows />
        </div>
        <div className='teams-structure'></div>
      </div>
    );
  }

  if (!childExist(leftChild) && childExist(rightChild)) {
    return (
      <div style={{ width: '100%', marginTop: 15, display: 'flex' }}>
        <div className='teams-structure'></div>
        <div className='teams-structure'>
          <TraidingTeamMember
            structure
            id={rightChild.id}
            user={rightChild}
            leftLegTotalVolume={rightChild.leftLegTotalVolume}
            rightLegTotalVolume={rightChild.rightLegTotalVolume}
          />
          <Arrows />
        </div>
      </div>
    );
  }
  if (childExist(leftChild) && childExist(rightChild)) {
    return (
      <div style={{ width: '100%', marginTop: 15, display: 'flex' }}>
        <div className='teams-structure'>
          <TraidingTeamMember
            structure
            id={leftChild.id}
            user={leftChild}
            leftLegTotalVolume={leftChild.leftLegTotalVolume}
            rightLegTotalVolume={leftChild.rightLegTotalVolume}
          />
          <Arrows />
        </div>
        <div className='teams-structure'>
          <TraidingTeamMember
            structure
            id={rightChild.id}
            user={rightChild}
            leftLegTotalVolume={rightChild.leftLegTotalVolume}
            rightLegTotalVolume={rightChild.rightLegTotalVolume}
          />
          <Arrows />
        </div>
      </div>
    );
  }

  return null;
};

export { SecondLevel };
