import { Dispatch, FC, SetStateAction } from 'react';
import { DropdownItem } from 'reactstrap';
import { OPENED_MODAL } from '../types';

export const WithdrawalsDropdownItem: FC<{
  setIsOpen: Dispatch<SetStateAction<number>>;
  title: string;
  toOpen: OPENED_MODAL;
}> = ({ setIsOpen, title, toOpen }) => {
  return (
    <>
      <DropdownItem
        tag='a'
        href='/'
        className='w-100'
        onClick={(e) => {
          e.preventDefault();
        }}>
        <div style={{ width: '100%' }} onClick={() => setIsOpen(toOpen)}>
          {title}
        </div>
      </DropdownItem>
    </>
  );
};
