import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { TotalBonusState, TotalBonusType } from './types';
import { toast } from 'react-toastify';

export const getTotalBonus = createAsyncThunk(
  'getTotalBonus/fetch',
  async (id: number | null) => {
    const response = await axiosInstance.get(`/bonus/total/${id}`);
    if (response.data.data.userPackage === null) {
      toast.error('Please type valid Order ID');
    }
    return response.data.data as TotalBonusType;
  }
);

const initialState = {
  data: {},
  loading: false
} as TotalBonusState;

const getTotalBonusSlice = createSlice({
  name: 'getTotalBonus',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTotalBonus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getTotalBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default getTotalBonusSlice.reducer;
