import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import TableContainer from '../../../Components/Common/TableContainer';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getQuestionById, getQuestions, setQuestionId } from '../slice';
import { columns } from './columns';
import { AddEditQuestionModal } from './edit';
import { handleSetUrlSearchParams } from '../../../helpers/helpers';

const QuestionsAnswers = () => {
  const dispatch = useAppDispatch();

  const { questions } = useAppSelector((state) => state.faq);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');

  const [addEditModal, setAddEditModal] = useState('');

  useEffect(() => {
    dispatch(getQuestions(searchParams));
  }, [searchParams]);

  return (
    <>
      <Card>
        <CardBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSetUrlSearchParams('search', search, setSearchParams);
            }}>
            <Row>
              <Col md={3} sm={4}>
                <SearchInputFilter
                  onChange={(e: any) => setSearch(e.target.value)}
                  value={search}
                  placeholder='Search'
                />
              </Col>
              <Col md={3} sm={4}>
                <SearchSubmitButton />
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>

      <Card>
        <CardHeader className='border-0'>
          <Row className='align-items-center gy-3'>
            <div className='col-sm d-flex gap-3 flex-wrap align-items-center'>
              <h5 className='card-title mb-0'>Questions Answers</h5>
            </div>
            <div className='col-sm-auto'>
              <div className='d-flex gap-3 flex-wrap align-items-center'>
                <Button
                  color='primary'
                  onClick={() => dispatch(setQuestionId('add'))}>
                  Add Question
                </Button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody className='pt-0'>
          <TableContainer
            columns={columns}
            data={questions || []}
            divClass='table-responsive table-card mb-1'
            tableClass='align-middle table-nowrap'
            theadClass='table-light text-muted'
          />
        </CardBody>
      </Card>
      <AddEditQuestionModal />
    </>
  );
};

export default QuestionsAnswers;
