import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';

export const getConversions = createAsyncThunk(
  'getConversions/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/package/converted', {
      params
    });

    return response.data.data;
  }
);

export interface Package {
  id: number;
  name: string;
}

export interface Conversions {
  id: number;
  price: number;
  userId: number;
  dateCreated: string;
  datePaid: string | null;
  dateConverted: string;
  loyaltyPoints: number;
  package: Package;
}

export interface ConversionsState {
  data: Conversions[];
  loading: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as ConversionsState;

const conversionsSlice = createSlice({
  name: 'conversions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConversions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConversions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.userPackages as Conversions[];
      state.totalCount = payload.count;
    });
    builder.addCase(getConversions.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default conversionsSlice.reducer;
