import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import {
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH
} from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
}

export const Filters: FC<FiltersProps> = ({ params, setParams }) => {
  const filterInputs = [
    {
      param: 'packageRankId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Package Rank ID'
    },
    { param: 'userId', type: INPUT_TYPE_SEARCH, placeholder: 'Search User ID' },

    { param: '', type: INPUT_TYPE_DATE_RANGE }
  ];
  return (
    <>
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
    </>
  );
};
