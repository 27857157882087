import { handleValidDate, handleValidTime } from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Coupon ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'From User ID',
    accessor: 'buyerUser',
    Cell: (cell: any) => {
      return cell?.value?.id;
    }
  },

  {
    id: 3,
    Header: 'First Name',
    accessor: 'buyerUser',
    Cell: (cell: any) => {
      return <>{cell.value.name}</>;
    }
  },

  {
    id: 4,
    Header: 'Last Name',
    accessor: 'buyerUser',
    Cell: (cell: any) => {
      return <>{cell.value.surname}</>;
    }
  },
  {
    id: 5,
    Header: 'To User ID',
    accessor: 'receiverUser',
    Cell: (cell: any) => {
      return <>{cell.value.id}</>;
    }
  },

  {
    id: 6,
    Header: 'First Name',
    accessor: 'receiverUser',
    Cell: (cell: any) => {
      return <>{cell.value.name}</>;
    }
  },

  {
    id: 7,
    Header: 'Last Name',
    accessor: 'receiverUser',
    Cell: (cell: any) => {
      return <>{cell.value.surname}</>;
    }
  },

  {
    id: 8,
    Header: 'For Hub',
    accessor: 'packageName',
    Cell: (cell: any) => {
      return <>{cell.value}</>;
    }
  },

  {
    id: 9,
    Header: 'Purchased order ID',
    accessor: 'purchasedUserPackageId',
    Cell: (cell: any) => {
      return <>{cell.value}</>;
    }
  }
];
