import { TeamStructureUserType } from '../types';
import { SecondLevel } from './secondLevel';
import { ThirdLevel } from './thirdLevel';
import './tree.scss';
import { ZeroLevel } from './zeroLevel';

const Tree = ({
  rootTeamMember
}: {
  rootTeamMember: TeamStructureUserType;
}) => {
  return (
    <>
      <ZeroLevel rootTeamMember={rootTeamMember} />
      <SecondLevel rootTeamMember={rootTeamMember} />
      <ThirdLevel
        leftChild={rootTeamMember?.leftChildUser}
        rightChild={rootTeamMember?.rightChildUser}
      />
    </>
  );
};

export { Tree };
