import { TeamMember } from '../teamMember';
import { Arrows } from './common';
import { SecondLevel } from './secondLevel';

const ThirdLevel = ({
  leftChild,
  rightChild,
  hideArrows = false,
  isLast = false
}: {
  rightChild: any;
  leftChild: any;
  hideArrows?: boolean;
  isLast?: boolean;
}) => {
  const childExist = (child: any) => {
    return child !== null && child !== undefined;
  };

  if (!childExist(leftChild) && !childExist(rightChild)) return null;

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      {childExist(leftChild) ? (
        <>
          {childExist(leftChild?.leftChildUser) ? (
            <>
              <div className='teams-structure'>
                <TeamMember
                  structure
                  id={leftChild?.leftChildUser?.id}
                  user={leftChild?.leftChildUser}
                  leftLegTotalVolume={
                    leftChild?.leftChildUser.leftLegTotalVolume
                  }
                  rightLegTotalVolume={
                    leftChild?.leftChildUser.rightLegTotalVolume
                  }
                />
                {!isLast && (
                  <>
                    {!hideArrows && <Arrows />}
                    <SecondLevel rootTeamMember={leftChild?.leftChildUser} />

                    <ThirdLevel
                      leftChild={leftChild?.leftChildUser?.leftChildUser}
                      rightChild={leftChild?.leftChildUser?.rightChildUser}
                      hideArrows
                      isLast
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <div className='teams-structure'></div>
          )}

          {childExist(leftChild?.rightChildUser) ? (
            <div className='teams-structure'>
              <TeamMember
                structure
                id={leftChild?.rightChildUser?.id}
                user={leftChild?.rightChildUser}
                leftLegTotalVolume={
                  leftChild?.rightChildUser.leftLegTotalVolume
                }
                rightLegTotalVolume={
                  leftChild?.rightChildUser.rightLegTotalVolume
                }
              />
              {!isLast && (
                <>
                  {!hideArrows && <Arrows />}
                  <SecondLevel rootTeamMember={leftChild?.rightChildUser} />

                  <ThirdLevel
                    leftChild={leftChild?.rightChildUser?.leftChildUser}
                    rightChild={leftChild?.rightChildUser?.rightChildUser}
                    hideArrows
                    isLast
                  />
                </>
              )}
            </div>
          ) : (
            <div className='teams-structure'></div>
          )}
        </>
      ) : (
        <div style={{ width: '100%' }}></div>
      )}
      {childExist(rightChild) ? (
        <>
          {childExist(rightChild?.leftChildUser) ? (
            <div className='teams-structure'>
              {!isLast && (
                <>
                  <TeamMember
                    structure
                    id={rightChild?.leftChildUser?.id}
                    user={rightChild?.leftChildUser}
                    leftLegTotalVolume={
                      rightChild?.leftChildUser.leftLegTotalVolume
                    }
                    rightLegTotalVolume={
                      rightChild?.leftChildUser.rightLegTotalVolume
                    }
                  />
                  {!hideArrows && <Arrows />}
                  <SecondLevel rootTeamMember={rightChild?.leftChildUser} />

                  <ThirdLevel
                    leftChild={rightChild?.leftChildUser?.leftChildUser}
                    rightChild={rightChild?.leftChildUser?.rightChildUser}
                    hideArrows
                    isLast
                  />
                </>
              )}
            </div>
          ) : (
            <div className='teams-structure'></div>
          )}

          {childExist(rightChild?.rightChildUser) ? (
            <div className='teams-structure'>
              <TeamMember
                structure
                id={rightChild?.rightChildUser?.id}
                user={rightChild?.rightChildUser}
                leftLegTotalVolume={
                  rightChild?.rightChildUser.leftLegTotalVolume
                }
                rightLegTotalVolume={
                  rightChild?.rightChildUser.rightLegTotalVolume
                }
              />
              {!isLast && (
                <>
                  {!hideArrows && <Arrows />}
                  <SecondLevel rootTeamMember={rightChild?.rightChildUser} />

                  <ThirdLevel
                    leftChild={rightChild?.rightChildUser?.leftChildUser}
                    rightChild={rightChild?.rightChildUser?.rightChildUser}
                    hideArrows
                    isLast
                  />
                </>
              )}
            </div>
          ) : (
            <div className='teams-structure'></div>
          )}
        </>
      ) : (
        <div style={{ width: '100%' }}></div>
      )}
    </div>
  );
};

export { ThirdLevel };
