import Select from 'react-select';
import { handleFilterChange } from '../../helpers/helpers';
import { Input } from 'reactstrap';

export const AmountFilter = ({
  searchParams,
  setSearchParams,
  param,
  placeholder
}: {
  searchParams: URLSearchParams;
  setSearchParams: any;
  param: string;
  placeholder?: string;
}) => {
  return (
    <div className='input-group'>
      <span className='input-group-text'>$</span>
      <Input
        className='form-control'
        aria-label='Amount (to the nearest dollar)'
        onChange={(e) =>
          handleFilterChange(param, e.target.value, setSearchParams)
        }
        value={searchParams.get(param) || ''}
        type='number'
        placeholder={placeholder}
      />
      <span className='input-group-text'>.00</span>
    </div>
  );
};
