import React from 'react';

import { useAppSelector } from '../../../../../app/hooks';

import TableContainer from '../../../../../Components/Common/TableContainer';

import { columns } from './columns';

export const Wallets: React.FC = () => {
  const { data } = useAppSelector((state) => state.userDetails);

  return (
    <>
      <TableContainer
        columns={columns}
        data={data?.userWallets || []}
        divClass='table-responsive table-card mb-1'
        tableClass='align-middle table-nowrap'
        theadClass='table-light text-muted'
      />
    </>
  );
};
