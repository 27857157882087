import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Select from 'react-select';
import { Button, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { Modal } from '../../../Components/Custom/modal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  addQuestion,
  editQuestion,
  getQuestionById,
  removeQuestionForEdit,
  removeQuestionId
} from '../slice';
import { AddEditQuestion } from '../types';

//  interface AddEditQuestionModalInterface {
//   isOpen: boolean;
//   setIsOpen: Dispatch<SetStateAction<boolean>>
// }

export const AddEditQuestionModal = () => {
  const dispatch = useAppDispatch();

  const { loading, questionForEdit, categories, questionId } = useAppSelector(
    (state) => state.faq
  );

  const [categoryId, setCategoryId] = useState(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [keywords, setKeywords] = useState('');

  const categoriesOptions: any = [];
  categories?.forEach((c) => {
    categoriesOptions.push({ label: c.name, value: c.id });
    {
      c.subCategories?.forEach((sc) =>
        categoriesOptions.push({ label: sc.name, value: sc.id })
      );
    }
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (categoryId === null) return;

    const dataEdit = {
      id: questionForEdit?.id,
      categoryId: categoryId['value'],
      question,
      answer,
      keywords: [keywords]
    } as AddEditQuestion;

    const { id, ...dataAdd } = dataEdit;

    if (typeof questionId === 'string') {
      dispatch(addQuestion(dataAdd));
    } else {
      dispatch(editQuestion(dataEdit));
    }

    handleClearState();
  };

  const [editor, setEditor] = useState(EditorState.createEmpty());

  const handleClearState = () => {
    setEditor(EditorState.createEmpty());
    setAnswer('');
    setQuestion('');
    setCategoryId(null);
    setKeywords('');
    dispatch(removeQuestionForEdit());
    dispatch(removeQuestionId());
  };

  const handleEditorChange = (data: any) => {
    setEditor(data);
    const dataForDatabase = draftToHtml(convertToRaw(data.getCurrentContent()));
    setAnswer(dataForDatabase);
  };

  useEffect(() => {
    if (questionId && typeof questionId !== 'string')
      dispatch(getQuestionById(questionId));
  }, [questionId]);

  useEffect(() => {
    if (questionForEdit) {
      setQuestion(questionForEdit?.question);
      setAnswer(questionForEdit?.answer);
      setKeywords(questionForEdit?.keywords);

      const getCategory = questionForEdit?.categoryId
        ? categoriesOptions.find(
            (e: any) => e.value == questionForEdit?.categoryId
          )
        : '';

      setCategoryId(getCategory);

      const blocksFromHTML: any = convertFromHTML(questionForEdit?.answer);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      setEditor(EditorState.createWithContent(content));
    }
  }, [questionForEdit?.id]);

  useEffect(() => {
    if (answer) {
      const blocksFromHTML: any = convertFromHTML(answer);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      setEditor(EditorState.createWithContent(content));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={questionId ? true : false}
      setIsOpen={() => {
        dispatch(removeQuestionId());
        dispatch(removeQuestionForEdit());
        handleClearState();
      }}
      title='Question/Answer'
      form
      size='xl'
      onFormSubmit={handleSubmit}
      actions={
        <>
          <Button color='primary' onClick={handleClearState}>
            Cancel
          </Button>
          <Button color='primary' type='submit'>
            {questionId === 'add' ? 'Add' : 'Edit'}
          </Button>
        </>
      }>
      {!loading ? (
        <Row>
          <Col lg={12}>
            <Label for='title'>Question</Label>
            <Input
              id='title'
              className='w-100 mb-2'
              type='text'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
          <Col lg={6}>
            <Label className='w-100 text-left' for='title'>
              Category ID
            </Label>
            <div className='mb-2 d-inline-block col-12'>
              <Select
                options={categoriesOptions}
                placeholder='Select Category ID'
                onChange={(e: any) => setCategoryId(e)}
                value={categoryId}
              />
            </div>
          </Col>
          <Col lg={6}>
            <Label className='w-100 text-left' for='title'>
              Keywords
            </Label>
            <Input
              id='keywords'
              className='w-100 mb-2'
              type='text'
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
          <Col lg={12}>
            <Label for='title'>Answer</Label>
            <Editor
              editorState={editor}
              onEditorStateChange={handleEditorChange}
              // toolbarClassName='toolbar'
              // wrapperClassName='wrapper'
              // editorClassName='editor'
              wrapperStyle={{ background: '#fff', color: '#000' }}
              editorStyle={{ minHeight: 300, padding: 20 }}
              toolbarStyle={{}}
              stripPastedStyles
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'textAlign',
                  // 'colorPicker',
                  'link',
                  'history'
                ],
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined
                },
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: [
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'superscript',
                    'subscript'
                  ]
                }
              }}
            />
          </Col>
        </Row>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};
