import { FC, FormEventHandler, ReactNode } from 'react';
import {
  ModalBody,
  Modal as ModalComponent,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: any;
  title: string;
  actions: ReactNode;
  children: ReactNode;
  size?: string;
  form?: boolean;
  onFormSubmit?: any;
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  actions,
  children,
  size,
  form,
  onFormSubmit
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      toggle={setIsOpen}
      className='modal-dialog-centered'
      size={size}>
      <ModalHeader toggle={setIsOpen}>{title}</ModalHeader>
      {form ? (
        <form onSubmit={(e) => onFormSubmit(e)}>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{actions}</ModalFooter>
        </form>
      ) : (
        <>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{actions}</ModalFooter>
        </>
      )}
    </ModalComponent>
  );
};
