import { formatUSDT, handleValidDate } from '../../../helpers/helpers';
import userDummy from '../../../assets/images/users/user-dummy-img.jpg';
import { DirectUser } from './types';

export const FastStartDirectUser = ({ user }: { user: DirectUser }) => {
  return (
    <div
      className={`border d-flex flex-column align-items-center text-center p-2`}>
      <div className='profile-user position-relative d-inline-block mx-auto'>
        <img
          src={user.profileImageUrl || userDummy}
          className='rounded-circle avatar-md  img-thumbnail user-profile-image mb-2'
          alt='user-profile'
        />
        {/* {user?.id && <h5 className='mb-2 '>{user.id || ''}</h5>} */}
        {user?.username && <p className='mb-1 fs-11'>{user?.username}</p>}
        {user?.package?.datePaid && (
          <p className='mb-1 fs-11'>
            {handleValidDate(user?.package?.datePaid)}
          </p>
        )}
        {user?.package?.price && (
          <p className='mb-1 fs-11'>{user?.package?.price} USDT</p>
        )}
      </div>
    </div>
  );
};
