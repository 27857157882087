import { Unilevel } from './sections/Unilevel/Unilevel';
export interface UserDetailsType {
  id: number;
  activationStatus: number;
  referrerId: number;
  parentId: number;
  username: string;
  name: string;
  surname: string;
  dateOfBirth: string;
  country: string;
  phoneNumber: string;
  userType: number;
  leftLegTotalVolume: number;
  rightLegTotalVolume: number;
  leftLegVolume: number;
  rightLegVolume: number;
  leftLegShadowVolume: number;
  rightLegShadowVolume: number;
  leftLegTotalTradingVolume: number;
  rightLegTotalTradingVolume: number;
  leftLegTradingVolume: number;
  rightLegTradingVolume: number;
  directTotalVolume: number;
  personalOrderAmount: number;
  personalOrderAmountWithAutostakeIntact: number;
  poForUv: number;
  personalVolume: number;
  directBonusCommissionPercentage: number;
  licenceEndDate: string;
  smsCode: {
    code: number;
    dateCreated: string;
  };
  email: string;
  registrationStatus: number;
  creationType: number;
  profileImageUrl: string;
  dateCreated: string;
  userRank: {
    id: number;
    rank: {
      rankId: number;
      name: string;
    };
  };
  userPackage: {
    id: number;
    price: number;
    shoppingPoints: number;
    donationPoints: number;
    teamBonusPercent: number;
    status: number;
    dateCreated: string;
    datePaid: string;
    pdfUrl: string;
    proFormaUrl: string;
    networkType: number;
    paymentAddress: string;
    dateLimitForAutoStake: string;
    calculationStatus: number;
    isAutostakeIntact: true;
    package: {
      packageId: number;
      name: string;
      price: number;
      teamBonusPercent: number;
      order: number;
      stakeRatio: number;
      weekCapRuleLimit: number;
      volumePoints: number;
      type: number;
    };
    packageRank: {
      packageRankId: number;
      name: string;
    };
    user: {
      id: number;
      email: string;
      profileImageUrl: string;
      name: string;
      surname: string;
      dateCreated: string;
    };
  };
  userWallets: [
    {
      id: number;
      address: string;
      dateCreated: string;
      status: number;
      label: string;
    }
  ];
  kycStatus: KycStatusType;
  userTickets: {
    shoppingPoints: number;
    donationPoints: number;
    comissionPercent: number;
    pendingRewardPoints: number;
    availableRewardPoints: number;
    givvoPoints: number;
    loyaltyPoints: number;
  };
  address: {
    id: number;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    country: string;
    countryCode: string;
    latitude: number;
    longitude: number;
    isPermanent: true;
    city: string;
  };
  adminComments: [UserComment];
}

export interface UpdateUserEmailType {
  userId: number;
  email: string;
}

export interface UpdateUserDetailsType {
  userId: number;
  name?: string;
  surname?: string;
}

export interface UpdateUserAddressType {
  addressId: number;
  addressLine1?: string;
  zipCode?: string;
  country?: string;
  city?: string;
}

export interface KycStatusType {
  isAddressAdded: true;
  isWalletAdded: true;
  isDocumentAdded: true;
  isSelfieAdded: true;
  isKycCompleted: true;
  isAllCompleted: true;
  selfieImageUrl: string;
  documentImageUrls: string[];
  documentType: number;
  documentHolder: string;
  isKycVerified: true;
  arePersonalDetailsAdded: true;
  areTermsAccepted: true;
}

export interface UserComment {
  id: number;
  adminUserId: number;
  userId: number;
  title: string;
  description: string;
  dateCreated: string;
  adminFullName: string;
}

export interface UserHubsInfo {
  staking: {
    summary: {
      baseStake: number;
      totalStake: number;
      availableBalance: number;
      totalOrderAmount: number;
      totalOrderWithAutostakeIntactAmount: number;
    };
    userPackages: [StakingHubType];
    userAddress: string;
  };
  gaming: {
    summary: {
      totalRsp: number;
      totalUsdAmount: number;
      totalOrderAmount: number;
      availableUsdtToClaim: number;
      availableUsdtToWithdraw: number;
    };
    userPackages: [GamingHubType];
    userAddress: string;
  };
  payment: {
    summary: {
      totalRsp: number;
      totalUsdAmount: number;
      totalOrderAmount: number;
    };
    userPackages: [GamingHubType];
    userAddress: string;
  };

  trading: {
    summary: {
      totalOrderAmount: number;
      maxDeposit: number | string;
      tradingBinary: number;
    };
    userPackages: [TradingType];
  };
  qualifiedRank: string;
  lifetimeRank: string;
  walletSummary: {
    totalOrder: number;
    totalOrderWithAutostakeIntactAmount: number;
    totalAvailable: number;
  };
}

export interface StakingHubType {
  id: number;
  nftId: number;
  packageId: number;
  type: string;
  name: string;
  price: number;
  baseStake: number;
  totalStake: number;
  autocompound: true;
  disableAtNextWeek: true;
  isAutostakeIntact: true;
  availableStake: number;
  dateLimitForAutoStake: string;
  lastAutocompoundTimestamp: number;
  myEarnings: number;
  nextUnlockAmount: number;
  dateOfCreation: number;
  dateOfPurchase: number;
  nextUnlockDate: number;
}
export interface GamingHubType {
  id: number;
  nftId: number;
  packageId: number;
  name: string;
  price: number;
  rsp: number;
  totalUsdValue: number;
  usdValue: number;
  dateOfCreation: number;
  dateOfPurchase: number;
}

export interface TradingType {
  id: number;
  name: string;
  image: string;
  packageId: number;
  price: number;
  creationDate: number; // Unix timestamp
  purchaseDate: number; // Unix timestamp
  expiryDate: number; // Unix timestamp
  maxDeposit: number | string;
  doubleTradingBinary: number;
}

export interface UnilevelType {
  currentPackageRank: {
    packageRankId: number;
    name: string;
    isPreviousRank: boolean;
    isCurrentRank: boolean;
    isNextRank: boolean;
    isFutureRank: boolean;
    hasPaidStakingHub: boolean;
    isRankStatusActive: boolean;
    shouldDisplayActivateRankStatus: boolean;
    isRegisteredMember: boolean;
    hasMemberPaid: boolean;
    hasPartnerWithPaidPackageInLeftLeg: boolean;
    hasPartnerWithPaidPackageInRightLeg: boolean;
    paidTeamCurrent: null | number;
    paidTeamLimit: null | number;
    directVolumeCurrent: null | number;
    directVolumeLimit: null | number;
    strongTeamCurrent: null | number;
    strongTeamLimit: null | number;
    weekCapRuleLimit: null | number;
    personalOrderAmount: null | number;
    personalOrderLimit: null | number;
    isRewardAvailable: boolean;
    isRewardAlreadyClaimed: boolean;
    canClaimReward: boolean;
    rewards: {
      id: number;
      reward: {
        packageRankRewardId: number;
        name: string;
        type: number;
        order: number;
        amount: number;
        proAmount: null | number;
      };
    }[];
  };
  nextPackageRank: {
    packageRankId: number;
    name: string;
    isPreviousRank: boolean;
    isCurrentRank: boolean;
    isNextRank: boolean;
    isFutureRank: boolean;
    hasPaidStakingHub: boolean;
    isRankStatusActive: boolean;
    shouldDisplayActivateRankStatus: boolean;
    isRegisteredMember: boolean;
    hasMemberPaid: boolean;
    hasPartnerWithPaidPackageInLeftLeg: boolean;
    hasPartnerWithPaidPackageInRightLeg: boolean;
    paidTeamCurrent: number;
    paidTeamLimit: number;
    directVolumeCurrent: number;
    directVolumeLimit: number;
    strongTeamCurrent: number;
    strongTeamLimit: number;
    weekCapRuleLimit: number;
    personalOrderAmount: number;
    personalOrderLimit: number;
    isRewardAvailable: boolean;
    isRewardAlreadyClaimed: boolean;
    canClaimReward: boolean;
    rewards: {
      id: number;
      reward: {
        packageRankRewardId: number;
        name: string;
        type: number;
        order: number;
        amount: number;
        proAmount: null | number;
      };
    }[];
  };
  directTeamUsers: {
    id: number;
    countryCode: string;
    username: string;
    totalVolume: number;
    effectiveVolume: number;
    percentage: number;
    leg: number;
  }[];
}

export interface RankType {
  rank: {
    packageRankId: number;
    name: string;
    isPreviousRank: boolean;
    isCurrentRank: boolean;
    isNextRank: boolean;
    isFutureRank: boolean;
    hasPaidStakingHub: boolean;
    isRankStatusActive: boolean;
    shouldDisplayActivateRankStatus: boolean;
    isRegisteredMember: boolean;
    hasMemberPaid: boolean;
    hasPartnerWithPaidPackageInLeftLeg: boolean;
    hasPartnerWithPaidPackageInRightLeg: boolean;
    paidTeamCurrent: number;
    paidTeamLimit: number;
    directVolumeCurrent: number;
    directVolumeLimit: number;
    strongTeamCurrent: number;
    strongTeamLimit: number;
    weekCapRuleLimit: number;
    personalOrderAmount: number;
    personalOrderLimit: number;
    isRewardAvailable: boolean;
    isRewardAlreadyClaimed: boolean;
    canClaimReward: boolean;
    imageUrl: string;
    rewards: {
      id: number;
      reward: {
        packageRankRewardId: number;
        name: string;
        type: number;
        order: number;
        amount: number | null;
        proAmount: number | null;
      };
    }[];
  };
}

interface PersonalSalesProgress {
  required: number;
  percentage: number;
  showThumb: boolean;
}

interface Tier {
  name: string;
  hasConditionForTier: boolean;
  reward: string;
  personalSalesProgress: PersonalSalesProgress;
  directVolumeProgres: PersonalSalesProgress;
  paidTeamProgres: PersonalSalesProgress;
}

export interface Event {
  tiers: Tier[];
  startDate: string;
  endDate: string;
  personalSalesAmount: number;
  directVolumeAmount: number;
  paidTeamAmount: number;
}
// BOOTCAMP

interface Progress {
  current: number;
  required: number;
  percentage: number;
}

export interface UserProgress {
  rankName: string;
  paidTeam: Progress;
  directVolume: Progress;
  strongTeam: Progress;
  personalOrder: Progress;
  businessVolume: Progress;
}

interface User {
  userId: number;
  username: string;
  sponsor: string | null;
  country: string;
  currentRank: string;
  hasReachedRankInPeriod: boolean;
  uv: number;
  progress: UserProgress;
  isDiamond: boolean;
}

interface DirectUser extends User {}

export enum BootcampProgressType {
  Simple = 1,
  Complex = 2
}
export interface BootcampEvent {
  progressType: BootcampProgressType;
  user: User;
  directUsers: DirectUser[];
}

// BOOTCAMP

//USER TYPES
export enum UserType {
  Regular = 1,
  Restricted = 2
}

export interface Invoice {
  id: number;
  weekStartDate: string;
  weekEndDate: string;
  amount: number;
  isPaid: boolean;
}

export interface Transaction {
  id: string;
  dateCreated: string;
  rewardPoints: number;
  shoppingPoints: number;
  ulxPoints: number;
  groupType: number;
  status: number;
}

export interface Withdrawal {
  id: number;
  dateCreated: string;
  datePaid: string;
  amount: number;
  feeAmount: number;
  paidAmount: number;
  wallet: string;
  networkType: number;
  status: number;
}

export interface Conversion {
  id: string;
  dateCreated: string;
  amount: number;
  type: string;
  status: string;
}

export interface WalletSummary {
  totalOrder: number;
  totalOrderAutostakeIntact: number;
  availableBalance: number;
  availableUsdtToWithdraw: number;
  userAddress: string;
}

export interface WithdrawAvailability {
  isAvailable: boolean;
  limitDate: string;
  withdrawUnavailableReason: string;
}

export interface CurrentVirtualOfficeLicence {
  licenceId: number;
  dateEnd: string;
  licenceName: string;
}

export interface UserHubsSummary {
  qualifiedRank: string;
  lifetimeRank: string;
  walletSummary: WalletSummary;
  withdrawAvailability: WithdrawAvailability;
  currentVirtualOfficeLicence: CurrentVirtualOfficeLicence;
}

export interface HubsTradingPackage {
  id: number;
  packageId: number;
  name: string;
  image: string;
  price: number;
  dateOfCreation: string;
  dateOfPurchase: string;
  dateFinupBonus: string;
  expiryDate: string;
  doubleTradingBinary: number;
  isConvertable: boolean;
  dateConverted: string;
  loyaltyPoints: number;
  potentialLoyaltyPoints: number;
  isMemeBonusActivated: boolean;
  memeBonusActivationPrice: number;
}

export interface HubsTradingSummary {
  totalOrderAmount: number;
  maxDeposit: string;
  tradingBinary: number;
}

export interface HubsTradingInfo {
  summary: HubsTradingSummary;
  userPackages: HubsTradingPackage[];
}

export interface HubsStakingPackage {
  id: number;
  nftId: number;
  packageId: number;
  type: string;
  name: string;
  price: number;
  baseStake: number;
  totalStake: number;
  autocompound: boolean;
  disableAtNextWeek: boolean;
  isAutostakeIntact: boolean;
  availableStake: number;
  dateLimitForAutoStake: string;
  lastAutocompoundTimestamp: number;
  myEarnings: number;
  nextUnlockAmount: number;
  dateOfCreation: string;
  dateOfPurchase: string;
  nextUnlockDate: number;
  isConvertable: boolean;
  dateConverted: string;
  loyaltyPoints: number;
  potentialLoyaltyPoints: number;
}

export interface HubsStakingSummary {
  baseStake: number;
  totalStake: number;
  availableBalance: number;
  totalOrderAmount: number;
  totalOrderWithAutostakeIntactAmount: number;
}

export interface HubsStakingInfo {
  summary: HubsStakingSummary;
  userPackages: HubsStakingPackage[];
}

export interface HubsGamingPackage {
  id: number;
  nftId: number;
  packageId: number;
  name: string;
  price: number;
  rsp: number;
  totalUsdValue: number;
  usdValue: number;
  dateOfCreation: string;
  dateOfPurchase: string;
  isConvertable: boolean;
  dateConverted: string;
  loyaltyPoints: number;
  potentialLoyaltyPoints: number;
}

export interface HubsGamingSummary {
  totalRsp: number;
  totalUsdAmount: number;
  totalOrderAmount: number;
  availableUsdtToClaim: number;
  availableUsdtToWithdraw: number;
}

export interface HubsGamingInfo {
  summary: HubsGamingSummary;
  userPackages: HubsGamingPackage[];
}

export interface HubsPaymentPackage {
  id: number;
  nftId: number;
  packageId: number;
  name: string;
  price: number;
  rsp: number;
  totalUsdValue: number;
  usdValue: number;
  dateOfCreation: string;
  dateOfPurchase: string;
  isConvertable: boolean;
  dateConverted: string;
  loyaltyPoints: number;
  potentialLoyaltyPoints: number;
}

export interface HubsPaymentSummary {
  totalRsp: number;
  totalOrderAmount: number;
  totalUsdAmount: number;
}

export interface HubsPaymentInfo {
  summary: HubsPaymentSummary;
  userPackages: HubsPaymentPackage[];
}

export interface HubsBigWhalePackage {
  id: number;
  packageId: number;
  name: string;
  image: string;
  price: number;
  dateOfCreation: string;
  dateOfPurchase: string;
  isConvertable: boolean;
  dateConverted: string;
  loyaltyPoints: number;
  potentialLoyaltyPoints: number;
}

export interface HubsBigWhaleSummary {
  totalOrderAmount: number;
  totalBigWhalePoints: number;
  memeCoinsFromBigWhale: number;
  memeCoinsFromBring3: number;
  totalMemeCoins: number;
  changePeriodInSeconds: number;
  totalCoinsPerChange: number;
  finupCoinsPerChange: number;
  fastStartCoinsPerChange: number;
}

export interface HubsBigWhaleInfo {
  summary: HubsBigWhaleSummary;
  userPackages: HubsBigWhalePackage[];
}
