import { Button, Card, CardBody } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { reset2Fa, setUserActivationStatus, updateUserType } from './actions';

import { Modal } from '../../../../Components/Custom/modal';
import { useEffect, useState } from 'react';
import { UserType } from '../types';

export const UserDetailsActions = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState(false);
  const { data } = useAppSelector((state) => state.userDetails);
  const [userType, setUserType] = useState(1);

  const toggleValue = () => {
    setUserType(
      userType === UserType.Regular ? UserType.Restricted : UserType.Regular
    );
    dispatch(
      updateUserType({
        userId,
        userType:
          userType === UserType.Regular ? UserType.Restricted : UserType.Regular
      })
    );
  };

  useEffect(() => {
    setUserType(data.userType);
  }, [data]);

  return (
    <Card>
      <CardBody>
        {/* <Button
          color='primary'
          className='w-100 mb-3'
          onClick={() =>
            dispatch(sendPasswordOrId({ action: 'password', userId }))
          }>
          Reset Password via Email
        </Button> */}
        {/* <Button
          color='primary'
          className='w-100 mb-3'
          onClick={() => dispatch(sendPasswordOrId({ action: 'id', userId }))}>
          Send ID via Email
        </Button> */}
        <div
          style={{
            border: '1px solid #CED4D9',
            borderRadius: 4,
            height: 37.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20
          }}>
          <div style={{ marginRight: 30 }}>Regular</div>
          <div className='flex mt-1'>
            <div className='form-check form-switch form-switch-right form-switch-md'>
              <input
                className='form-check-input code-switcher'
                type='checkbox'
                id='toggle-button'
                value={userType}
                checked={userType === UserType.Restricted}
                onChange={toggleValue}
              />
            </div>
          </div>
          <div style={{ marginLeft: 30 }}>Restricted</div>
        </div>

        {/* <Button
          color='primary'
          className='w-100 mb-3'
          onClick={() => dispatch(updateUserType({ userId, userType: 1 }))}>
          Regular
        </Button> */}

        <Button
          color='primary'
          className='w-100 mb-3'
          onClick={() => dispatch(reset2Fa(userId))}>
          2FA Reset
        </Button>

        <Button
          color={data.activationStatus === 1 ? 'danger' : 'success'}
          className='w-100'
          onClick={() => setModal(!modal)}>
          {data.activationStatus === 1 ? 'Deactivate user' : 'Activate user'}
        </Button>
      </CardBody>

      <Modal
        isOpen={modal}
        setIsOpen={setModal}
        title={
          data.activationStatus === 1 ? 'Deactivate user' : 'Activate user'
        }
        actions={
          <>
            <Button
              color='primary'
              outline
              onClick={() => setModal(!modal)}
              className='m-1'>
              Cancel
            </Button>
            <Button
              color={data.activationStatus === 1 ? 'danger' : 'success'}
              className='m-1'
              onClick={() => {
                setModal(!modal);
                dispatch(
                  setUserActivationStatus({
                    userId,
                    activationStatus: data.activationStatus === 1 ? 2 : 1
                  })
                );
              }}>
              Yes
            </Button>
          </>
        }>
        <div className='mb-3'>
          <div className='avatar-lg mx-auto'>
            <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
              <i className='ri-error-warning-fill '></i>
            </div>
          </div>
        </div>
        <h2>
          Are you sure you want to{' '}
          {data.activationStatus === 1 ? 'deactivate user' : 'activate user'}?
        </h2>
      </Modal>
    </Card>
  );
};

export default UserDetailsActions;
