import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { WithdrawalStatusEnum, Withdrawals } from './types';
import {
  handleValidDate,
  handleValidTime,
  formatUSDT
} from '../../../helpers/helpers';

export const getWithdrawals = createAsyncThunk(
  'withdrawals/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/mlm/user/balance/withdrawal', {
      params
    });
    return response.data.data;
  }
);

const chooseStatusType = (key: WithdrawalStatusEnum) => {
  switch (key) {
    case WithdrawalStatusEnum.CANCELED:
      return 'status/canceled';
    case WithdrawalStatusEnum.PAID:
      return 'status/paid';
    case WithdrawalStatusEnum.AUTOMATIC_PAYOUT_IN_PROGRESS:
      return 'payout/automatic';
  }
};

export const updateWithdrawalStatus = createAsyncThunk(
  'withdrawals/updateWithdrawalStatus',
  async ({ id, type }: { id: number; type: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/mlm/user/balance/withdrawal/${chooseStatusType(type)}`,
        {
          id
        }
      );
      toast.success(
        response.data.message || 'Successfully updated withdrawal status'
      );
      return response.data.data as Withdrawals;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const exportWithdrawals = createAsyncThunk(
  'withdrawals/exportWithdrawals',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(
      `/mlm/user/balance/withdrawal/export`,
      { params }
    );

    const dataForExport = response.data.data.withdrawals.map(
      (w: Withdrawals) => ({
        id: w.id,
        userId: w.user.id,
        user: `${w.user.name} ${w.user.surname}`,
        walletAddress: w.userWallet?.address || '',
        amount: `${w.paidAmount ? formatUSDT.format(w.paidAmount) : ''}`,
        dateCreated: w.dateCreated
          ? `${handleValidDate(w.dateCreated)}, ${handleValidTime(
              w.dateCreated
            )}`
          : '',
        datePaid: w.datePaid
          ? `${handleValidDate(w.datePaid)}, ${handleValidTime(w.datePaid)}`
          : '',
        status: w.status
      })
    );

    return dataForExport;
  }
);

export const exportHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User ID', key: 'userId' },
  { label: 'User', key: 'user' },
  { label: 'Wallet Address', key: 'walletAddress' },
  { label: 'Amount', key: 'amount' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Date Paid', key: 'datePaid' },
  { label: 'Status', key: 'status' }
];

export interface WithdrawalsState {
  data: Withdrawals[];
  loading: boolean;
  totalCount: number;
  dataForExport: Withdrawals[];
  loadingExport: boolean;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  dataForExport: [],
  loadingExport: false
} as WithdrawalsState;

const withdrawalsSlice = createSlice({
  name: 'withdrawals',
  initialState,
  reducers: {
    removeWithdrawalsForExport(state) {
      state.dataForExport = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWithdrawals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWithdrawals.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.withdrawals as Withdrawals[];
      state.totalCount = payload.count;
    });
    builder.addCase(getWithdrawals.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateWithdrawalStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateWithdrawalStatus.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index] = payload;
    });
    builder.addCase(updateWithdrawalStatus.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportWithdrawals.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(exportWithdrawals.fulfilled, (state, { payload }) => {
      state.loadingExport = false;
      state.dataForExport = payload;
    });
    builder.addCase(exportWithdrawals.rejected, (state) => {
      state.loadingExport = false;
    });
  }
});

export const { removeWithdrawalsForExport } = withdrawalsSlice.actions;

export default withdrawalsSlice.reducer;
