import { FC } from 'react';
import { DropdownItem } from 'reactstrap';
import { useAppDispatch } from '../../../../app/hooks';
import { resendPaymentInstructions } from '../slice';

export const ResendPaymentInstructions: FC<{
  id: number;
}> = ({ id }) => {
  const dispatch = useAppDispatch();
  return (
    <DropdownItem
      tag='a'
      href='/'
      className='w-100'
      onClick={(e) => {
        e.preventDefault();
      }}>
      <div
        style={{ width: '100%' }}
        onClick={() => dispatch(resendPaymentInstructions(id))}>
        Uncancel payment
      </div>
    </DropdownItem>
  );
};
