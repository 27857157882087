import { Dispatch, FC, SetStateAction } from 'react';
import { Button, DropdownItem } from 'reactstrap';
import { Modal } from '../../../../Components/Custom/modal';
import { useAppDispatch } from '../../../../app/hooks';
import { handleMultipleModalsWithOneState } from '../../../../helpers/helpers';
import { updatePackageStatus } from '../slice';
import { CalculationStatus, OpenedModal } from '../types';

export const MarkAsPaid: FC<{
  isOpen: number;
  setIsOpen: Dispatch<SetStateAction<number>>;
}> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      {MarkAsPaidActions.map((action, i) => {
        return (
          <DropdownItem
            key={i}
            tag='a'
            href='/'
            className='w-100'
            onClick={(e) => {
              e.preventDefault();
            }}>
            <div
              style={{ width: '100%' }}
              onClick={() => setIsOpen(action.modal)}>
              {action.label}
            </div>
          </DropdownItem>
        );
      })}
    </>
  );
};

const markAsPaidModals = [
  OpenedModal.MARK_AS_PAID,
  OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_NO_POPV,
  OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_NO_POPV,
  OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALC_WITH_POPV,
  OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALC_WITH_POPV,
  OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PO,
  OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PO,
  OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PV,
  OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PV,
  OpenedModal.LP_TRADING_ADD_PO
];

export const MarkAsPaidModal: FC<{
  id: number;
  setIsOpen: Dispatch<SetStateAction<number>>;
  isOpen: number;
}> = ({ id, isOpen, setIsOpen }) => {
  const action = ChosenAction(isOpen);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    setIsOpen(OpenedModal.CLOSED);
    dispatch(updatePackageStatus({ id, calculationStatus: action.value }));
  };

  return (
    <Modal
      isOpen={markAsPaidModals.includes(isOpen)}
      setIsOpen={() => handleMultipleModalsWithOneState(isOpen, setIsOpen)}
      title={action.label}
      actions={
        <>
          <Button
            color='primary'
            outline
            onClick={() => setIsOpen(OpenedModal.CLOSED)}
            className='m-1'>
            Cancel
          </Button>
          <Button color='primary' className='m-1' onClick={onSubmit}>
            Yes
          </Button>
        </>
      }>
      <div className='mb-3'>
        <div className='avatar-lg mx-auto'>
          <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
            <i className='ri-error-warning-fill '></i>
          </div>
        </div>
      </div>
      <h2>Are you sure?</h2>
      <p>You won’t be able to revert this!</p>
    </Modal>
  );
};

const MarkAsPaidActions = [
  {
    label: 'Mark as paid',
    value: CalculationStatus.CALCULATE,
    modal: OpenedModal.MARK_AS_PAID
  },
  {
    label: 'Mark as paid with HUB, no calculation no POPV',
    value: CalculationStatus.SKIP_WITH_HUB_CREATION,
    modal: OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_NO_POPV
  },
  {
    label: 'Mark as paid no HUB, no calculation no POPV',
    value: CalculationStatus.SKIP_WITHOUT_HUB_CREATION,
    modal: OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_NO_POPV
  },
  {
    label: 'Mark as paid with HUB, no calculation with POPV',
    value: CalculationStatus.SKIP_WITH_HUB_CREATION_ADD_PO_PV,
    modal: OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALC_WITH_POPV
  },
  {
    label: 'Mark as paid no HUB, no calculation with POPV',
    value: CalculationStatus.SKIP_WITHOUT_HUB_CREATION_ADD_PO_PV,
    modal: OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALC_WITH_POPV
  },

  {
    label: 'Mark as paid with HUB, no calculation with PO',
    value: CalculationStatus.SKIP_WITH_HUB_CREATION_ADD_PO,
    modal: OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PO
  },
  {
    label: 'Mark as paid no HUB, no calculation with PO',
    value: CalculationStatus.SKIP_WITHOUT_HUB_CREATION_ADD_PO,
    modal: OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PO
  },
  {
    label: 'Mark as paid with HUB, no calculation with PV',
    value: CalculationStatus.SKIP_WITH_HUB_CREATION_ADD_PV,
    modal: OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PV
  },
  {
    label: 'Mark as paid no HUB, no calculation with PV',
    value: CalculationStatus.SKIP_WITHOUT_HUB_CREATION_ADD_PV,
    modal: OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PV
  },

  {
    label: 'Mark as paid (LP), with HUB, no calculation no POPV`',
    value: CalculationStatus.LP_TRADING_ADD_PO,
    modal: OpenedModal.LP_TRADING_ADD_PO
  }
];

const ChosenAction = (openedModal: number) => {
  switch (openedModal) {
    case OpenedModal.MARK_AS_PAID:
      return MarkAsPaidActions[0];
    case OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_NO_POPV:
      return MarkAsPaidActions[1];
    case OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_NO_POPV:
      return MarkAsPaidActions[2];
    case OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALC_WITH_POPV:
      return MarkAsPaidActions[3];
    case OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALC_WITH_POPV:
      return MarkAsPaidActions[4];

    case OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PO:
      return MarkAsPaidActions[5];
    case OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PO:
      return MarkAsPaidActions[6];
    case OpenedModal.MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PV:
      return MarkAsPaidActions[7];
    case OpenedModal.MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PV:
      return MarkAsPaidActions[8];
    case OpenedModal.LP_TRADING_ADD_PO:
      return MarkAsPaidActions[9];

    default:
      return {
        label: '',
        value: 0
      };
  }
};
