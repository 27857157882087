import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';

import { Link } from 'react-router-dom';

import { NetworkType } from '../PackageOrders/types';
import { EventTicketsActions } from './actions';

export const columns = [
  {
    id: 1,
    Header: 'Order ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return (
        <Link to={`/users/${cell.value}`} state={{ tab: '3' }}>
          {cell.value}
        </Link>
      );
    }
  },

  {
    id: 3,
    Header: 'User',
    accessor: 'userName',
    Cell: (cell: any) => {
      return (
        <>
          {cell.row.original.userName} {cell.row.original.userSurname}
        </>
      );
    }
  },
  {
    id: 4,
    Header: 'Title',
    accessor: 'ticketTitle',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 5,
    Header: 'Event Name',
    accessor: 'eventName',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },

  {
    id: 6,
    Header: 'Price',
    accessor: 'price',
    Cell: (cell: any) => {
      return cell?.value ? usdFormatter.format(cell?.value) : '---';
    }
  },
  {
    id: 7,
    Header: 'Payment address',
    accessor: 'paymentAddress',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 8,
    Header: 'Network type',
    accessor: 'networkType',
    Cell: (cell: any) => {
      switch (cell.value) {
        case NetworkType.BEP_20:
          return <div>BEP-20</div>;

        case NetworkType.TRC_20:
          return <div>TRC-20</div>;

        case NetworkType.Ultron:
          return <div>Ultron</div>;

        default:
          return <div></div>;
      }
    }
  },
  {
    id: 9,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              PENDING
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              PAID
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              CANCELED
            </span>
          );

        case 4:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              CANCELED BY USER
            </span>
          );
      }
    }
  },
  {
    id: 10,
    Header: 'Order Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },

  {
    id: 11,
    Header: 'Payment Date',
    accessor: 'datePaid',
    Cell: (cell: any) => (
      <>
        {cell.value ? (
          <>
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        ) : (
          '---'
        )}
      </>
    )
  },
  {
    id: 12,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      if (cell.row.original.status === 1)
        return <EventTicketsActions {...cell} />;
    }
  }
];
