export const Arrows = () => {
  return (
    <>
      <div className='teams-user-circle mt-2'></div>
      <UserLine />
      <div className='teams-big-arrows'>
        <LeftArrow />
        <RightArrow />
      </div>
    </>
  );
};

const UserLine = () => {
  return <div className='teams-user-line'></div>;
};

const LeftArrow = () => {
  return (
    <i
      style={{ position: 'absolute', left: '-10px', bottom: '-20px' }}
      className='ri-arrow-down-s-line fs-20'></i>
  );
};

const RightArrow = () => {
  return (
    <i
      style={{ position: 'absolute', right: '-10px', bottom: '-20px' }}
      className='ri-arrow-down-s-line fs-20'></i>
  );
};
