import { useState } from 'react';
import {
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap';
import { EventTicketStatus } from '../types';
import { MarkAsPaid, MarkAsPaidModal } from './markAsPaid';

import { Storno, StornoModal } from './storno';

export const EventTicketsActions = (cell: any) => {
  const [isOpen, setIsOpen] = useState(0);

  const { id, status } = cell.row.original;

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          href='#'
          className='btn btn-soft-secondary btn-sm'
          tag='button'>
          <i className='ri-more-fill' />
        </DropdownToggle>

        <DropdownMenu className='dropdown-menu-end'>
          {status === EventTicketStatus.PENDING && (
            <MarkAsPaid isOpen={isOpen} setIsOpen={setIsOpen} />
          )}

          {status === EventTicketStatus.PENDING && (
            <Storno setIsOpen={setIsOpen} />
          )}
        </DropdownMenu>
      </UncontrolledDropdown>

      {status === EventTicketStatus.PENDING && (
        <MarkAsPaidModal id={id} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      {status === EventTicketStatus.PENDING && (
        <StornoModal id={id} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
};

export default EventTicketsActions;
