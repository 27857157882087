import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
  CardHeader
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { formatUSDT, setDocumentTitle } from '../../../helpers/helpers';
import { getFreeWillyCoins, updateWillyCoins } from './slice';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';
import classnames from 'classnames';
// import { Direct } from './direct';
// import { Structure } from './structure';
import '../../../assets/scss/customStyles/teams.scss';
import { Direct } from './direct';
// import TradingStructure from './tradingStructure';

const PAGE_TITLE = 'Free Willy Coins';

const FreeWillyCoinsPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState('');

  const { loading, willyCoins } = useAppSelector(
    (state) => state.freeWillyCoins
  );

  const userId = searchParams.get('id');

  useEffect(() => {
    if (userId) {
      dispatch(getFreeWillyCoins(userId));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!loading) {
      const interval = setInterval(() => {
        dispatch(updateWillyCoins(willyCoins));
      }, willyCoins.changePeriodInSeconds * 1000);

      return () => clearInterval(interval);
    }
  }, [willyCoins, loading]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader loading={loading} title={PAGE_TITLE} />
              <CardHeader>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSearchParams({ id });
                  }}>
                  <Row>
                    <Col md={3} sm={4}>
                      <SearchInputFilter
                        onChange={(e: any) => setId(e.target.value)}
                        value={id}
                        placeholder='Search User ID'
                      />
                    </Col>
                    <Col md={3} sm={4}>
                      <SearchSubmitButton />
                    </Col>
                  </Row>
                </form>
              </CardHeader>
              {willyCoins?.user?.id !== 0 && (
                <CardBody>
                  <h3 className='mt-5'>
                    WILLY MEME COINS: {formatUSDT.format(willyCoins.totalCoins)}{' '}
                    Willy
                  </h3>
                  <h4 className='mt-5'>
                    RECEIVING PER DAY: {willyCoins.coinsPerDay} Willy
                  </h4>
                  <h4 className='mt-4 mb-5'>
                    DAYS LEFT: {willyCoins.daysLeft}
                  </h4>

                  <Direct />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FreeWillyCoinsPage;
