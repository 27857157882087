import { handleValidDate, handleValidTime } from '../../../helpers/helpers';
import { DeleteUser } from './delete';

export const columns = [
  {
    id: 1,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'Remark',
    accessor: 'remark',
    Cell: (cell: any) => {
      return cell.value;
    }
  },
  {
    id: 3,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },
  {
    id: 4,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      return <DeleteUser userId={cell.row.original.userId} />;
    }
  }
];
