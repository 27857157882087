import { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch } from '../../app/hooks';

export const CsvExport = ({
  action,
  removeDataAction,
  data,
  disabled,
  fileName,
  headers
}: any) => {
  const dispatch = useAppDispatch();
  const csvInstance = useRef<any>(null);

  useEffect(() => {
    if (data && data.length) {
      setTimeout(() => {
        csvInstance?.current?.link?.click();
        dispatch(removeDataAction());
      });
    }
  }, [data]);

  return (
    <>
      <button
        disabled={disabled}
        type='button'
        className='btn btn-primary'
        onClick={action}>
        <i className='ri-filter-off-fill align-bottom me-1'></i> Export data to
        CSV
      </button>
      {data ? (
        <CSVLink
          data={data}
          headers={headers}
          filename={fileName}
          ref={csvInstance}
        />
      ) : undefined}
    </>
  );
};
