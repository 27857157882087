import { color } from '../../../../../../styles/GlobalStyles';
import { Icon, IconArrowPolygon } from '../../../../../../styles/Icons';
import { BigArrows, UserCircle, UserLine } from './DotLineArrows.styles';

export const DotLineArrows = ({ disabled }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: disabled ? '0.3' : ''
      }}>
      <UserCircle />
      <UserLine />
      <BigArrows>
        <Icon
          color={color.colors.neutral900}
          style={{ position: 'absolute', left: '-13px', bottom: '-14px' }}>
          <IconArrowPolygon />
        </Icon>

        <Icon
          color={color.colors.neutral900}
          style={{
            position: 'absolute',
            right: '-13px',
            bottom: '-14px'
          }}>
          <IconArrowPolygon />
        </Icon>
      </BigArrows>
    </div>
  );
};
