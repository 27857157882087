import { Accordion, AccordionItem, Collapse, Row } from 'reactstrap';

import classnames from 'classnames';
import Countdown from 'react-countdown';
import {
  formatUSD,
  formatUSDNoDecimals
} from '../../../../../../helpers/helpers';
import { StakingHub } from '../StakingHub';
import { Tile } from '../Tile';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { getUserHubsStakingInfo } from '../../../slice';

export const Staking = () => {
  const { staking } = useAppSelector((state) => state.userDetails);

  const [col, setCol] = useState(true);

  const t_col = () => {
    setCol(!col);
  };

  const dispatch = useAppDispatch();

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    if (staking !== null) return;
    dispatch(getUserHubsStakingInfo(Number(userId)));
  }, []);

  let now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(13);
  now.setSeconds(0);
  now.setMinutes(0);
  now.setMilliseconds(0);

  const renderer = ({
    hours,
    minutes,
    seconds
  }: {
    hours: any;
    minutes: any;
    seconds: any;
  }) => {
    return (
      <div>
        {hours}h: {minutes}m: {seconds}s
      </div>
    );
  };

  return (
    <div>
      {' '}
      <h5 className='mt-5'>My Ultron Staking HUB NFTs summary</h5>
      <Row>
        <Tile label='BASE STAKE'>
          {formatUSD(staking?.summary?.baseStake)}
          <span className='text-muted fs-14'> wULX</span>
        </Tile>
        <Tile label='TOTAL STAKED'>
          {formatUSD(staking?.summary?.totalStake)}
          <span className='text-muted fs-14'> wULX</span>
        </Tile>
        {/* <Tile label='APR/APY' value={<></>} /> */}
        <Tile label='TOTAL ULTRON STAKING HUB NFT ORDERS'>
          {formatUSDNoDecimals(staking?.summary?.totalOrderAmount)}
          <span className='text-muted fs-14'> USDT</span>
        </Tile>
        <Tile label='TOTAL ORDERS WITH AUTOSTAKE ON'>
          {formatUSDNoDecimals(
            staking?.summary?.totalOrderWithAutostakeIntactAmount
          )}
          <span className='text-muted fs-14'> USDT</span>
        </Tile>
        <Tile label='NEXT'>
          <Countdown date={now} renderer={renderer} />
        </Tile>
      </Row>
      <Accordion open={'1'} id='default-accordion-example'>
        <AccordionItem>
          <h2 className='accordion-header' id='headingOne'>
            <button
              className={classnames('accordion-button', { collapsed: !col })}
              type='button'
              onClick={t_col}
              style={{ cursor: 'pointer' }}>
              Staking HUB NFTs
            </button>
          </h2>
          <Collapse
            isOpen={col}
            className='accordion-collapse'
            id='collapseOne'>
            <div className='accordion-body'>
              {staking?.userPackages?.map((data, i) => (
                <StakingHub key={i} data={data} />
              ))}
            </div>
          </Collapse>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
