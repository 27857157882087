import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getTeamTradingById } from './slice';
import { Tree } from './traidingTree/tree';

export const TradingStructure = () => {
  const dispatch = useAppDispatch();
  const { trading } = useAppSelector((state) => state.teams);

  const goLeftDown = () => {
    const id = trading.leftChildUser.id;
    if (id) dispatch(getTeamTradingById(id.toString()));
  };

  const goRightDown = () => {
    const id = trading?.rightChildUser?.id;
    if (id) dispatch(getTeamTradingById(id.toString()));
  };

  const goUp = () => {
    if (trading?.parentId !== null) {
      dispatch(getTeamTradingById(trading?.parentId.toString()));
    }
  };

  return (
    <>
      {trading && <Tree rootTeamMember={trading} />}
      <div className='d-flex justify-content-around mt-4'>
        <div className='d-flex flex-column gap-2'>
          <div>
            <Button onClick={goUp} color='primary'>
              <i className='ri-arrow-up-s-line fs-24'></i>
            </Button>
          </div>
          <div>
            <Button onClick={goLeftDown} color='primary'>
              <i className='ri-arrow-down-s-line fs-24'></i>
            </Button>
          </div>
        </div>
        <div className='d-flex flex-column gap-2'>
          <div>
            <Button onClick={goUp} color='primary'>
              <i className='ri-arrow-up-s-line fs-24'></i>
            </Button>
          </div>
          <div>
            <Button onClick={goRightDown} color='primary'>
              <i className='ri-arrow-down-s-line fs-24'></i>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingStructure;
