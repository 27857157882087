import { ProfilePicture } from '../../../../../styles/stylesStyled';
import { useTranslation } from 'react-i18next';
import { UV, formatVP } from '../../../../../helpers/helpers';
import { CaptionText, H3, H4, P3 } from '../../../../../styles/FontStyles';
import {
  Flexed,
  SectionSeparatorVertical,
  UserImageCircle,
  StructurePlusBox
} from '../../../../../styles/GlobalComponents';
import { color } from '../../../../../styles/GlobalStyles';
import { Icon, IconStructurePlus } from '../../../../../styles/Icons';

export const UserProfileBootcamp = ({ user }) => {
  const { t } = useTranslation('myTeam');

  return (
    <Flexed center column>
      <ProfilePicture
        size={'100px'}
        style={{ marginBottom: 12, marginRight: 0 }}>
        <UserImageCircle
          image={user?.profileImg}
          avatar
          avatarSize='40px'
          size='100px'
        />
      </ProfilePicture>

      <CaptionText mb='4' center color={color.colors.neutral900}>
        YOUR USER ID: {user?.userId}
      </CaptionText>

      <H3
        center
        mb='4'
        color={color.colors.neutral900}
        style={{ maxWidth: '100%' }}>
        <div className='truncated'>
          <div>{user?.username}</div>
        </div>
      </H3>

      <CaptionText center color={color.colors.neutral900}>
        Unilevel:{' '}
        <b>
          {formatVP(user?.uv || 0)} {UV}
        </b>
      </CaptionText>
    </Flexed>
  );
};

export const UserProfileBootcampSmall = ({ user }) => {
  if (!user) {
    return (
      <StructurePlusBox>
        <Icon color={color.colors.neutral50}>
          <IconStructurePlus />
        </Icon>
      </StructurePlusBox>
    );
  }

  return (
    <Flexed center column>
      <ProfilePicture
        size={'40px'}
        style={{ marginBottom: 12, marginRight: 0 }}>
        <UserImageCircle
          image={user?.profileImg}
          avatar
          avatarSize='20px'
          size='40px'
        />
      </ProfilePicture>

      <CaptionText center color={color.colors.neutral900}>
        id: {user?.userId}
      </CaptionText>

      <H4
        center
        mb='10'
        color={color.colors.neutral900}
        style={{ maxWidth: '100%' }}>
        <div className='truncated'>
          <div>{user?.username}</div>
        </div>
      </H4>

      <Flexed>
        <div>
          <CaptionText right color={color.colors.primary900}>
            Current Rank
          </CaptionText>
          <P3 right color={color.colors.neutral900}>
            {user?.currentRank}
          </P3>
        </div>
        <SectionSeparatorVertical margin='0 12px' />
        <div>
          <CaptionText color={color.colors.primary900}>USER ID</CaptionText>
          <P3 color={color.colors.neutral900}>{user?.userId}</P3>
        </div>
      </Flexed>
    </Flexed>
  );
};
