import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Modal } from '../../../Components/Custom/modal';
import { useAppDispatch } from '../../../app/hooks';
import { addBalance } from './slice';

export const AddBalance = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [id, setId] = useState<number | undefined>();
  const [name, setName] = useState('');
  const [rewardPoints, setRewardPoints] = useState<string>('');
  const [shoppingPoints, setShoppingPoints] = useState<string>('');
  const [remark, setRemark] = useState<string>('');

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data = {
      userId: id,
      name,
      rewardPoints: parseFloat(rewardPoints),
      shoppingPoints: parseFloat(shoppingPoints),
      remark
    };

    dispatch(addBalance(data));
    handleClearState();
  };

  const handleClearState = () => {
    setIsOpen(!isOpen);

    setName('');
    setId(undefined);
    setRewardPoints('');
    setShoppingPoints('');
    setRemark('');
  };

  return (
    <>
      <Button color='primary' onClick={() => setIsOpen(!isOpen)}>
        Add Balance Change
      </Button>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        title='Add Balance Change '
        size='xl'
        form
        onFormSubmit={handleSubmit}
        actions={
          <>
            <Button color='primary' onClick={handleClearState}>
              Cancel
            </Button>
            <Button
              color='primary'
              type='submit'
              disabled={!id || !name || !rewardPoints || !remark}>
              Add
            </Button>
          </>
        }>
        <Row>
          <Col lg={3}>
            <Label className='w-100 text-left' for='userId'>
              User ID
            </Label>
            <Input
              id='userId'
              className='w-100 mb-2'
              type='number'
              value={id}
              onChange={(e) => setId(parseInt(e.target.value))}
              placeholder='Enter here'
            />
          </Col>
          <Col lg={3}>
            <Label for='transaction'>Transaction name </Label>
            <Input
              id='transaction'
              className='w-100 mb-2'
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
          <Col lg={3}>
            <Label className='w-100 text-left' for='amount'>
              Reward Points
            </Label>
            <Input
              id='amount'
              className='w-100 mb-2'
              type='number'
              name='number'
              step='any'
              value={rewardPoints}
              onChange={(e) => setRewardPoints(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
          <Col lg={3}>
            <Label className='w-100 text-left' for='shopping'>
              Shopping Points
            </Label>
            <Input
              id='shopping'
              className='w-100 mb-2'
              type='number'
              name='number'
              step='any'
              value={shoppingPoints}
              onChange={(e) => setShoppingPoints(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
          <Col lg={12}>
            <Label for='title'>Remark</Label>
            <Input
              id='title'
              className='w-100 mb-2'
              type='textarea'
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
