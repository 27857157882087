import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';
import { columns } from './columns';
import { Filters } from './filters';

import { getBalanceChange } from './slice';

import { PageHeader } from '../../../Components/Custom/PageHeader';
import { AddBalance } from './addBalance';

const PAGE_TITLE = 'Balance Change';

const INITIAL_PARAMS: {
  sortBy: string;
  sortOrder: string;
  countPerPage: string;
  numberOfPage: string;
} = {
  sortBy: 'dateCreated',
  sortOrder: 'DESC',
  countPerPage: '10',
  numberOfPage: '1'
};

const BalanceChangePage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);
  const { loading, data, totalCount } = useAppSelector(
    (state) => state.balanceChange
  );

  useEffect(() => {
    dispatch(getBalanceChange(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
                title={PAGE_TITLE}
                actions={
                  <div>
                    <AddBalance />
                  </div>
                }
              />

              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />

                <TableContainer
                  columns={columns}
                  data={data || []}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BalanceChangePage;
