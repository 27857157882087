import { DropdownToggle } from 'reactstrap';
import { handleValidDate, handleValidTime } from '../../../helpers/helpers';
import { useAppDispatch } from '../../../app/hooks';
import { getSuperAdminById } from './slice';
// import  { SuperAdminsActions } from './actions';

export const columns = [
  {
    id: 1,
    Header: 'User ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },

  {
    id: 2,
    Header: 'User',
    accessor: 'user',
    Cell: (cell: any) => {
      return cell?.row?.original?.name + ' ' + cell?.row?.original?.surname;
    }
  },
  {
    id: 4,
    Header: 'Email',
    accessor: 'email',
    Cell: (cell: any) => {
      return cell.value;
    }
  },

  {
    id: 5,
    Header: 'Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell?.value)},
        <small className='text-muted'> {handleValidTime(cell?.value)}</small>
      </>
    )
  },

  {
    id: 6,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      const dispatch = useAppDispatch();
      return (
        <>
          <DropdownToggle
            href='#'
            className='btn btn-soft-secondary btn-sm'
            tag='button'
            onClick={() => dispatch(getSuperAdminById(cell.value))}>
            <i className='ri-more-fill' />
          </DropdownToggle>
        </>
      );
    }
  }
];
