import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { TradingTeamBonusState, TradingTeamBonusType } from './types';

export const getTradingTeamBonus = createAsyncThunk(
  'getTeamBonus/fetch',
  async (id: number | null) => {
    const response = await axiosInstance.get(`/bonus/trading/${id}`);
    // if (response.data.data.userPackage === null) {
    //   toast.error('Please type valid Order ID');
    // }
    return response.data.data as TradingTeamBonusType;
  }
);

const initialState = {
  data: {},
  loading: false
} as TradingTeamBonusState;

const getTeamBonusSlice = createSlice({
  name: 'getTradingTeamBonus',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getTradingTeamBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTradingTeamBonus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getTradingTeamBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default getTeamBonusSlice.reducer;
