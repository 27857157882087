import { Button } from 'reactstrap';
import { useAppDispatch } from '../../../app/hooks';
import { handleValidDate, handleValidTime } from '../../../helpers/helpers';
import {
  deleteSubCategory,
  setCategoryForEdit,
  setCategoryIdForModal
} from '../slice';

export const columns = [
  {
    id: 1,
    Header: 'Subcategory',
    accessor: 'name',
    Cell: (cell: any) => {
      const dispatch = useAppDispatch();

      return (
        <div
          onClick={() => {
            dispatch(setCategoryIdForModal(cell.row.original.id));
            dispatch(setCategoryForEdit(cell.row.original));
          }}>
          {cell.value}
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell?.value)},
        <small className='text-muted'> {handleValidTime(cell?.value)}</small>
      </>
    )
  },
  {
    id: 3,
    Header: 'Date Updated',
    accessor: 'dateUpdated',
    Cell: (cell: any) => (
      <>
        {cell?.value ? (
          <>
            {handleValidDate(cell?.value)},
            <small className='text-muted'>
              {' '}
              {handleValidTime(cell?.value)}
            </small>
          </>
        ) : (
          '---'
        )}
      </>
    )
  },
  {
    id: 4,
    Header: 'Action',
    accessor: 'id',
    Cell: (cell: any) => {
      const dispatch = useAppDispatch();
      return (
        <>
          <Button
            size='sm'
            color='danger'
            className='btn btn-soft-danger btn-sm'
            onClick={() => dispatch(deleteSubCategory({ id: cell.value }))}>
            <i className='ri-delete-bin-line fs-14'></i>
          </Button>
        </>
      );
    }
  }
];
