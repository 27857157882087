import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import TableContainer from '../../../Components/Common/TableContainer';
import { columns } from './columns';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AddEditCategoryModal } from './edit';
import { setCategoryIdForModal } from '../slice';

export const SubCategories = () => {
  const { subCategories } = useAppSelector((state) => state.faq);
  const dispatch = useAppDispatch();

  return (
    <>
      <Card>
        <CardHeader className='border-0'>
          <Row className='align-items-center gy-3'>
            <div className='col-sm d-flex gap-3 flex-wrap align-items-center'>
              <h5 className='card-title mb-0'>Sub Categories</h5>
            </div>
            <div className='col-sm-auto'>
              <div className='d-flex gap-3 flex-wrap align-items-center'>
                <Button
                  color='primary'
                  onClick={() => dispatch(setCategoryIdForModal('add'))}>
                  Add Sub Categories
                </Button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody className='pt-0'>
          <TableContainer
            columns={columns}
            data={subCategories || []}
            divClass='table-responsive table-card mb-1'
            tableClass='align-middle table-nowrap'
            theadClass='table-light text-muted'
          />
        </CardBody>
      </Card>
      <AddEditCategoryModal />
    </>
  );
};
