import Countdown from 'react-countdown';

export const Counter = ({ timestamp }: { timestamp: number }) => {
  if (timestamp) {
    return (
      <Countdown
        date={timestamp}
        renderer={({ days, hours, minutes, seconds }) => (
          <div className='d-flex align-items-center text-center'>
            <div className='p-3'>
              <h2>{days}</h2>
              <p>DAYS</p>
            </div>
            <div className='p-3'>
              <h2>{hours}</h2>
              <p>HOURS</p>
            </div>
            <div className='p-3'>
              <h2>{minutes}</h2>
              <p>MINUTES</p>
            </div>
            <div className='p-3'>
              <h2>{seconds}</h2>
              <p>SECONDS</p>
            </div>
          </div>
        )}
      />
    );
  } else return null;
};
