import { formatUSDT } from '../../../helpers/helpers';

export const TeamMember = ({ id, user }: { id: number; user?: any }) => {
  return (
    <div
      className={
        'border d-flex flex-column align-items-center text-center p-4'
      }>
      <div className='profile-user position-relative d-inline-block mx-auto'>
        <h5 className='mb-2'>ID: {id || ''}</h5>

        <h4 className='mb-2'>{user?.username}</h4>

        <h5 className='mb-1'>
          {user?.packageName} ({user?.packagePrice} USDT)
        </h5>
        <p className='mb-1'>IS VALID: {user?.isValid ? 'TRUE' : 'FALSE'}</p>
      </div>
    </div>
  );
};
