import { useEffect, useRef, useState } from 'react';
import { Button, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { Modal } from '../../../Components/Custom/modal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  addCategory,
  editCategory,
  removeCategoryForEdit,
  removeCategoryIdForModal2
} from '../slice';
import { toast } from 'react-toastify';

export const AddEditCategoryModal = () => {
  const dispatch = useAppDispatch();

  const { loading, categories, categoryForEdit, categoryIdForModal2 } =
    useAppSelector((state) => state.faq);

  const [categoryId, setCategoryId] = useState(null);
  const [title, setTitle] = useState('');
  const [img1, setImg1] = useState<string | null>(null);
  const [image1, setImage1] = useState<string | null>(null);
  const hiddenFileInput1 = useRef<HTMLInputElement>(null);

  const handleChange1 = (event: any) => {
    const fileUploaded1 = event.target.files[0];
    setImage1(URL.createObjectURL(fileUploaded1));
    setImg1(fileUploaded1);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!title) {
      toast.error('Please insert title');
      return;
    }
    // if (!image1) {
    //   toast.error('Please insert thumbnail image');
    //   return;
    // }

    const dataEdit = {
      id: categoryForEdit?.id,
      parentId: null,
      name: title
    };

    const image = new FormData();
    if (img1) {
      image.append('image', img1);
    }

    const { id, ...dataAdd } = dataEdit;

    if (typeof categoryIdForModal2 === 'string') {
      if (img1) {
        dispatch(addCategory({ data: dataEdit, image }));
      } else {
        dispatch(addCategory({ data: dataEdit }));
      }
    } else {
      if (img1) {
        dispatch(editCategory({ data: dataEdit, image }));
      } else {
        dispatch(editCategory({ data: dataEdit }));
      }
    }

    handleClearState();
  };

  const handleClearState = () => {
    setTitle('');
    setImg1(null);
    setImage1(null);
    dispatch(removeCategoryForEdit());
    dispatch(removeCategoryIdForModal2());
  };

  useEffect(() => {
    if (categoryForEdit?.id && categories) {
      setTitle(categoryForEdit?.name);
      setImage1(categoryForEdit.thumbnailUrl);
    }
  }, [categoryForEdit, categoryIdForModal2]);

  const handleEditClick = () => {
    hiddenFileInput1?.current?.click();
  };

  return (
    <Modal
      isOpen={categoryIdForModal2 ? true : false}
      setIsOpen={() => {
        dispatch(removeCategoryForEdit());
        dispatch(removeCategoryIdForModal2());
        handleClearState();
      }}
      title='Category'
      form
      onFormSubmit={handleSubmit}
      actions={
        <>
          <Button color='primary' onClick={handleClearState}>
            Cancel
          </Button>
          <Button color='primary' type='submit'>
            {categoryIdForModal2 === 'add' ? 'Add' : 'Edit'}
          </Button>
        </>
      }>
      {!loading ? (
        <Row>
          <Col lg={12}>
            <Label className='w-100 text-left' for='title'>
              Thumbnail image
            </Label>
            <div className='addThumb imageDeleteWrap' onClick={handleEditClick}>
              {image1 ? (
                <>
                  <img src={image1} alt='thumbnail' className='w-100 mb-3' />
                </>
              ) : (
                <div
                  className='border border-2 border-primary border-dashed mb-3 d-flex align-items-center justify-content-center cursor-pointer'
                  style={{ height: '200px' }}>
                  Add Thumb Image
                </div>
              )}
              <input
                type='file'
                accept='image/*'
                style={{ display: 'none' }}
                onChange={handleChange1}
                ref={hiddenFileInput1}
              />
            </div>
          </Col>
          <Col lg={12}>
            <Label for='title'>Title</Label>
            <Input
              id='title'
              className='w-100 mb-2'
              type='text'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
        </Row>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};
