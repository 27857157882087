import { useEffect, useMemo } from 'react';
import { handleFilterChange } from '../../helpers/helpers';
import { debounce } from 'lodash';

export const SearchInputFilter = ({
  searchParams,
  setSearchParams,
  param,
  placeholder,
  onChange,
  value
}: {
  searchParams?: URLSearchParams;
  setSearchParams?: any;
  param?: string;
  placeholder?: string;
  onChange?: any;
  value?: string;
}) => {
  const handleChange = async (e: any) => {
    const inputValue = e.target.value;

    if (param && setSearchParams) {
      handleFilterChange(param, inputValue, setSearchParams);
    }
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <div className={'search-box me-2 mb-2 d-inline-block col-12'}>
      <input
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else if (param && setSearchParams) {
            debouncedResults(e);
          }
        }}
        // value={
        //   value || (searchParams && param && searchParams.get(param)) || ''
        // }
        type='text'
        className='form-control search /'
        placeholder={placeholder || 'Search'}
      />
      <i className='bx bx-search-alt search-icon'></i>
    </div>
  );
};
