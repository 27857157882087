import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { TeamBonusState, TeamBonusType } from './types';
import { toast } from 'react-toastify';

export const getTeamBonus = createAsyncThunk(
  'getTeamBonus/fetch',
  async (id: number | null) => {
    const response = await axiosInstance.get(`/bonus/team/${id}`);
    if (response.data.data.userPackage === null) {
      toast.error('Please type valid Order ID');
    }
    return response.data.data as TeamBonusType;
  }
);

const initialState = {
  data: {},
  loading: false
} as TeamBonusState;

const getTeamBonusSlice = createSlice({
  name: 'getTeamBonus',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getTeamBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamBonus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getTeamBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default getTeamBonusSlice.reducer;
