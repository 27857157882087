import { useAppSelector } from '../../../app/hooks';
import { TeamMember } from './teamMember';

export const Direct = () => {
  const { willyCoins } = useAppSelector((state) => state.freeWillyCoins);

  return (
    <div>
      {willyCoins.user && (
        <>
          <div className='d-flex flex-column align-items-center '>
            <TeamMember id={willyCoins?.user?.id} user={willyCoins?.user} />
          </div>
          <div className='d-flex mt-5 mb-5 justify-content-lg-between'>
            {willyCoins?.sales?.map((team, i) => (
              <div key={i}>
                <TeamMember id={team.id} user={team} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
