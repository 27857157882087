import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import { RenderSorting } from '../../../Components/Custom/RenderSorting';
import countries from '../../../common/countries';
import {
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SELECT
} from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
}

export const Filters: FC<FiltersProps> = ({ params, setParams }) => {
  const kycVerificationStatusOptions = [
    { label: 'Not entered', value: '1' },
    { label: 'Not verified', value: '2' },
    { label: 'Verified', value: '3' }
  ];

  const packageRankTypeOptions = [
    { label: 'Silver', value: '1' },
    { label: 'Gold', value: '2' },
    { label: 'Pearl', value: '3' },
    { label: 'Sapphire', value: '4' },
    { label: 'Ruby', value: '5' },
    { label: 'Emerald', value: '6' },
    { label: 'Diamond', value: '7' },
    { label: 'Blue Diamond', value: '8' },
    { label: 'Green Diamond', value: '9' },
    { label: 'Black Diamond', value: '10' },
    { label: 'Crown Diamond', value: '11' },
    { label: 'Double Crown Diamond', value: '12' },
    { label: 'Ambassador', value: '13' },
    { label: 'Royal Ambassador', value: '14' },
    { label: 'G.O.A.T', value: '15' },
    { label: 'Customer', value: '16' },
    { label: 'Partner', value: '17' },
    { label: 'Qualified partner', value: '18' }
  ];

  const sortOrderOptions = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' }
  ];

  const sortByOptions = [
    { label: 'User ID', value: 'id' },
    { label: 'User Name', value: 'name' },
    { label: 'User email', value: 'email' },
    { label: 'User Phone Number', value: 'phoneNumber' },
    { label: 'Registration Status', value: 'registrationStatus' },
    { label: 'Date Created', value: 'dateCreated' },
    { label: 'Package Rank', value: 'packageRank' }
  ];

  const sortingInputs = [
    { param: 'sortBy', type: INPUT_TYPE_SELECT, options: sortByOptions },
    { param: 'sortOrder', type: INPUT_TYPE_SELECT, options: sortOrderOptions }
  ];

  const filterInputs = [
    { param: 'id', type: INPUT_TYPE_SEARCH, placeholder: 'Search User ID' },
    {
      param: 'username',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Username'
    },
    {
      param: 'name',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search First name'
    },
    {
      param: 'surname',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Last name'
    },
    {
      param: 'email',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Email'
    },
    {
      param: 'referrerId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Referrer ID'
    },

    {
      param: 'packageRankType',
      type: INPUT_TYPE_SELECT,
      options: packageRankTypeOptions,
      placeholder: 'Rank'
    },
    {
      param: 'country',
      type: INPUT_TYPE_SELECT,
      options: countries,
      placeholder: 'Country'
    },
    {
      param: 'kycVerificationStatus',
      type: INPUT_TYPE_SELECT,
      options: kycVerificationStatusOptions,
      placeholder: 'Kyc Verification Status'
    },
    { param: '', type: INPUT_TYPE_DATE_RANGE }
  ];

  return (
    <>
      <RenderSorting
        sortingInputs={sortingInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
    </>
  );
};
