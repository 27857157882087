import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { ExtraordinaryBonuses, BalanceAddType } from './types';

export const getBalanceChange = createAsyncThunk(
  'balanceChange/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/bonus/extraordinary', {
      params
    });

    return response.data.data;
  }
);

export const addBalance = createAsyncThunk(
  'balanceChange/add',
  async (data: BalanceAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/mlm/user/earnings/extraordinary-bonus',
        data
      );
      toast.success('Balance successfully added');

      return response.data.data.bonus as ExtraordinaryBonuses;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export interface BalanceChangeState {
  data: ExtraordinaryBonuses[];
  loading: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as BalanceChangeState;

const balanceChangeSlice = createSlice({
  name: 'balanceChange',
  initialState,
  reducers: {
    removePackageOrdersForExport(state) {
      // state.dataForExport = [];
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getBalanceChange.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBalanceChange.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.extraordinaryBonuses as ExtraordinaryBonuses[];
      state.totalCount = payload.count;
    });
    builder.addCase(getBalanceChange.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addBalance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBalance.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.unshift(payload);
    });
    builder.addCase(addBalance.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { removePackageOrdersForExport } = balanceChangeSlice.actions;

export default balanceChangeSlice.reducer;
