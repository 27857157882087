import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { Modal } from '../../../Components/Custom/modal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  editContentBlock,
  getContentBlockById,
  removeContentBlockForEdit
} from './slice';

export const EditContentBlock = ({ id }: { id: number }) => {
  const dispatch = useAppDispatch();

  const { loading, contentBlockForEdit } = useAppSelector(
    (state) => state.contentBlocks
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [contentBlockId, setContentBlockId] = useState('');
  const [title, setTitle] = useState('');
  const [contentBlock, setContentBlock] = useState<any>('');

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data = {
      id: contentBlockForEdit?.id,
      contentBlockId: parseInt(contentBlockId),
      title,
      content: contentBlock,
      languageId: 1
    };

    dispatch(editContentBlock(data));
    handleClearState();
  };

  const [editor, setEditor] = useState(EditorState.createEmpty());

  const handleClearState = () => {
    setIsOpen(!isOpen);
    setEditor(EditorState.createEmpty());
    setContentBlock('');
    setTitle('');
    setContentBlockId('');
  };

  const handleEditorChange = (data: any) => {
    setEditor(data);
    const dataForDatabase = draftToHtml(convertToRaw(data.getCurrentContent()));
    setContentBlock(dataForDatabase);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getContentBlockById({ id }));
    } else {
      removeContentBlockForEdit();
    }
  }, [isOpen]);

  useEffect(() => {
    if (contentBlockForEdit) {
      setContentBlockId(contentBlockForEdit?.contentBlockId?.toString());
      setTitle(contentBlockForEdit?.title);
      setContentBlock(contentBlockForEdit?.content);

      const blocksFromHTML: any = convertFromHTML(contentBlockForEdit?.content);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      setEditor(EditorState.createWithContent(content));
    }
  }, [contentBlockForEdit]);

  useEffect(() => {
    if (contentBlock) {
      const blocksFromHTML: any = convertFromHTML(contentBlock);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      setEditor(EditorState.createWithContent(content));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <p color='primary' className='m-0' onClick={() => setIsOpen(!isOpen)}>
        {id}
      </p>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        title='Edit Content Block'
        size='xl'
        form
        onFormSubmit={handleSubmit}
        actions={
          <>
            <Button color='primary' onClick={handleClearState}>
              Cancel
            </Button>
            <Button color='primary' type='submit'>
              Edit
            </Button>
          </>
        }>
        {!loading && contentBlockForEdit?.id ? (
          <Row>
            <Col lg={6}>
              <Label for='title'>Block Title</Label>
              <Input
                id='title'
                className='w-100 mb-2'
                type='text'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder='Enter here'
              />
            </Col>
            <Col lg={6}>
              <Label className='w-100 text-left' for='title'>
                ID
              </Label>
              <Input
                id='title'
                className='w-100 mb-2'
                type='number'
                value={contentBlockId}
                onChange={(e) => setContentBlockId(e.target.value)}
                placeholder='Enter here'
              />
            </Col>
            <Col lg={12}>
              <Label for='title'>Content</Label>
              <Editor
                editorState={editor}
                onEditorStateChange={handleEditorChange}
                // toolbarClassName='toolbar'
                // wrapperClassName='wrapper'
                // editorClassName='editor'
                wrapperStyle={{ background: '#fff', color: '#000' }}
                editorStyle={{ minHeight: 300, padding: 20 }}
                toolbarStyle={{}}
                stripPastedStyles
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'list',
                    'textAlign',
                    // 'colorPicker',
                    'link',
                    'history'
                  ],
                  blockType: {
                    inDropdown: true,
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined
                  },
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: [
                      'bold',
                      'italic',
                      'underline',
                      'strikethrough',
                      'superscript',
                      'subscript'
                    ]
                  }
                }}
              />
            </Col>
          </Row>
        ) : (
          <Spinner />
        )}
      </Modal>
    </>
  );
};
