import styled from 'styled-components';

interface MavieRankProps {
  isNext: boolean;
}

export const MavieRank = styled.div`
  /* box-shadow: ${(props) => props.theme.shadows.shadowXS}; */
  width: 100%;
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
    padding: 32px;
  }
  > img {
    width: 100%;
  }
  border-radius: 15px;
  margin-bottom: 40px;
  @media (max-width: 800px) {
    border-radius: 8px;
    margin-bottom: 20px;
    > div {
      padding: 20px;
    }
  }
  overflow: hidden;
  /* background: ${(props) => props.theme.colors.neutral50}; */
  @media (max-width: 800px) {
    box-shadow: none;
    border-radius: 0;
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
`;
export const CheckboxRank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: ${(props) => props.color};
  border-radius: 50%;
  flex: none;
`;
export const CheckboxButton = styled.div`
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  > div {
    height: 47px;
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 16px;
    width: 24px;
  }
`;
export const TierBars = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
`;
export const TierReward = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  margin-bottom: 10px;
  > span {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    background: ${(props) => props.color};
    border-radius: 50%;
  }
`;
export const PromotionStartEnd = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
export const TierCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  background: ${(props) => props.color};
  border-radius: 50%;
  margin-bottom: 14px;
  @media (max-width: 800px) {
    margin-bottom: 0;
  }
`;

interface ArrowTierProps {
  hasCondition: boolean;
}

export const ArrowTier = styled.div<ArrowTierProps>`
  height: 1px;
  border-bottom: 1px dashed
    ${(props) => (props.hasCondition ? '#4830C1' : '#A9A9A9')};
  margin: 0 20px;
`;
export const CountdownNumbers = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  > div {
    width: 40px;
    margin: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      color: #dbcea2;
    }
    @media (max-width: 800px) {
      width: calc(25% - 30px);
    }
  }
  img {
    width: 100%;
  }
`;

export const ProgressBarWithPercentage = styled.div<any>`
  width: 100%;
  /* padding: 2px 3px; */
  background: '#09B29C';
  border: 1px solid rgba(45, 45, 45, 0.02);
  box-sizing: border-box;
  border-radius: 13px;
  margin-bottom: 20px;
  height: 12px;
  > div {
    position: relative;
    width: ${(props) =>
      props.percentage ? `${props.percentage + '%'}` : '0%'};
    height: 12px;
    background: ${(props) => props.color};
    border-radius: 6px;
    > div {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -7px;
      border: 2px solid ${(props) => props.color};
      box-shadow: ${(props) => props.color};
      background: '#09B29C';
      color: ${(props) => props.color};
      border-radius: 9px;
      white-space: nowrap;
      flex: none;
      padding: 0 5px;
      height: 26px;
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
      ${({ percentage, progressWidth, progressValueWidth }) =>
        percentageBoxPosition(percentage, progressWidth, progressValueWidth)};
    }
  }
`;

export const percentageBoxPosition = (
  percentage: any,
  progressWidth: any,
  progressValueWidth: any
) => {
  if (numberInRange(percentage, 0, 10)) {
    if (progressWidth >= progressValueWidth) {
      return 'right: -20px;';
    } else {
      return 'left: 0;';
    }
  }

  if (numberInRange(percentage, 11, 69)) return 'right: -20px;';
  if (numberInRange(percentage, 70, 101)) return 'right: 0;';
};

export function numberInRange(num: number, low: number, high: number) {
  if (num >= low && num <= high) {
    return true;
  }

  return false;
}
