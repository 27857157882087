import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  handleSetUrlSearchParams,
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Bonus ID',
    accessor: 'id',
    Cell: (cell: any) => {
      const [searchParams, setSearchParams] = useSearchParams();
      const navigate = useNavigate();

      return (
        <div
          onClick={() =>
            navigate(
              `/fast-start-bonus/${searchParams.get('userId')}${
                cell.row.original.dateCreated ? `/${cell?.value}` : '/null'
              }`
            )
          }>
          {cell?.value}
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'Package ID',
    accessor: 'userPackageId',
    Cell: (cell: any) => {
      return cell?.value || '---';
    }
  },
  {
    id: 4,
    Header: 'Amount',
    accessor: 'amount',
    Cell: (cell: any) => {
      return cell?.value ? <>{usdFormatter.format(cell?.value)}</> : '---';
    }
  },
  {
    id: 5,
    Header: 'Commission',
    accessor: 'comissionAmount',
    Cell: (cell: any) => {
      return cell?.value ? <>{usdFormatter.format(cell?.value)}</> : '---';
    }
  },
  {
    id: 6,
    Header: 'RP',
    accessor: 'rewardPoints',
    Cell: (cell: any) => {
      return <>{cell?.value || '---'}</>;
    }
  },
  {
    id: 7,
    Header: 'SP',
    accessor: 'shoppingPoints',
    Cell: (cell: any) => {
      return <>{cell?.value || '---'}</>;
    }
  },
  {
    id: 8,
    Header: 'Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {cell.value ? (
          <>
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        ) : (
          '---'
        )}
      </>
    )
  },
  {
    id: 9,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Paid
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Ineligible
            </span>
          );

        default:
          return '---';
      }
    }
  }
];
