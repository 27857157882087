/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthUser {
  email: string;
  password: string;
}

// interface Permissions {
//   [key: string]: boolean;
// }

interface AuthState {
  loading: boolean;
  authUser: AuthUser | null;
}

const initialState: AuthState = {
  loading: false,
  authUser: {
    email: '',
    password: ''
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveSignInUser(state, action: PayloadAction<AuthUser>) {
      state.authUser = action.payload;
    }
  }
});

export const { saveSignInUser } = authSlice.actions;
export default authSlice.reducer;
