export interface License {
  licenceId: number;
  name: string;
  price: number;
  yearDuration: number;
}

export interface User {
  id: number;
  email: string;
  profileImageUrl: string;
  name: string;
  surname: string;
  dateCreated: string;
}

export enum OrderType {
  ORDER = 1,
  PACKAGE,
  LICENCE,
  COMBO
}

export interface LicenseType {
  id: number;
  price: number;
  status: number;
  dateCreated: string;
  dateEnd: string;
  datePaid: string;
  networkType: number;
  paymentAddress: string;
  dateLimitForAutoStake: string;
  licence: License;
  user: User;
  orderType: OrderType;
}
