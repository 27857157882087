import { FC, useEffect } from 'react';
import CountUp from 'react-countup';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';
import { getDirectorInfo } from './slice';
import { SelectFilter } from '../../../Components/Custom/SelectFilter';

const PAGE_TITLE = 'Director Info';

const INITIAL_PARAMS: {
  timeFilter: string;
} = {
  timeFilter: 'allTime'
};

const DirectorInfoPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);
  const { loading, data } = useAppSelector((state) => state.directorInfo);

  useEffect(() => {
    dispatch(getDirectorInfo(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  if (loading && !data) return null;

  const balanceInfo: any = {
    title: 'Balance',
    info: [
      {
        name: 'Club USDT',
        value: data?.shoppingPoints
      },
      {
        name: 'USDT',
        value: data?.rewardPoints
      },
      {
        name: 'ULX Points',
        value: data?.ulxPoints
      },
      {
        name: 'Buyback ULX',
        value: data?.buybackUlx
      }
    ]
  };

  const usdtWithdrawalsInfo: any = {
    title: 'USDT withdrawals',
    info: [
      {
        name: 'USDT withdrawals',
        value: data?.totalWithdrawals
      },
      {
        name: 'Pending Withdrawals',
        value: data?.pendingWithdrawals
      },
      {
        name: 'Paid Withdrawals',
        value: data?.pendingWithdrawals
      }
    ]
  };

  const purchasesInfo: any = {
    title: 'Purchases',
    info: [
      {
        name: 'USDT orders',
        value: data?.bankTransferPurchases
      },
      {
        name: 'Club purchase',
        value: data?.shoppingPointsPurchases
      },
      {
        name: 'Total turnover',
        value: data?.totalPurchases
      },
      {
        name: ' Number of paid orders CLUB',
        value: data?.shoppingPointsCount
      },
      {
        name: ' Number of paid orders USDT',
        value: data?.bankTransferCount
      },
      {
        name: '  Number of Withdrawals',
        value: data?.withdrawalsCount
      }
    ]
  };

  const rewardsInfo: any = {
    title: 'Rewards',
    info: [
      {
        name: 'Direct bonus',
        value: data?.directBonusesTotal
      },
      {
        name: 'Team bonus',
        value: data?.teamBonusesTotal
      },
      {
        name: 'Matching bonus',
        value: data?.matchingBonusesTotal
      },
      {
        name: 'Global Pool Bonus',
        value: data?.globalPoolBonusesTotal
      },
      {
        name: 'Cashback Bonus',
        value: data?.cashbackBonusesTotal
      },
      {
        name: 'Top Performer Bonus',
        value: data?.topPerformerPoolBonusesTotal
      },
      {
        name: 'Rank Rewards',
        value: data?.packageRankRewardsTotal
      },
      {
        name: 'Total Rewards',
        value: data?.totalRewards
      },
      {
        name: 'Payout Percent',
        value: data?.payoutPercent
      }
    ]
  };

  const timeFilterOptions = [
    { label: 'All time', value: 'allTime' },
    { label: '24 hours', value: '24h' },
    { label: '7 days', value: '7days' },
    { label: '30 days', value: '30days' }
  ];

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />

        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <CardBody className='border border-dashed border-end-0 border-start-0'>
                <Col md={3} sm={4}>
                  <SelectFilter
                    param='timeFilter'
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    options={timeFilterOptions}
                    placeholder='Time filter'
                    hasPagination={false}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={balanceInfo.title} />
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {balanceInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={balanceInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={usdtWithdrawalsInfo.title} />
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {usdtWithdrawalsInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={usdtWithdrawalsInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={purchasesInfo.title} />
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {purchasesInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={purchasesInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={rewardsInfo.title} />
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {rewardsInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={rewardsInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={'Hubs'} />
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => (
                    <TileHub
                      item={item}
                      key={i}
                      index={i}
                      data={rewardsInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DirectorInfoPage;

const Tile: FC<any> = ({ item, index, data }) => {
  return (
    <Col
      className={
        index + 1 === data.length ? 'col-lg-3' : 'col-lg-3 border-end'
      }>
      <div className='mt-3 mt-md-0 py-4 px-3'>
        <h5 className='text-muted text-uppercase fs-13'>
          {item.name} <i className={'fs-18 float-end align-middle '}></i>
        </h5>
        <div className='d-flex align-items-center'>
          <div className='flex-shrink-0'>
            <i className={'display-6 text-muted ' + item.icon}></i>
          </div>
          <div className='flex-grow-1 ms-3'>
            <h2 className='mb-0'>
              <span className='counter-value'>
                <CountUp
                  start={0}
                  prefix={'$'}
                  suffix={''}
                  // separator={''}
                  end={item.value}
                  decimals={2}
                  duration={2}
                />
              </span>
            </h2>
          </div>
        </div>
      </div>
    </Col>
  );
};

const TileHub: FC<any> = ({ item, index, data }) => {
  return (
    <Col
      className={
        index + 1 === data.length ? 'col-lg-4' : 'col-lg-4 border-end'
      }>
      <div className='mt-3 mt-md-0 py-4 px-3'>
        <h5 className='text-muted text-uppercase fs-13'>
          {item.price} USDT <i className={'fs-18 float-end align-middle '}></i>
        </h5>
        <div className='d-flex align-items-center'>
          <div className='flex-shrink-0'>
            <i className={'display-6 text-muted ' + item.icon}></i>
          </div>
          <div className='flex-grow-1 ms-3'>
            <h2 className='mb-0'>
              <span className='counter-value'>
                <CountUp
                  start={0}
                  prefix={'$'}
                  suffix={''}
                  // separator={''}
                  end={item.sum}
                  decimals={2}
                  duration={2}
                />
                /
                <CountUp start={0} end={item.count} duration={2} />
              </span>
            </h2>
          </div>
        </div>
      </div>
    </Col>
  );
};
