import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { LicenseType } from './types';

export const getLicenses = createAsyncThunk(
  'licenses/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/licence/user', {
      params
    });
    return response.data.data;
  }
);

export const updateLicenseToPaid = createAsyncThunk(
  'licenses/updateLicenseToPaid',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/licence/user/status/paid`, {
        id
      });
      toast.success(response.data.message);
      return response.data.data.userLicence as LicenseType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const stornoLicence = createAsyncThunk(
  'licenses/stornoLicence',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/licence/user/status/canceled`,
        {
          id
        }
      );
      toast.success('Licence successfully updated');
      return response.data.data.userLicence as LicenseType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export interface LicensesStateInterface {
  data: LicenseType[];
  loading: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as LicensesStateInterface;

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getLicenses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLicenses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.userLicences as LicenseType[];
      state.totalCount = payload.count;
    });
    builder.addCase(getLicenses.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateLicenseToPaid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLicenseToPaid.fulfilled, (state, { payload }) => {
      state.loading = false;

      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index] = payload;
    });
    builder.addCase(updateLicenseToPaid.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(stornoLicence.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(stornoLicence.fulfilled, (state, { payload }) => {
      state.loading = false;

      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index] = payload;
    });
    builder.addCase(stornoLicence.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default licensesSlice.reducer;
