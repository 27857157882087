// import { Tree } from './tree/Tree';
// import { ChevronDown, ChevronUp } from 'react-feather';
// import { getUserStructureData } from '../../redux/actions/myTeam';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getTeamStructureById } from './slice';
import { Tree } from './tree/tree';

export const Structure = () => {
  const dispatch = useAppDispatch();
  const { structure } = useAppSelector((state) => state.teams);

  const goLeftDown = () => {
    const id = structure.leftChildUser.id;
    if (id) dispatch(getTeamStructureById(id.toString()));
  };

  const goRightDown = () => {
    const id = structure?.rightChildUser?.id;
    if (id) dispatch(getTeamStructureById(id.toString()));
  };

  const goUp = () => {
    if (structure?.parentId !== null) {
      dispatch(getTeamStructureById(structure?.parentId.toString()));
    }
  };

  return (
    <>
      {structure && <Tree rootTeamMember={structure} />}
      <div className='d-flex justify-content-around mt-4'>
        <div className='d-flex flex-column gap-2'>
          <div>
            <Button onClick={goUp} color='primary'>
              <i className='ri-arrow-up-s-line fs-24'></i>
            </Button>
          </div>
          <div>
            <Button onClick={goLeftDown} color='primary'>
              <i className='ri-arrow-down-s-line fs-24'></i>
            </Button>
          </div>
        </div>
        <div className='d-flex flex-column gap-2'>
          <div>
            <Button onClick={goUp} color='primary'>
              <i className='ri-arrow-up-s-line fs-24'></i>
            </Button>
          </div>
          <div>
            <Button onClick={goRightDown} color='primary'>
              <i className='ri-arrow-down-s-line fs-24'></i>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Structure;
