import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import { RenderSorting } from '../../../Components/Custom/RenderSorting';
import { INPUT_TYPE_SEARCH, INPUT_TYPE_SELECT } from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
}

export const Filters: FC<FiltersProps> = ({ params, setParams }) => {
  const filterInputs = [
    {
      param: 'userId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search User ID'
    },

    {
      param: 'remark',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search remark'
    }
  ];

  return (
    <>
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
    </>
  );
};
