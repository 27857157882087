import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { TopPerformerBonusState, TopPerformerBonusType } from './types';
import { toast } from 'react-toastify';

export const getTopPerformerBonus = createAsyncThunk(
  'getTopPerformerBonus/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(`/bonus/top-performer`, {
      params
    });
    if (response.data.data.userTopPerformerBonus === null) {
      toast.error('Please type valid Order ID');
    }
    return response.data.data as TopPerformerBonusType;
  }
);

const initialState = {
  data: {},
  loading: false
} as TopPerformerBonusState;

const getTopPerformerBonusSlice = createSlice({
  name: 'getTopPerformerBonus',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getTopPerformerBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopPerformerBonus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getTopPerformerBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default getTopPerformerBonusSlice.reducer;
