import moment from 'moment';
import {
  handleValidDate,
  handleValidTime,
  formatUSD
} from '../../../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Date',
    accessor: 'date',
    Cell: (cell: any) => {
      return (
        <div>
          {moment.utc(cell?.row?.original.weekStartDate).format('DD/MM/YY')}
          {' - '}
          {moment.utc(cell?.row?.original.weekEndDate).format('DD/MM/YY')}
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'Invoice ID',
    accessor: 'id',
    Cell: (cell: any) => <div>#{cell?.row?.original?.id}</div>
  },

  {
    id: 3,
    Header: 'Amount',
    accessor: 'amount',
    Cell: (cell: any) => {
      return <> {formatUSD(cell?.row?.original?.amount)} USDT</>;
    }
  },

  {
    id: 4,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      return (
        <div>
          {cell?.row?.original?.amount !== 0
            ? cell?.row?.original?.isPaid
              ? 'Paid'
              : 'Processing'
            : '/'}
        </div>
      );
    }
  },

  {
    id: 5,
    Header: 'Invoice',
    accessor: 'invoice',
    Cell: (cell: any) => (
      <div>{cell?.row?.original?.amount === 0 && 'No report'}</div>
    )
  }
];
