import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { DeleteTask } from './delete';

export const columns = [
  {
    id: 1,
    Header: 'Task Id',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User Id',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell.row.original.user.id;
    }
  },
  {
    id: 3,
    Header: 'User',
    accessor: 'user',
    Cell: (cell: any) => {
      return `${cell.row.original.user.name} ${cell.row.original.user.surname}`;
    }
  },
  {
    id: 4,
    Header: 'Title',
    accessor: 'title',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  // {
  //   id: 5,
  //   Header: 'Description',
  //   accessor: 'description',
  //   Cell: (cell: any) => {
  //     return cell?.value;
  //   }
  // },
  // {
  //   id: 6,
  //   Header: 'Category name',
  //   accessor: 'category',
  //   Cell: (cell: any) => {
  //     return cell.row.original.category.categoryName;
  //   }
  // },
  {
    id: 7,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },
  {
    id: 8,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.row.original.status.statusId) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              TO DO
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              IN PROGRESS
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              COMPLETED
            </span>
          );
      }
    }
  },
  {
    id: 9,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      return <DeleteTask task={cell.row.original} />;
    }
  }
];
