import moment from 'moment';
import { FC } from 'react';
import { Col, Row } from 'reactstrap';
import {
  formatUSDNoDecimals,
  handleValidDate
} from '../../../../../helpers/helpers';
import { HubsTradingPackage, TradingType } from '../../types';
import { PackageConverted } from './PackageDisabled';

export const TradingHub: FC<any> = ({ data }: { data: HubsTradingPackage }) => {
  const renderExpiryDate = (date: string) => {
    const d = moment(date);
    const year = d.format('YYYY');
    if (Number(year) > 2100) {
      return 'LIFETIME';
    } else return d.format('DD MMM YYYY');
  };

  return (
    <div
      className='d-flex mb-3 mt-2 border border-primary'
      style={{ position: 'relative' }}>
      <img width={200} src={data.image} />
      <div className='d-flex  w-100 align-items-center'>
        <div className=' p-3  w-100'>
          <h4>{data?.name}</h4>
          <Row>
            <Col lg={3} className=' border-end p-3  mt-2'>
              <h5 className='text-muted text-uppercase fs-13'>PRICE</h5>
              <h4 className='fs-15 mb-0'>
                {formatUSDNoDecimals(data.price)}
                <span className='text-muted fs-13'> USDT</span>
              </h4>
            </Col>
            <Col lg={3} className=' border-end p-3  mt-2'>
              <h5 className='text-muted text-uppercase fs-13'>
                DATE OF PURCHASE
              </h5>
              <h4 className='fs-15 mb-0'>
                {moment(data?.dateOfPurchase).format('DD MMM YYYY')}
              </h4>
            </Col>
            <Col lg={3} className=' p-3  mt-2'>
              <h5 className='text-muted text-uppercase fs-13'>
                DATE OF ACTIVATION:
              </h5>
              <h4 className='fs-15 mb-0'>
                {moment(data?.dateOfPurchase).format('DD MMM YYYY')}
              </h4>
            </Col>
            <Col lg={3} className=' p-3  mt-2'>
              <h5 className='text-muted text-uppercase fs-13'>
                DATE OF EXPIRY:
              </h5>
              <h4 className='fs-15 mb-0'>
                {renderExpiryDate(data.expiryDate)}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg={3} className='p-3'>
              <p className='mb-0'>TRADING HUB DETAILS</p>
            </Col>
            <Col lg={3} className='p-3'>
              <p className='mb-0'>
                ORDER ID: <b>{data.id}</b>
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <PackageConverted data={data} />
    </div>
  );
};
