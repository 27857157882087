import { TeamMember } from '../teamMember';
import { TeamStructureUserType } from '../types';

import { Arrows } from './common';

const ZeroLevel = ({
  rootTeamMember
}: {
  rootTeamMember: TeamStructureUserType;
}) => {
  if (rootTeamMember === null) return null;

  return (
    <>
      <div className='d-flex align-center flex-column align-items-center'>
        <TeamMember
          structure
          id={rootTeamMember.id}
          user={rootTeamMember}
          leftLegTotalVolume={rootTeamMember.leftLegTotalVolume}
          rightLegTotalVolume={rootTeamMember.rightLegTotalVolume}
        />
        <Arrows />
      </div>
    </>
  );
};

export { ZeroLevel };
