import styled from 'styled-components';

export const DisableSelection = ` 
-webkit-touch-callout: none !important;
-webkit-user-select: none !important;
-khtml-user-select: none !important; 
-moz-user-select: none !important;
-ms-user-select: none !important; 
user-select: none !important; 
`;

export const ProfilePicture = styled.div<{ size: string }>`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};

  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  cursor: pointer;

  ${DisableSelection}/* border: 1.5px solid rgba(254, 254, 254, 0.3); */
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;
