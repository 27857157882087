import { Conversions } from './../pages/Dashboard/Conversions/slice';
import { EventTickets } from './../pages/Dashboard/EventTickets/types';
import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';
import authReducer from './auth/auth-slice';
import taskListReducer from '../pages/Dashboard/TaskList/slice';
import directorInfoReducer from '../pages/Dashboard/DirectorInfo/slice';
import superAdminReducer from '../pages/Dashboard/SuperAdmin/slice';
import usersReducer from '../pages/Dashboard/Users/slice';
import userDetailsReducer from '../pages/Dashboard/UserDetails/slice';
import packageOrdersReducer from '../pages/Dashboard/PackageOrders/slice';
import packageRankHistoryReducer from '../pages/Dashboard/RankHistory/slice';
import packageRankRewardsReducer from '../pages/Dashboard/RankReward/slice';
import eventTicketsReducer from '../pages/Dashboard/EventTickets/slice';
import withdrawalsReducer from '../pages/Dashboard/Withdrawals/slice';
import blacklistedUsersReducer from '../pages/Dashboard/BlacklistedUsers/slice';
import blacklistedWalletsReducer from '../pages/Dashboard/BlackistedWallets/slice';
import conversionsReducer from '../pages/Dashboard/Conversions/slice';
import performanceIdsReducer from '../pages/Dashboard/PerformanceIDs/slice';
import teamsReducer from '../pages/Dashboard/Teams/slice';
import freeWillyCoinsReducer from '../pages/Dashboard/FreeWillyCoins/slice';
import earningsReducer from '../pages/Dashboard/Earnings/slice';
import licensesReducer from '../pages/Dashboard/Licenses/slice';
import teamBonusReducer from '../pages/Bonuses/TeamBonus/slice';
import tradingTeamBonusReducer from '../pages/Bonuses/TradingTeamBonus/slice';
import directBonusReducer from '../pages/Bonuses/DirectBonus/slice';
import matchingBonusReducer from '../pages/Bonuses/MatchingBonus/slice';
import totalBonusReducer from '../pages/Bonuses/TotalBonus/slice';
import customerBonusReducer from '../pages/Bonuses/CustomerBonus/slice';
import fastStartBonusReducer from '../pages/Bonuses/FastStartBonus/slice';
import globalPoolBonusBonusReducer from '../pages/Bonuses/GlobalPoolBonus/slice';
import topPerformerBonusBonusReducer from '../pages/Bonuses/TopPerformerBonus/slice';
import ContentBlocksReducer from '../pages/Dashboard/ContentBlocks/slice';
import faqReducer from '../pages/Faq/slice';
import balanceChangeReducer from '../pages/Dashboard/BalanceChange/slice';
import discountCodeReducer from '../pages/Dashboard/DiscountCode/slice';

export const reducers = combineReducers({
  Layout: LayoutReducer,
  auth: authReducer,
  taskList: taskListReducer,
  directorInfo: directorInfoReducer,
  superAdmin: superAdminReducer,
  users: usersReducer,
  userDetails: userDetailsReducer,
  packageOrders: packageOrdersReducer,
  packageRankHistory: packageRankHistoryReducer,
  packageRankRewards: packageRankRewardsReducer,
  eventTickets: eventTicketsReducer,
  withdrawals: withdrawalsReducer,
  teams: teamsReducer,
  freeWillyCoins: freeWillyCoinsReducer,
  earnings: earningsReducer,
  licenses: licensesReducer,
  teamBonus: teamBonusReducer,
  tradingTeamBonus: tradingTeamBonusReducer,
  directBonus: directBonusReducer,
  matchingBonus: matchingBonusReducer,
  totalBonus: totalBonusReducer,
  customerBonus: customerBonusReducer,
  fastStartBonus: fastStartBonusReducer,
  globalPoolBonus: globalPoolBonusBonusReducer,
  topPerformerBonus: topPerformerBonusBonusReducer,
  contentBlocks: ContentBlocksReducer,
  faq: faqReducer,
  balanceChange: balanceChangeReducer,
  blacklistedWallets: blacklistedWalletsReducer,
  blacklistedUsers: blacklistedUsersReducer,
  performanceIDs: performanceIdsReducer,
  discountCode: discountCodeReducer,
  conversions: conversionsReducer
});

export type AppState = ReturnType<typeof reducers>;
