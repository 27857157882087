import {
  formatUSDT,
  handleValidDate,
  handleValidTime
} from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Bonus ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  // {
  //   id: 3,
  //   Header: 'Rank',
  //   accessor: 'packageRank',
  //   Cell: (cell: any) => {
  //     return <>{cell?.value?.name}</>;
  //   }
  // },
  {
    id: 4,
    Header: '%',
    accessor: 'commissionPercent',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 5,
    Header: 'Pay-out position',
    accessor: 'position',
    Cell: (cell: any) => {
      return <>{cell?.value === 0 ? 'Left' : 'Right'}</>;
    }
  },
  {
    id: 6,
    Header: 'LLV Before',
    accessor: 'leftLegVolumeBefore',
    Cell: (cell: any) => {
      return <>{formatUSDT.format(cell?.value)}</>;
    }
  },
  {
    id: 7,
    Header: 'RLV Before',
    accessor: 'rightLegVolumeBefore',
    Cell: (cell: any) => {
      return <>{formatUSDT.format(cell?.value)}</>;
    }
  },
  {
    id: 8,
    Header: 'Added on LLV',
    accessor: 'leftLegAmount',
    Cell: (cell: any) => {
      return <>{formatUSDT.format(cell?.value)}</>;
    }
  },
  {
    id: 9,
    Header: 'Added on RLV',
    accessor: 'rightLegAmount',
    Cell: (cell: any) => {
      return <>{formatUSDT.format(cell?.value)}</>;
    }
  },
  {
    id: 10,
    Header: 'LLV after',
    accessor: 'leftLegVolumeAfter',
    Cell: (cell: any) => {
      return <>{formatUSDT.format(cell?.value)}</>;
    }
  },
  {
    id: 11,
    Header: 'RLV after',
    accessor: 'rightLegVolumeAfter',
    Cell: (cell: any) => {
      return <>{formatUSDT.format(cell?.value)}</>;
    }
  },
  {
    id: 12,
    Header: 'Amount',
    accessor: 'paidCommissionAmount',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 13,
    Header: 'Calc Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },
  {
    id: 14,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Pending
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Paid
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-info'>
              Ineligible
            </span>
          );
      }
    }
  }
];
