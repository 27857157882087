import { Button } from 'reactstrap';
import { useAppDispatch } from '../../../app/hooks';
import { handleValidDate, handleValidTime } from '../../../helpers/helpers';
import { deleteQuestion, getQuestionById, setQuestionId } from '../slice';

export const columns = [
  {
    id: 1,
    Header: 'Question',
    accessor: 'question',
    Cell: (cell: any) => {
      const dispatch = useAppDispatch();
      return (
        <div onClick={() => dispatch(setQuestionId(cell?.row?.original?.id))}>
          {cell.value?.length > 30
            ? `${cell.value?.substring(0, 30)}...`
            : cell.value}
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'Answer',
    accessor: 'answer',
    Cell: (cell: any) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html:
              cell.value?.length > 30
                ? `${cell.value?.substring(0, 30)}...`
                : cell.value
          }}
        />
      );
    }
  },

  {
    id: 3,
    Header: 'Category ID',
    accessor: 'category',
    Cell: (cell: any) => {
      return cell?.value?.id;
    }
  },

  {
    id: 4,
    Header: 'Category Name',
    accessor: 'category',
    Cell: (cell: any) => {
      return cell?.value?.name;
    }
  },

  {
    id: 5,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },
  {
    id: 6,
    Header: 'Action',
    accessor: 'id',
    Cell: (cell: any) => {
      const dispatch = useAppDispatch();
      return (
        <>
          <Button
            size='sm'
            color='danger'
            className='btn btn-soft-danger btn-sm'
            onClick={() => dispatch(deleteQuestion({ id: cell.value }))}>
            <i className='ri-delete-bin-line fs-14'></i>
          </Button>
        </>
      );
    }
  }
];
