import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { CashbackBonusData, CashbackStateType, CashbackType } from './types';
import { toast } from 'react-toastify';

export const getFastStartBonusList = createAsyncThunk(
  'getFastStartBonusList/fetch',
  async (params: URLSearchParams, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bonus/cashback/user/list`, {
        params: {
          userId: params.get('userId'),
          countPerPage: '10'
        }
      });
      return response.data.data as CashbackBonusData;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const getFastStartBonus = createAsyncThunk(
  'getFastStartBonus/fetch',
  async (params: URLSearchParams, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bonus/cashback/info`, {
        params: {
          userId: params.get('userId')
        }
      });
      return response.data.data as CashbackType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

const initialState = {
  data: {},
  list: {},
  loading: false
} as CashbackStateType;

const fastStartBonusSlice = createSlice({
  name: 'getCashbackBonus',
  initialState,
  reducers: {
    // fill in primary logic here
  },

  extraReducers: (builder) => {
    builder.addCase(getFastStartBonusList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFastStartBonusList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.list = payload;
    });
    builder.addCase(getFastStartBonusList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getFastStartBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFastStartBonus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getFastStartBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default fastStartBonusSlice.reducer;
