import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';

interface PaginationProps {
  totalCount: number | null;
  data: object[];
  setParams: any;
  params: any;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  data,
  setParams,
  params
}) => {
  const pageSize = Number(params.get('countPerPage'));
  const pageIndex = Number(params.get('numberOfPage'));

  const calculatePagesCount = () => {
    if (data?.length && totalCount && pageSize) {
      return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
    }
  };

  const totalNumberOfPages = calculatePagesCount() ?? 0;
  const canPreviousPage = pageIndex > 1;
  const canNextPage = pageIndex >= totalNumberOfPages;

  const handleChange = (
    key: string,
    value: any,
    setSearchParams: any
  ): void => {
    setSearchParams((prevParams: any) => {
      if (value === null || value === undefined || value === '') {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  };

  const nextPage = () => {
    handleChange('numberOfPage', pageIndex + 1, setParams);
  };

  const previousPage = () => {
    handleChange('numberOfPage', pageIndex - 1, setParams);
  };

  const onChangeInputPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (e.target.value < 1) return;
    handleChange('numberOfPage', e.target.value, setParams);
  };

  const onChangeInSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange('countPerPage', e.target.value, setParams);
    handleChange('numberOfPage', 1, setParams);
  };

  return (
    <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
      <Col md={2}>
        <select
          className='form-select'
          value={pageSize}
          onChange={onChangeInSelect}>
          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </Col>

      <Col className='col-md-auto'>
        <div className='d-flex gap-1'>
          <Button
            color='primary'
            onClick={previousPage}
            disabled={!canPreviousPage}>
            {'<'}
          </Button>
        </div>
      </Col>

      <Col className='col-md-auto d-none d-md-block'>
        Page{' '}
        <strong>
          {pageIndex} of {totalNumberOfPages}
        </strong>
      </Col>

      <Col className='col-md-auto'>
        <Input
          type='number'
          style={{ width: 70 }}
          max={totalNumberOfPages}
          value={pageIndex}
          onChange={onChangeInputPage}
        />
      </Col>

      <Col className='col-md-auto d-none d-md-block'>
        Total: <strong>{totalCount}</strong>
      </Col>

      <Col className='col-md-auto'>
        <div className='d-flex gap-1'>
          <Button color='primary' onClick={nextPage} disabled={canNextPage}>
            {'>'}
          </Button>
        </div>
      </Col>
    </Row>
  );
};
