import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { EventTicketsState, EventTickets, EventTicketStatus } from './types';
import { toast } from 'react-toastify';

export const getEventTickets = createAsyncThunk(
  'eventTickets/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/event-ticket', {
      params
    });

    return response?.data?.data;
  }
);

export const markEventTicketPaid = createAsyncThunk(
  'eventTickets/paid',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/event-ticket/${id}/mark-as-paid`
      );
      toast.success(response.data.message);

      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const cancelEventTicket = createAsyncThunk(
  'packageOrders/cancelEventTicket',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/event-ticket/${id}/cancel`);
      toast.success(response.data.message);
      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as EventTicketsState;

const eventTicketsSlice = createSlice({
  name: 'eventTickets',
  initialState,
  reducers: {
    ordered: (state) => {
      state.loading = true;
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getEventTickets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEventTickets.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.totalCount = payload.count;
      state.data = payload.userEventTickets as EventTickets[];
    });
    builder.addCase(getEventTickets.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(markEventTicketPaid.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(markEventTicketPaid.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.data.findIndex((p) => p.id === payload);
      state.data[index].status = EventTicketStatus.PAID;
    });
    builder.addCase(markEventTicketPaid.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(cancelEventTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(cancelEventTicket.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.data.findIndex((p) => p.id === payload);
      state.data[index].status = EventTicketStatus.CANCELED;
    });
    builder.addCase(cancelEventTicket.rejected, (state) => {
      state.loading = false;
    });
  }
});

// export const { ordered } = packageRankRewardsSlice.actions;
export default eventTicketsSlice.reducer;
