import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button } from 'reactstrap';
import { useAppDispatch } from '../../../app/hooks';
// import { stornoLicence } from './slice';
import { Modal } from '../../../Components/Custom/modal';
import { deleteTask, updateTask } from './slice';
import { TaskStatus } from './types';

export const DeleteTask: FC<{
  task: any;
}> = ({ task }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();

  const showStatuses = (status: number) => {
    switch (status) {
      case TaskStatus.TODO:
        return (
          <i
            className='ri-play-line'
            style={{ cursor: 'pointer', marginRight: 10 }}
            onClick={() =>
              dispatch(
                updateTask({
                  id: task.id,
                  statusId: TaskStatus.IN_PROGRESS
                })
              )
            }></i>
        );

      case TaskStatus.IN_PROGRESS:
        return (
          <>
            <i
              className='ri-pause-line'
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() =>
                dispatch(
                  updateTask({
                    id: task.id,
                    statusId: TaskStatus.TODO
                  })
                )
              }></i>
            <i
              className='ri-task-fill'
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() =>
                dispatch(
                  updateTask({
                    id: task.id,
                    statusId: TaskStatus.COMPLETED
                  })
                )
              }></i>
          </>
        );

      case TaskStatus.COMPLETED:
        return <></>;
      default:
        <div></div>;
    }
  };

  return (
    <>
      <div className='d-flex justify-content-end'>
        {showStatuses(task?.status?.statusId)}
        <i
          className='ri-delete-bin-line '
          style={{ cursor: 'pointer', marginRight: 10 }}
          onClick={() => setIsOpen(!isOpen)}></i>
      </div>

      <DeleteTaskModal id={task.id} setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  );
};

export const DeleteTaskModal: FC<{
  id: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}> = ({ id, isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(!isOpen)}
      title='Delete task'
      actions={
        <>
          <Button
            color='primary'
            outline
            onClick={() => setIsOpen(!isOpen)}
            className='m-1'>
            Cancel
          </Button>
          <Button
            color='primary'
            className='m-1'
            onClick={() => {
              setIsOpen(!isOpen);
              dispatch(deleteTask(id));
            }}>
            Yes
          </Button>
        </>
      }>
      <div className='mb-3'>
        <div className='avatar-lg mx-auto'>
          <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
            <i className='ri-error-warning-fill '></i>
          </div>
        </div>
      </div>
      <h2 className='text-center'>Are you sure?</h2>
      <p className='text-center'>You won’t be able to revert this!</p>
    </Modal>
  );
};
