import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button } from 'reactstrap';
import { useAppDispatch } from '../../../app/hooks';
// import { stornoLicence } from './slice';
import { Modal } from '../../../Components/Custom/modal';
import { deleteblacklistWallet } from './slice';

export const DeleteWallet: FC<{
  address: string;
}> = ({ address }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        size='sm'
        color='danger'
        className='btn btn-soft-danger btn-sm'
        outline
        onClick={() => setIsOpen(!isOpen)}>
        Delete
      </Button>

      <DeleteWalletModal
        address={address}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </>
  );
};

export const DeleteWalletModal: FC<{
  address: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}> = ({ address, isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(!isOpen)}
      title='Delete wallet'
      actions={
        <>
          <Button
            color='primary'
            outline
            onClick={() => setIsOpen(!isOpen)}
            className='m-1'>
            Cancel
          </Button>
          <Button
            color='primary'
            className='m-1'
            onClick={() => {
              setIsOpen(!isOpen);
              dispatch(deleteblacklistWallet(address));
            }}>
            Yes
          </Button>
        </>
      }>
      <div className='mb-3'>
        <div className='avatar-lg mx-auto'>
          <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
            <i className='ri-error-warning-fill '></i>
          </div>
        </div>
      </div>
      <h2 className='text-center'>Are you sure?</h2>
      <p className='text-center'>You won’t be able to revert this!</p>
    </Modal>
  );
};
