const countries = [
  {
    label: 'Afghanistan',
    value: 'AF',
    // // flag: af,
    checked: false
  },
  {
    label: 'Aland Islands',
    value: 'AX',
    // flag: ax,
    checked: false
  },
  {
    label: 'Albania',
    value: 'AL',
    // flag: al,
    checked: false
  },
  {
    label: 'Algeria',
    value: 'DZ',
    // flag: dz,
    checked: false
  },
  {
    label: 'AmericanSamoa',

    value: 'AS',
    // flag: ams,
    checked: false
  },
  {
    label: 'Andorra',
    value: 'AD',
    // flag: ad,
    checked: false
  },
  {
    label: 'Angola',
    value: 'AO',
    // flag: ao,
    checked: false
  },
  {
    label: 'Anguilla',

    value: 'AI',
    // flag: ai,
    checked: false
  },
  {
    label: 'Antarctica',
    value: 'AQ',
    // flag: aq,
    checked: false
  },
  {
    label: 'Antigua and Barbuda',

    value: 'AG',
    // flag: ag,
    checked: false
  },
  {
    label: 'Argentina',
    value: 'AR',
    // flag: ar,
    checked: false
  },
  {
    label: 'Armenia',
    value: 'AM',
    // flag: am,
    checked: false
  },
  {
    label: 'Aruba',
    value: 'AW',
    // flag: aw,
    checked: false
  },
  {
    label: 'Australia',
    value: 'AU',
    // flag: au,
    checked: false
  },
  {
    label: 'Austria',
    value: 'AT',
    // flag: at,
    checked: false
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
    // flag: az,
    checked: false
  },
  {
    label: 'Bahamas',

    value: 'BS',
    // flag: bs,
    checked: false
  },
  {
    label: 'Bahrain',
    value: 'BH',
    // flag: bh,
    checked: false
  },
  {
    label: 'Bangladesh',
    value: 'BD',
    // flag: bd,
    checked: false
  },
  {
    label: 'Barbados',

    value: 'BB',
    // flag: bb,
    checked: false
  },
  {
    label: 'Belarus',
    value: 'BY',
    // flag: by,
    checked: false
  },
  {
    label: 'Belgium',
    value: 'BE',
    // flag: be,
    checked: false
  },
  {
    label: 'Belize',
    value: 'BZ',
    // flag: bz,
    checked: false
  },
  {
    label: 'Benin',
    value: 'BJ',
    // flag: bj,
    checked: false
  },
  {
    label: 'Bermuda',

    value: 'BM',
    // flag: bm,
    checked: false
  },
  {
    label: 'Bhutan',
    value: 'BT',
    // flag: bt,
    checked: false
  },
  {
    label: 'Bolivia, Plurinational State of',
    value: 'BO',
    // flag: bo,
    checked: false
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    // flag: ba,
    checked: false
  },
  {
    label: 'Botswana',
    value: 'BW',
    // flag: bw,
    checked: false
  },
  {
    label: 'Brazil',
    value: 'BR',
    // flag: br,
    checked: false
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
    // flag: io,
    checked: false
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
    // flag: bn,
    checked: false
  },
  {
    label: 'Bulgaria',
    value: 'BG',
    // flag: bg,
    checked: false
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    // flag: bf,
    checked: false
  },
  {
    label: 'Burundi',
    value: 'BI',
    // flag: bi,
    checked: false
  },
  {
    label: 'Cambodia',
    value: 'KH',
    // flag: kn,
    checked: false
  },
  {
    label: 'Cameroon',
    value: 'CM',
    // flag: cm,
    checked: false
  },
  {
    label: 'Canada',
    value: 'CA',
    // flag: ca,
    checked: false
  },
  {
    label: 'Cape Verde',
    value: 'CV',
    // flag: cv,
    checked: false
  },
  {
    label: 'Cayman Islands',

    value: 'KY',
    // flag: ky,
    checked: false
  },
  {
    label: 'Central African Republic',
    value: 'CF',
    // flag: cf,
    checked: false
  },
  {
    label: 'Chad',
    value: 'TD',
    // flag: td,
    checked: false
  },
  {
    label: 'Chile',
    value: 'CL',
    // flag: cl,
    checked: false
  },
  {
    label: 'China',
    value: 'CN',
    // flag: cn,
    checked: false
  },
  {
    label: 'Christmas Island',
    value: 'CX',
    // flag: cx,
    checked: false
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
    // flag: cc,
    checked: false
  },
  {
    label: 'Colombia',
    value: 'CO',
    // flag: co,
    checked: false
  },
  {
    label: 'Comoros',
    value: 'KM',
    // flag: km,
    checked: false
  },
  {
    label: 'Congo',
    value: 'CG',
    // flag: cg,
    checked: false
  },
  {
    label: 'Congo, The Democratic Republic of the Congo',
    value: 'CD',
    // flag: cd,
    checked: false
  },
  {
    label: 'Cook Islands',
    value: 'CK',
    // flag: ck,
    checked: false
  },
  {
    label: 'Costa Rica',
    value: 'CR',
    // flag: cr,
    checked: false
  },
  {
    label: "Cote d'Ivoire",
    value: 'CI',
    // flag: ci,
    checked: false
  },
  {
    label: 'Croatia',
    value: 'HR',
    // flag: hr,
    checked: false
  },
  {
    label: 'Cuba',
    value: 'CU',
    // flag: cu,
    checked: false
  },
  {
    label: 'Cyprus',
    value: 'CY',
    // flag: cy,
    checked: false
  },
  {
    label: 'Czech Republic',
    value: 'CZ',
    // flag: cz,
    checked: false
  },
  {
    label: 'Denmark',
    value: 'DK',
    // flag: dk,
    checked: false
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    // flag: dj,
    checked: false
  },
  {
    label: 'Dominica',

    value: 'DM',
    // flag: dm,
    checked: false
  },
  {
    label: 'Dominican Republic',

    value: 'DO',
    // flag: dor,
    checked: false
  },
  {
    label: 'Ecuador',
    value: 'EC',
    // flag: ec,
    checked: false
  },
  {
    label: 'Egypt',
    value: 'EG',
    // flag: eg,
    checked: false
  },
  {
    label: 'El Salvador',
    value: 'SV',
    // flag: sv,
    checked: false
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
    // flag: gq,
    checked: false
  },
  {
    label: 'Eritrea',
    value: 'ER',
    // flag: er,
    checked: false
  },
  {
    label: 'Estonia',
    value: 'EE',
    // flag: ee,
    checked: false
  },
  {
    label: 'Ethiopia',
    value: 'ET',
    // flag: et,
    checked: false
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK',
    // flag: fk,
    checked: false
  },
  {
    label: 'Faroe Islands',
    value: 'FO',
    // flag: fo,
    checked: false
  },
  {
    label: 'Fiji',
    value: 'FJ',
    // flag: fj,
    checked: false
  },
  {
    label: 'Finland',
    value: 'FI',
    // flag: fi,
    checked: false
  },
  {
    label: 'France',
    value: 'FR',
    // flag: fr,
    checked: false
  },
  {
    label: 'French Guiana',
    value: 'GF',
    // flag: gf,
    checked: false
  },
  {
    label: 'French Polynesia',
    value: 'PF',
    // flag: pf,
    checked: false
  },
  {
    label: 'Gabon',
    value: 'GA',
    // flag: ga,
    checked: false
  },
  {
    label: 'Gambia',
    value: 'GM',
    // flag: gm,
    checked: false
  },
  {
    label: 'Georgia',
    value: 'GE',
    // flag: ge,
    checked: false
  },
  {
    label: 'Germany',
    value: 'DE',
    // flag: de,
    checked: false
  },
  {
    label: 'Ghana',
    value: 'GH',
    // flag: gh,
    checked: false
  },
  {
    label: 'Gibraltar',
    value: 'GI',
    // flag: gi,
    checked: false
  },
  {
    label: 'Greece',
    value: 'GR',
    // flag: gr,
    checked: false
  },
  {
    label: 'Greenland',
    value: 'GL',
    // flag: gl,
    checked: false
  },
  {
    label: 'Grenada',

    value: 'GD',
    // flag: gd,
    checked: false
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
    // flag: gp,
    checked: false
  },
  {
    label: 'Guam',

    value: 'GU',
    // flag: gu,
    checked: false
  },
  {
    label: 'Guatemala',
    value: 'GT',
    // flag: gt,
    checked: false
  },
  {
    label: 'Guernsey',
    value: 'GG',
    // flag: gg,
    checked: false
  },
  {
    label: 'Guinea',
    value: 'GN',
    // flag: gn,
    checked: false
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
    // flag: gw,
    checked: false
  },
  {
    label: 'Guyana',
    value: 'GY',
    // flag: gy,
    checked: false
  },
  {
    label: 'Haiti',
    value: 'HT',
    // flag: ht,
    checked: false
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA',
    // flag: va,
    checked: false
  },
  {
    label: 'Honduras',
    value: 'HN',
    // flag: hn,
    checked: false
  },
  {
    label: 'Hong Kong',
    value: 'HK',
    // flag: hk,
    checked: false
  },
  {
    label: 'Hungary',
    value: 'HU',
    // flag: hu,
    checked: false
  },
  {
    label: 'Iceland',
    value: 'IS',
    // flag: is,
    checked: false
  },
  {
    label: 'India',
    value: 'IN',
    // flag: ind,
    checked: false
  },
  {
    label: 'Indonesia',
    value: 'ID',
    // flag: id,
    checked: false
  },
  {
    label: 'Iran, Islamic Republic of Persian Gulf',
    value: 'IR',
    // flag: ir,
    checked: false
  },
  {
    label: 'Iraq',
    value: 'IQ',
    // flag: iq,
    checked: false
  },
  {
    label: 'Ireland',
    value: 'IE',
    // flag: ie,
    checked: false
  },
  {
    label: 'Isle of Man',
    value: 'IM',
    // flag: im,
    checked: false
  },
  {
    label: 'Israel',
    value: 'IL',
    // flag: il,
    checked: false
  },
  {
    label: 'Italy',
    value: 'IT',
    // flag: it,
    checked: false
  },
  {
    label: 'Jamaica',

    value: 'JM',
    // flag: jm,
    checked: false
  },
  {
    label: 'Japan',
    value: 'JP',
    // flag: jp,
    checked: false
  },
  {
    label: 'Jersey',
    value: 'JE',
    // flag: je,
    checked: false
  },
  {
    label: 'Jordan',
    value: 'JO',
    // flag: jo,
    checked: false
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    // flag: kz,
    checked: false
  },
  {
    label: 'Kenya',
    value: 'KE',
    // flag: ke,
    checked: false
  },
  {
    label: 'Kiribati',
    value: 'KI',
    // flag: ki,
    checked: false
  },
  {
    label: "Korea, Democratic People's Republic of Korea",
    value: 'KP',
    // flag: kp,
    checked: false
  },
  {
    label: 'Korea, Republic of South Korea',
    value: 'KR',
    // flag: kr,
    checked: false
  },
  {
    label: 'Kuwait',
    value: 'KW',
    // flag: kw,
    checked: false
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
    // flag: kg,
    checked: false
  },
  {
    label: 'Laos',
    value: 'LA',
    // flag: la,
    checked: false
  },
  {
    label: 'Latvia',
    value: 'LV',
    // flag: lv,
    checked: false
  },
  {
    label: 'Lebanon',
    value: 'LB',
    // flag: lb,
    checked: false
  },
  {
    label: 'Lesotho',
    value: 'LS',
    // flag: ls,
    checked: false
  },
  {
    label: 'Liberia',
    value: 'LR',
    // flag: lr,
    checked: false
  },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'LY',
    // flag: ly,
    checked: false
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
    // flag: li,
    checked: false
  },
  {
    label: 'Lithuania',
    value: 'LT',
    // flag: lt,
    checked: false
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    // flag: lu,
    checked: false
  },
  {
    label: 'Macao',
    value: 'MO',
    // flag: mo,
    checked: false
  },
  {
    label: 'Macedonia',
    value: 'MK',
    // flag: mk,
    checked: false
  },
  {
    label: 'Madagascar',
    value: 'MG',
    // flag: mg,
    checked: false
  },
  {
    label: 'Malawi',
    value: 'MW',
    // flag: mw,
    checked: false
  },
  {
    label: 'Malaysia',
    value: 'MY',
    // flag: my,
    checked: false
  },
  {
    label: 'Maldives',
    value: 'MV',
    // flag: mv,
    checked: false
  },
  {
    label: 'Mali',
    value: 'ML',
    // flag: ml,
    checked: false
  },
  {
    label: 'Malta',
    value: 'MT',
    // flag: mt,
    checked: false
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
    // flag: mh,
    checked: false
  },
  {
    label: 'Martinique',
    value: 'MQ',
    // flag: mq,
    checked: false
  },
  {
    label: 'Mauritania',
    value: 'MR',
    // flag: mr,
    checked: false
  },
  {
    label: 'Mauritius',
    value: 'MU',
    // flag: mu,
    checked: false
  },
  {
    label: 'Mayotte',
    value: 'YT',
    // flag: yt,
    checked: false
  },
  {
    label: 'Mexico',
    value: 'MX',
    // flag: mx,
    checked: false
  },
  {
    label: 'Micronesia, Federated States of Micronesia',
    value: 'FM',
    // flag: fm,
    checked: false
  },
  {
    label: 'Moldova',
    value: 'MD',
    // flag: md,
    checked: false
  },
  {
    label: 'Monaco',
    value: 'MC',
    // flag: mc,
    checked: false
  },
  {
    label: 'Mongolia',
    value: 'MN',
    // flag: mn,
    checked: false
  },
  {
    label: 'Montenegro',
    value: 'ME',
    // flag: me,
    checked: false
  },
  {
    label: 'Montserrat',

    value: 'MS',
    // flag: ms,
    checked: false
  },
  {
    label: 'Morocco',
    value: 'MA',
    // flag: ma,
    checked: false
  },
  {
    label: 'Mozambique',
    value: 'MZ',
    // flag: mz,
    checked: false
  },
  {
    label: 'Myanmar',
    value: 'MM',
    // flag: mm,
    checked: false
  },
  {
    label: 'Namibia',
    value: 'NA',
    // flag: na,
    checked: false
  },
  {
    label: 'Nauru',
    value: 'NR',
    // flag: nr,
    checked: false
  },
  {
    label: 'Nepal',
    value: 'NP',
    // flag: np,
    checked: false
  },
  {
    label: 'Netherlands',
    value: 'NL',
    // flag: nl,
    checked: false
  },
  {
    label: 'Netherlands Antilles',
    value: 'AN',
    // flag: an,
    checked: false
  },
  {
    label: 'New Caledonia',
    value: 'NC',
    // flag: nc,
    checked: false
  },
  {
    label: 'New Zealand',
    value: 'NZ',
    // flag: nz,
    checked: false
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    // flag: ni,
    checked: false
  },
  {
    label: 'Niger',
    value: 'NE',
    // flag: ne,
    checked: false
  },
  {
    label: 'Nigeria',
    value: 'NG',
    // flag: ng,
    checked: false
  },
  {
    label: 'Niue',
    value: 'NU',
    // flag: nu,
    checked: false
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
    // flag: nf,
    checked: false
  },
  {
    label: 'Northern Mariana Islands',

    value: 'MP',
    // flag: mp,
    checked: false
  },
  {
    label: 'Norway',
    value: 'NO',
    // flag: no,
    checked: false
  },
  {
    label: 'Oman',
    value: 'OM',
    // flag: om,
    checked: false
  },
  {
    label: 'Pakistan',
    value: 'PK',
    // flag: pk,
    checked: false
  },
  {
    label: 'Palau',
    value: 'PW',
    // flag: pw,
    checked: false
  },
  {
    label: 'Palestinian Territory, Occupied',
    value: 'PS',
    // flag: ps,
    checked: false
  },
  {
    label: 'Panama',
    value: 'PA',
    // flag: pa,
    checked: false
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
    // flag: pg,
    checked: false
  },
  {
    label: 'Paraguay',
    value: 'PY',
    // flag: py,
    checked: false
  },
  {
    label: 'Peru',
    value: 'PE',
    // flag: pe,
    checked: false
  },
  {
    label: 'Philippines',
    value: 'PH',
    // flag: ph,
    checked: false
  },
  {
    label: 'Pitcairn',
    value: 'PN',
    // flag: pn,
    checked: false
  },
  {
    label: 'Poland',
    value: 'PL',
    // flag: pl,
    checked: false
  },
  {
    label: 'Portugal',
    value: 'PT',
    // flag: pt,
    checked: false
  },
  {
    label: 'Puerto Rico',

    value: 'PR',
    // flag: pr,
    checked: false
  },
  {
    label: 'Qatar',
    value: 'QA',
    // flag: qa,
    checked: false
  },
  {
    label: 'Romania',
    value: 'RO',
    // flag: ro,
    checked: false
  },
  {
    label: 'Russia',
    value: 'RU',
    // flag: ru,
    checked: false
  },
  {
    label: 'Rwanda',
    value: 'RW',
    // flag: rw,
    checked: false
  },
  {
    label: 'Reunion',
    value: 'RE',
    // flag: re,
    checked: false
  },
  {
    label: 'Saint Barthelemy',
    value: 'BL',
    // flag: bl,
    checked: false
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    value: 'SH',
    // flag: sh,
    checked: false
  },
  {
    label: 'Saint Kitts and Nevis',

    value: 'KN',
    // flag: kn,
    checked: false
  },
  {
    label: 'Saint Lucia',

    value: 'LC',
    // flag: lc,
    checked: false
  },
  {
    label: 'Saint Martin',
    value: 'MF',
    // flag: mf,
    checked: false
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
    // flag: pm,
    checked: false
  },
  {
    label: 'Saint Vincent and the Grenadines',

    value: 'VC',
    // flag: vc,
    checked: false
  },
  {
    label: 'Samoa',
    value: 'WS',
    // flag: ws,
    checked: false
  },
  {
    label: 'San Marino',
    value: 'SM',
    // flag: sm,
    checked: false
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
    // flag: st,
    checked: false
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
    // flag: sa,
    checked: false
  },
  {
    label: 'Senegal',
    value: 'SN',
    // flag: sn,
    checked: false
  },
  {
    label: 'Serbia',
    value: 'RS',
    // flag: rs,
    checked: false
  },
  {
    label: 'Seychelles',
    value: 'SC',
    // flag: sc,
    checked: false
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    // flag: sl,
    checked: false
  },
  {
    label: 'Singapore',
    value: 'SG',
    // flag: sg,
    checked: false
  },
  {
    label: 'Slovenia',
    value: 'SI',
    // flag: si,
    checked: false
  },
  {
    label: 'Slovakia',
    value: 'SK',
    // flag: sk,
    checked: false
  },

  {
    label: 'Solomon Islands',
    value: 'SB',
    // flag: sb,
    checked: false
  },
  {
    label: 'Somalia',
    value: 'SO',
    // flag: so,
    checked: false
  },
  {
    label: 'South Africa',
    value: 'ZA',
    // flag: za,
    checked: false
  },
  {
    label: 'South Sudan',
    value: 'SS',
    // flag: ss,
    checked: false
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    // flag: gs,
    checked: false
  },
  {
    label: 'Spain',
    value: 'ES',
    // flag: es,
    checked: false
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    // flag: lk,
    checked: false
  },
  {
    label: 'Sudan',
    value: 'SD',
    // flag: sd,
    checked: false
  },
  {
    label: 'Surilabel',
    value: 'SR',
    // flag: sr,
    checked: false
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
    // flag: sj,
    checked: false
  },
  {
    label: 'Swaziland',
    value: 'SZ',
    // flag: sz,
    checked: false
  },
  {
    label: 'Sweden',
    value: 'SE',
    // flag: se,
    checked: false
  },
  {
    label: 'Switzerland',
    value: 'CH',
    // flag: ch,
    checked: false
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY',
    // flag: sy,
    checked: false
  },
  {
    label: 'Taiwan',
    value: 'TW',
    // flag: tw,
    checked: false
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
    // flag: tj,
    checked: false
  },
  {
    label: 'Tanzania, United Republic of Tanzania',
    value: 'TZ',
    // flag: tz,
    checked: false
  },
  {
    label: 'Thailand',
    value: 'TH',
    // flag: th,
    checked: false
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
    // flag: tl,
    checked: false
  },
  {
    label: 'Togo',
    value: 'TG',
    // flag: tg,
    checked: false
  },
  {
    label: 'Tokelau',
    value: 'TK',
    // flag: tk,
    checked: false
  },
  {
    label: 'Tonga',
    value: 'TO',
    // flag: to,
    checked: false
  },
  {
    label: 'Trinidad and Tobago',

    value: 'TT',
    // flag: tt,
    checked: false
  },
  {
    label: 'Tunisia',
    value: 'TN',
    // flag: tn,
    checked: false
  },
  {
    label: 'Turkey',
    value: 'TR',
    // flag: tr,
    checked: false
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
    // flag: tm,
    checked: false
  },
  {
    label: 'Turks and Caicos Islands',

    value: 'TC',
    // flag: tc,
    checked: false
  },
  {
    label: 'Tuvalu',
    value: 'TV',
    // flag: tv,
    checked: false
  },
  {
    label: 'Uganda',
    value: 'UG',
    // flag: ug,
    checked: false
  },
  {
    label: 'Ukraine',
    value: 'UA',
    // flag: ua,
    checked: false
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    // flag: ae,
    checked: false
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    // flag: gb,
    checked: false
  },
  {
    label: 'United States',
    value: 'US',
    // flag: us,
    checked: false
  },
  {
    label: 'Uruguay',
    value: 'UY',
    // flag: uy,
    checked: false
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
    // flag: uz,
    checked: false
  },
  {
    label: 'Vanuatu',
    value: 'VU',
    // flag: vu,
    checked: false
  },
  {
    label: 'Venezuela, Bolivarian Republic of Venezuela',
    value: 'VE',
    // flag: ve,
    checked: false
  },
  {
    label: 'Vietnam',
    value: 'VN',
    // flag: vn,
    checked: false
  },
  {
    label: 'Virgin Islands, British',

    value: 'VG',
    // flag: vg,
    checked: false
  },
  {
    label: 'Virgin Islands, U.S.',

    value: 'VI',
    // flag: vi,
    checked: false
  },
  {
    label: 'Wallis and Futuna',
    value: 'WF',
    // flag: wf,
    checked: false
  },
  {
    label: 'Yemen',
    value: 'YE',
    // flag: ye,
    checked: false
  },
  {
    label: 'Zambia',
    value: 'ZM',
    // flag: zm,
    checked: false
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    // flag: zw,
    checked: false
  }
];

export default countries;
