import { handleValidDate, handleValidTime } from '../../../helpers/helpers';
import { EditContentBlock } from './edit';

export const columns = [
  {
    id: 1,
    Header: 'User ID',
    accessor: 'contentBlockId',
    Cell: (cell: any) => {
      return <EditContentBlock id={cell.value} />;
    }
  },
  {
    id: 2,
    Header: 'Package Rank ID',
    accessor: 'title',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },

  {
    id: 3,
    Header: 'Date Updated',
    accessor: 'dateUpdated',
    Cell: (cell: any) => (
      <>
        {cell?.value ? (
          <>
            {handleValidDate(cell?.value)},
            <small className='text-muted'>
              {' '}
              {handleValidTime(cell?.value)}
            </small>
          </>
        ) : (
          '---'
        )}
      </>
    )
  },

  {
    id: 4,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  }
];
