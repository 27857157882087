import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { columns } from './columns';
import { getMatchingBonus } from './slice';

const PAGE_TITLE = 'Matching Bonus';

const MatchingBonusPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, data } = useAppSelector((state) => state.matchingBonus);
  const [id, setId] = useState(searchParams.get('id') || '');

  useEffect(() => {
    if (id) {
      dispatch(getMatchingBonus(Number(id)));
    }
  }, [searchParams]);

  const { userPackage } = data;

  document.title = 'Package Orders | Ultron Admin';
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Bonuses' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader loading={loading} title={PAGE_TITLE} />

              <CardBody className='pt-0'>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSearchParams({ id });
                  }}>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row>
                      <Col md={3} sm={4}>
                        <SearchInputFilter
                          onChange={(e: any) => setId(e.target.value)}
                          value={id}
                          placeholder='Search User Package ID'
                        />
                      </Col>
                      <Col md={3} sm={4}>
                        <SearchSubmitButton />
                      </Col>
                    </Row>
                  </CardBody>
                  {userPackage && (
                    <CardBody className='border border-dashed border-end-0 border-start-0'>
                      <Row>
                        <Col md={3} sm={4}>
                          Order ID: {userPackage.id}
                        </Col>
                        <Col md={3} sm={4}>
                          Order Date:{' '}
                          {moment(userPackage.dateCreated).format('DD.MM.YYYY')}
                        </Col>
                        <Col md={3} sm={4}>
                          ORDER USER ID: {userPackage.userId}
                        </Col>
                        <Col md={3} sm={4}>
                          ORDER Value: {userPackage.price}
                        </Col>
                      </Row>
                    </CardBody>
                  )}
                </form>
                <TableContainer
                  columns={columns}
                  data={data.userMatchingBonuses || []}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MatchingBonusPage;
