import { Dispatch, FC, SetStateAction } from 'react';
import { Button, DropdownItem } from 'reactstrap';
import { Modal } from '../../../../Components/Custom/modal';
import { useAppDispatch } from '../../../../app/hooks';
import { handleMultipleModalsWithOneState } from '../../../../helpers/helpers';
import { markEventTicketPaid } from '../slice';
import { CalculationStatus, OPENED_MODAL } from '../types';

export const MarkAsPaid: FC<{
  isOpen: number;
  setIsOpen: Dispatch<SetStateAction<number>>;
}> = ({ isOpen, setIsOpen }) => {
  return (
    <>
      {MarkAsPaidActions.map((action, i) => {
        return (
          <DropdownItem
            key={i}
            tag='a'
            href='/'
            className='w-100'
            onClick={(e) => {
              e.preventDefault();
            }}>
            <div
              style={{ width: '100%' }}
              onClick={() => setIsOpen(action.modal)}>
              {action.label}
            </div>
          </DropdownItem>
        );
      })}
    </>
  );
};

export const MarkAsPaidModal: FC<{
  id: number;
  setIsOpen: Dispatch<SetStateAction<number>>;
  isOpen: number;
}> = ({ id, isOpen, setIsOpen }) => {
  const action = ChosenAction(isOpen);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    setIsOpen(OPENED_MODAL.CLOSED);
    dispatch(markEventTicketPaid(id));
  };

  return (
    <Modal
      isOpen={isOpen === OPENED_MODAL.MARK_AS_PAID}
      setIsOpen={() => handleMultipleModalsWithOneState(isOpen, setIsOpen)}
      title={action.label}
      actions={
        <>
          <Button
            color='primary'
            outline
            onClick={() => setIsOpen(OPENED_MODAL.CLOSED)}
            className='m-1'>
            Cancel
          </Button>
          <Button color='primary' className='m-1' onClick={onSubmit}>
            Yes
          </Button>
        </>
      }>
      <div className='mb-3'>
        <div className='avatar-lg mx-auto'>
          <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
            <i className='ri-error-warning-fill '></i>
          </div>
        </div>
      </div>
      <h2>Are you sure?</h2>
      <p>You won’t be able to revert this!</p>
    </Modal>
  );
};

const MarkAsPaidActions = [
  {
    label: 'Mark as paid',
    value: CalculationStatus.CALCULATE,
    modal: OPENED_MODAL.MARK_AS_PAID
  }
];

const ChosenAction = (openedModal: number) => {
  switch (openedModal) {
    case OPENED_MODAL.MARK_AS_PAID:
      return MarkAsPaidActions[0];

    default:
      return {
        label: '',
        value: 0
      };
  }
};
