import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { TeamDirectType, TeamStructureUserType } from './types';

export const getTeamStructureById = createAsyncThunk(
  'teams/getTeamStructureById',
  async (id: string) => {
    const response = await axiosInstance.get(
      `/mlm/user/team/${id}?levelCount=5`
    );

    return response.data.data.teamUser as TeamStructureUserType;
  }
);

export const getTeamDirectById = createAsyncThunk(
  'teams/getTeamDirectById',
  async (id: string) => {
    const response = await axiosInstance.get(`/mlm/user/team/direct/${id}`);
    return response.data.data as TeamDirectType;
  }
);

export const getTeamTradingById = createAsyncThunk(
  'teams/getTeamTradingById',
  async (id: string) => {
    const response = await axiosInstance.get(
      `/mlm/user/team/trading/${id}?levelCount=5`
    );

    return response.data.data.teamUser as TeamStructureUserType;
  }
);

export interface TeamsState {
  structure: TeamStructureUserType;
  direct: TeamDirectType;
  trading: TeamStructureUserType;
  loading: boolean;
}

const initialState = {
  structure: {},
  direct: {},
  trading: {},
  loading: false
} as TeamsState;

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    //TEAM STRUCTURE
    builder.addCase(getTeamStructureById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamStructureById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.structure = payload;
    });
    builder.addCase(getTeamStructureById.rejected, (state) => {
      state.loading = false;
    });
    //DIRECT
    builder.addCase(getTeamDirectById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamDirectById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.direct = payload;
    });
    builder.addCase(getTeamDirectById.rejected, (state) => {
      state.loading = false;
    });
    //TRADING STRUCTURE
    builder.addCase(getTeamTradingById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamTradingById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.trading = payload;
    });
    builder.addCase(getTeamTradingById.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default teamsSlice.reducer;
