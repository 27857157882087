import { CardBody, Col, Row } from 'reactstrap';
import { SelectFilter } from './SelectFilter';

export const RenderSorting = ({
  sortingInputs,
  searchParams,
  setSearchParams,
  colLg,
  colMd,
  colSm
}: {
  sortingInputs?: any;
  searchParams: URLSearchParams;
  setSearchParams: any;
  colLg?: number;
  colMd?: number;
  colSm?: number;
}) => {
  return (
    <CardBody className='border border-dashed border-end-0 border-start-0'>
      <Row>
        {sortingInputs.map((inp: any, i: number) => (
          <Col lg={colLg || 3} md={colMd || 3} sm={colSm || 4} key={i}>
            <SelectFilter
              param={inp.param}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              options={inp.options}
              isClearable={false}
            />
          </Col>
        ))}
      </Row>
    </CardBody>
  );
};
