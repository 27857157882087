import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';
import { Pagination } from '../../../Components/Custom/pagination';

import { Filters } from './filters';

import { columns } from './columns';
import { getConversions } from './slice';

const PAGE_TITLE = 'Conversions';

const ConversionsPage = () => {
  const INITIAL_PARAMS: {
    sortBy: string;
    sortOrder: string;
    countPerPage: string;
    numberOfPage: string;
  } = {
    sortBy: 'dateConverted',
    sortOrder: 'DESC',
    countPerPage: '10',
    numberOfPage: '1'
  };

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);
  const dispatch = useAppDispatch();
  const { loading, data, totalCount } = useAppSelector(
    (state) => state.conversions
  );

  useEffect(() => {
    dispatch(getConversions(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <CardHeader className='border-0'>
                <Row className='align-items-center gy-3'>
                  <div className='col-sm d-flex gap-3 flex-wrap align-items-center'>
                    <h5 className='card-title mb-0'>{PAGE_TITLE}</h5>
                    {loading && <Spinner size='sm' className='primary' />}
                  </div>
                  <div className='col-sm-auto'>
                    <div className='d-flex gap-3 flex-wrap align-items-center'>
                      {/* <AddContentBlock /> */}
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />
                <TableContainer
                  columns={columns || []}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />

                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConversionsPage;
