import { useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';
import { AddContentBlock } from './add';
import { columns } from './columns';
import { getContentBlocks } from './slice';

const PAGE_TITLE = 'Content Blocks';

const ContentBlocksPage = () => {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.contentBlocks);

  useEffect(() => {
    dispatch(getContentBlocks());
  }, []);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <CardHeader className='border-0'>
                <Row className='align-items-center gy-3'>
                  <div className='col-sm d-flex gap-3 flex-wrap align-items-center'>
                    <h5 className='card-title mb-0'>{PAGE_TITLE}</h5>
                    {loading && <Spinner size='sm' className='primary' />}
                  </div>
                  <div className='col-sm-auto'>
                    <div className='d-flex gap-3 flex-wrap align-items-center'>
                      <AddContentBlock />
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className='pt-0'>
                <TableContainer
                  columns={columns || []}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContentBlocksPage;
