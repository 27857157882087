import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { PerformanceIdType, PerformanceIdAddType } from './types';

export const getPerformanceIDs = createAsyncThunk(
  'performance/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/performance/user', {
      params
    });
    return response.data.data;
  }
);

export const addPerformanceIDs = createAsyncThunk(
  'performance/add',
  async (data: PerformanceIdAddType, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/performance/user', data);
      toast.success('User successfully added');

      return response.data.data.performanceUser as PerformanceIdType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

// export const recalculatePerfomanceBonus = createAsyncThunk(
//   'performance/recalculate',
//   async () => {
//     const response = await axiosInstance.post('/performance/recalculate-uv');
//     return response.data.data;
//   }
// );

export const recalculatePerfomanceBonus = createAsyncThunk(
  'performance/recalculate',
  async () => {
    try {
      const response = await axiosInstance.post(`/performance/recalculate-uv`);
      toast.success(response.data.message);
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
  }
);

export const deletePerformanceID = createAsyncThunk(
  'performance/delete',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/performance/user/${userId}`
      );
      toast.success(response.data.message);

      return userId;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export interface PerformanceIdTypeInterface {
  data: PerformanceIdType[];
  loading: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false
} as PerformanceIdTypeInterface;

const performanceIDsSlice = createSlice({
  name: 'performanceIDs',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getPerformanceIDs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPerformanceIDs.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data = payload.performanceUsers as PerformanceIdType[];
      state.totalCount = payload.count;
    });
    builder.addCase(getPerformanceIDs.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addPerformanceIDs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPerformanceIDs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.unshift(payload);
    });
    builder.addCase(addPerformanceIDs.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deletePerformanceID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePerformanceID.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.data.splice(
        state.data.findIndex((p) => p.userId === payload),
        1
      );
    });
    builder.addCase(deletePerformanceID.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(recalculatePerfomanceBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recalculatePerfomanceBonus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(recalculatePerfomanceBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default performanceIDsSlice.reducer;
