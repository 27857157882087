import { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Modal } from '../../../Components/Custom/modal';
import { useAppDispatch } from '../../../app/hooks';
import { addBlacklistUser } from './slice';

export const AddBlacklistedUser = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [userId, setUserId] = useState<string>('');

  const [remark, setRemark] = useState<string>('');

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data = {
      userId,
      remark
    };

    dispatch(addBlacklistUser(data));
    handleClearState();
  };

  const handleClearState = () => {
    setIsOpen(!isOpen);

    setUserId('');
    setRemark('');
  };

  return (
    <>
      <Button color='primary' onClick={() => setIsOpen(!isOpen)}>
        Add User Id
      </Button>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        title='Add Blacklist User Id'
        size='xl'
        form
        onFormSubmit={handleSubmit}
        actions={
          <>
            <Button color='primary' onClick={handleClearState}>
              Cancel
            </Button>
            <Button color='primary' type='submit' disabled={!userId || !remark}>
              Add
            </Button>
          </>
        }>
        <Row>
          <Col lg={12}>
            <Col lg={6}>
              <Label className='w-100 text-left' for='userId'>
                User Id
              </Label>
              <Input
                id='userId'
                className='w-100 mb-2'
                type='text'
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder='Enter here'
              />
            </Col>
          </Col>
          <Col lg={12}>
            <Col lg={6}>
              <Label for='title'>Remark</Label>
              <Input
                id='title'
                className='w-100 mb-2'
                type='textarea'
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder='Enter here'
              />
            </Col>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
