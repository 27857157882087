import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button } from 'reactstrap';
import { useAppDispatch } from '../../../app/hooks';
import { markPackageRankRewardDelivered } from './slice';
import { Modal } from '../../../Components/Custom/modal';

export const MarkPackageRankRewardDelivered: FC<{
  id: number;
}> = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        size='sm'
        color='secondary'
        className='btn btn-soft-secondary btn-sm'
        outline
        onClick={() => setIsOpen(!isOpen)}>
        Mark as delivered
      </Button>

      <MarkPackageRankRewardDeliveredModal
        id={id}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </>
  );
};

export const MarkPackageRankRewardDeliveredModal: FC<{
  id: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}> = ({ id, isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title='Mark as delivered'
      actions={
        <>
          <Button
            color='primary'
            outline
            onClick={() => setIsOpen(!isOpen)}
            className='m-1'>
            Cancel
          </Button>
          <Button
            color='primary'
            className='m-1'
            onClick={() => {
              setIsOpen(!isOpen);
              dispatch(markPackageRankRewardDelivered(id));
            }}>
            Yes
          </Button>
        </>
      }>
      <div className='mb-3'>
        <div className='avatar-lg mx-auto'>
          <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
            <i className='ri-error-warning-fill '></i>
          </div>
        </div>
      </div>
      <h2>Are you sure?</h2>
      <p>You won’t be able to revert this!</p>
    </Modal>
  );
};
