export interface User {
  id: number;
  username: string | null;
  name: string | null;
  surname: string | null;
  email: string | null;
  profileImageUrl: string | null;
  dateCreated: string;
  registrationStatus: RegistrationStatusEnum;
  rank: {
    id: number;
    rank: {
      rankId: number;
      name: string;
    };
  };
  packageRank: {
    packageRankId: number;
    name: string;
  };
  country: string | null;
  referrerId: number | null;
  kycVerificationStatus: KycVerificationStatusEnum;

  userWallets: {
    id: number;
    address: string;
    dateCreated: string;
    status: number;
    label: string | null;
  };
}

export enum RegistrationStatusEnum {
  EMAIL_VERIFICATION = 3,
  EMAIL_VERIFIED = 4,
  PASSWORD_ADDED = 5,
  SOCIAL_NETWORK_CREATION = 6,
  SOCIAL_NETWORK_REFERRER_ADDED = 7,
  TEAM_ADDING = 10,
  PROFILE_DETAILS_ADDED = 11,
  SOCIAL_NETWORK_PROFILE_DETAILS_ADDED = 12,
  SOCIAL_NETWORK_TERMS_AND_CONDITIONS_ACCEPTED = 13
}

export enum CreationTypesEnum {
  EMAIL = 1,
  FACEBOOK = 2,
  APPLE = 3,
  GOOGLE = 4,
  LINKED_IN = 5
}

export enum KycVerificationStatusEnum {
  NOT_ENTERED = 1,
  NOT_VERIFIED,
  VERIFIED
}
