import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { MarkPackageRankRewardDelivered } from './markPackageRankRewardDelivered';
import { PackageRankRewardStatus } from './types';

export const columns = [
  {
    id: 1,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'Package Rank ID',
    accessor: 'packageRank',
    Cell: (cell: any) => {
      return cell?.value?.packageRankId;
    }
  },

  {
    id: 3,
    Header: 'Package',
    accessor: 'packageRank',
    Cell: (cell: any) => {
      return cell.value?.name;
    }
  },

  {
    id: 4,
    Header: 'Amount',
    accessor: 'package',
    Cell: (cell: any) => {
      return cell?.value?.price
        ? usdFormatter.format(cell?.value?.price)
        : '---';
    }
  },

  {
    id: 5,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },

  {
    id: 6,
    Header: 'Reward',
    accessor: 'packageRankReward',
    Cell: (cell: any) => {
      return cell.value?.name || '---';
    }
  },

  {
    id: 8,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case PackageRankRewardStatus.PENDING:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Pending
            </span>
          );

        case PackageRankRewardStatus.DELIVERED:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Delivered
            </span>
          );

        default:
          return null;
      }
    }
  },

  {
    id: 7,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      if (cell.row.original.status === 1)
        return <MarkPackageRankRewardDelivered id={cell.value} />;
    }
  }
];
