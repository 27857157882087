import { handleValidDate, handleValidTime } from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'Package Rank ID',
    accessor: 'packageRank',
    Cell: (cell: any) => {
      return cell?.value?.packageRankId;
    }
  },

  {
    id: 3,
    Header: 'Package',
    accessor: 'packageRank',
    Cell: (cell: any) => {
      return <>{cell.value.name}</>;
    }
  },
  {
    id: 4,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  }
];
