import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { NetworkType } from '../PackageOrders/types';
import { MarkLicenseAsPaid } from './markLicenseAsPaid';
import { Storno } from './storno';
import { OrderType } from './types';

export const columns = [
  {
    id: 1,
    Header: 'License ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'user',
    Cell: (cell: any) => {
      return cell.value.id;
    }
  },

  {
    id: 3,
    Header: 'User',
    accessor: 'user',
    Cell: (cell: any) => {
      return (
        <>
          {cell.value.name} {cell.value.surname}
        </>
      );
    }
  },
  {
    id: 4,
    Header: 'Type',
    accessor: 'orderType',
    Cell: (cell: any) => {
      switch (cell.value) {
        case OrderType.ORDER:
          return <div>Order</div>;

        case OrderType.PACKAGE:
          return <div>Package</div>;

        case OrderType.LICENCE:
          return <div>Licence</div>;

        case OrderType.COMBO:
          return <div>Combo</div>;
      }
    }
  },
  {
    id: 5,
    Header: 'Amount',
    accessor: 'package',
    Cell: (cell: any) => {
      return cell.row.original.price
        ? usdFormatter.format(cell.row.original.price)
        : '---';
    }
  },

  {
    id: 6,
    Header: 'Payment address',
    accessor: 'paymentAddress',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 7,
    Header: 'Network type',
    accessor: 'networkType',
    Cell: (cell: any) => {
      switch (cell.value) {
        case NetworkType.BEP_20:
          return <div>BEP-20</div>;

        case NetworkType.TRC_20:
          return <div>TRC-20</div>;

        case NetworkType.Ultron:
          return <div>Ultron</div>;

        default:
          return <div></div>;
      }
    }
  },
  {
    id: 8,
    Header: 'Order Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.row.original.dateCreated)},
        <small className='text-muted'>
          {' '}
          {handleValidTime(cell.row.original.dateCreated)}
        </small>
      </>
    )
  },

  {
    id: 9,
    Header: 'Payment Date',
    accessor: 'datePaid',
    Cell: (cell: any) => {
      if (cell.value) {
        return (
          <>
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        );
      }
    }
  },
  {
    id: 10,
    Header: 'BO extended to',
    accessor: 'dateEnd',
    Cell: (cell: any) => {
      if (cell.value) {
        return (
          <>
            {handleValidDate(cell.row.original.dateEnd)},
            <small className='text-muted'>
              {' '}
              {handleValidTime(cell.row.original.dateEnd)}
            </small>
          </>
        );
      }
    }
  },

  {
    id: 11,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Pending
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Paid
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              Canceled
            </span>
          );
      }
    }
  },
  {
    id: 12,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      if (cell.row.original.status === 1) {
        return <MarkLicenseAsPaid id={cell.value} />;
      } else if (
        cell.row.original.status === 2 &&
        cell.row.original.isCancelable
      ) {
        return <Storno id={cell.value} />;
      }
    }
  }
];
