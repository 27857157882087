import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';
import { CreateAdminUser } from './actions/createAdminUser';
import { GetUserById } from './actions/getUserById';
import { columns } from './columns';
import {
  getSuperAdmin,
  getWalletsExport,
  recalculateUserTeamInfo,
  removeUserWalletExportData,
  recalculateRanks
} from './slice';

const PAGE_TITLE = 'Super Admin';

const SuperAdminPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, data, userWalletsExports } = useAppSelector(
    (state) => state.superAdmin
  );
  const initialRef: any = null;
  const csvLinkEl = useRef(initialRef);

  useEffect(() => {
    if (csvLinkEl.current !== null && userWalletsExports.length) {
      csvLinkEl.current.link.click();
      dispatch(removeUserWalletExportData());
    }
  }, [userWalletsExports]);

  useEffect(() => {
    dispatch(getSuperAdmin());
  }, []);

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Address', key: 'address' },
    { label: 'Is Used', key: 'isUsed' },
    { label: 'Network type', key: 'networkType' },
    { label: 'Package ID', key: 'userPackageId' }
  ];

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading}
                title={PAGE_TITLE}
                actions={
                  <>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => dispatch(recalculateRanks())}>
                      Recalculate ranks
                    </button>
                    <CSVLink
                      data={userWalletsExports}
                      headers={headers}
                      filename={'users-wallets-export.csv'}
                      target='_blank'
                      style={{ visibility: 'hidden' }}
                      ref={csvLinkEl}></CSVLink>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => dispatch(recalculateUserTeamInfo())}>
                      <i className='ri-file-download-fill align-bottom me-1'></i>{' '}
                      Recalculate user team info
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => dispatch(getWalletsExport())}>
                      <i className='ri-file-download-fill align-bottom me-1'></i>{' '}
                      Download wallets
                    </button>
                    <CreateAdminUser />
                  </>
                }
              />
              <CardBody className='pt-0'>
                <GetUserById
                  params={searchParams}
                  setParams={setSearchParams}
                />

                <TableContainer
                  columns={columns}
                  data={data}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SuperAdminPage;
