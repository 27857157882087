import { handleValidDate, handleValidTime } from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Bonus ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },

  {
    id: 3,
    Header: 'TB ID',
    accessor: 'userTeamBonusId',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },

  {
    id: 4,
    Header: 'TB Amount',
    accessor: 'amount',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },

  {
    id: 5,
    Header: 'Rank',
    accessor: 'rank',
    Cell: (cell: any) => {
      return <>{cell?.value?.name}</>;
    }
  },

  {
    id: 6,
    Header: '%',
    accessor: 'comissionPercent',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 7,
    Header: 'Amount',
    accessor: 'comissionAmount',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },

  {
    id: 8,
    Header: 'Levels',
    accessor: 'comissionLevels',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },

  {
    id: 9,
    Header: 'Calc Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },

  {
    id: 10,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Pending
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Paid
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-info'>
              Ineligible
            </span>
          );
      }
    }
  }
];
