import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { DirectBonusState, DirectBonusType } from './types';
import { toast } from 'react-toastify';

export const getDirectBonus = createAsyncThunk(
  'getDirectBonus/fetch',
  async (id: number | null) => {
    const response = await axiosInstance.get(`/bonus/direct/${id}`);
    if (response.data.data.userPackage === null) {
      toast.error('Please type valid Order ID');
    }
    return response.data.data as DirectBonusType;
  }
);

const initialState = {
  data: {},
  loading: false
} as DirectBonusState;

const getDirectBonusSlice = createSlice({
  name: 'getDirectBonus',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getDirectBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDirectBonus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(getDirectBonus.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default getDirectBonusSlice.reducer;
