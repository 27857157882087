import { Dispatch, SetStateAction } from 'react';
import { CSVLink } from 'react-csv';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { axiosInstance } from '../../helpers/api_helper';

const ExportCSVModal = ({
  isOpen,
  setIsOpen,
  data,
  fileName
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  fileName: string;
}) => {
  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered={true}>
      <ModalHeader toggle={() => setIsOpen(!isOpen)}>
        Export data to CSV
      </ModalHeader>
      <ModalBody className='py-3 px-5'>
        <div className='mt-2 text-center'>
          <div className='mb-3'>
            <div className='avatar-lg mx-auto'>
              <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
                <i className='ri-download-2-line'></i>
              </div>
            </div>
          </div>
          <div className='mt-4 pt-2 fs-15 mx-4 mx-sm-5'>
            <h4>Are you sure ?</h4>
            <p className='text-muted mx-4 mb-0'>
              Are you sure you want to export CSV file?
            </p>
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
          <button
            type='button'
            className='btn w-sm btn-light'
            data-bs-dismiss='modal'
            onClick={() => setIsOpen(!isOpen)}>
            Close
          </button>
          {/* <CSVLink
            data={data}
            type='button'
            onClick={() => setIsOpen(!isOpen)}
            className='btn w-sm btn-success '
            id='delete-record'
            filename={fileName}>
            Download
          </CSVLink> */}
          <CSVLink
            data={data}
            asyncOnClick={true}
            onClick={(event, done) => {
              axiosInstance.get('/user/mlm/filter/export').then(() => {
                done(); // REQUIRED to invoke the logic of component
              });
            }}>
            Download
          </CSVLink>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExportCSVModal;
