import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { Modal } from '../../../Components/Custom/modal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  addCategory,
  editCategory,
  removeCategoryForEdit,
  removeCategoryIdForModal
} from '../slice';

export const AddEditCategoryModal = () => {
  const dispatch = useAppDispatch();

  const { loading, categories, categoryForEdit, categoryIdForModal } =
    useAppSelector((state) => state.faq);

  const [categoryId, setCategoryId] = useState(null);
  const [title, setTitle] = useState('');

  const categoriesOptions: any = [];

  categories?.forEach((c) => {
    categoriesOptions.push({
      label: c.name,
      value: c.id
    });
    // {
    //   c.subCategories?.forEach((sc) =>
    //     categoriesOptions.push({
    //       label: sc.name,
    //       value: sc.id
    //     })
    //   );
    // }
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (categoryId === null) return;

    const dataEdit = {
      id: categoryForEdit?.id,
      parentId: categoryId['value'],
      name: title
    };

    const { id, ...dataAdd } = dataEdit;

    if (typeof categoryIdForModal === 'string') {
      dispatch(addCategory({ data: dataAdd }));
    } else {
      dispatch(editCategory({ data: dataEdit }));
    }

    handleClearState();
  };

  const handleClearState = () => {
    setTitle('');
    setCategoryId(null);
    dispatch(removeCategoryForEdit());
    dispatch(removeCategoryIdForModal());
  };

  useEffect(() => {
    if (categoryForEdit?.id && categories) {
      setTitle(categoryForEdit?.name);

      const getCategory = categoriesOptions.find(
        (e: any) => e.value == categoryForEdit?.parentId
      );

      setCategoryId(getCategory);
    }
  }, [categoryForEdit, categoryIdForModal]);

  return (
    <Modal
      isOpen={categoryIdForModal ? true : false}
      setIsOpen={() => {
        dispatch(removeCategoryForEdit());
        dispatch(removeCategoryIdForModal());
        handleClearState();
      }}
      title='Subcategory'
      form
      onFormSubmit={handleSubmit}
      actions={
        <>
          <Button color='primary' onClick={handleClearState}>
            Cancel
          </Button>
          <Button color='primary' type='submit'>
            {categoryIdForModal === 'add' ? 'Add' : 'Edit'}
          </Button>
        </>
      }>
      {!loading ? (
        <Row>
          <Col lg={12}>
            <Label className='w-100 text-left' for='title'>
              Category ID
            </Label>
            <div className='mb-2 d-inline-block col-12'>
              <Select
                options={categoriesOptions}
                placeholder='Select Category ID'
                onChange={(e: any) => setCategoryId(e)}
                value={categoryId}
              />
            </div>
          </Col>
          <Col lg={12}>
            <Label for='title'>Title</Label>
            <Input
              id='title'
              className='w-100 mb-2'
              type='text'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder='Enter here'
            />
          </Col>
        </Row>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};
