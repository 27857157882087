import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  handleSetUrlSearchParams,
  setDocumentTitle
} from '../../../helpers/helpers';
import { getTopPerformerBonus } from './slice';
import TableContainer from '../../../Components/Common/TableContainer';
import { columns } from './columns';

const PAGE_TITLE = 'Top Performer Bonus';

const TopPerformerBonusPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, data } = useAppSelector((state) => state.topPerformerBonus);

  // const [monthDate, setMonthDate] = useState(
  //   searchParams.get('monthDate') || ''
  // );
  const [userId, setUserId] = useState(searchParams.get('userId') || '');

  useEffect(() => {
    if (userId) {
      dispatch(getTopPerformerBonus(searchParams));
    }
  }, [searchParams]);

  const { topPerformerBonuses } = data;

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Bonuses' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader loading={loading} title={PAGE_TITLE} />
              <CardBody className='pt-0'>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    handleSetUrlSearchParams('userId', userId, setSearchParams);
                  }}>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row>
                      <Col md={3} sm={4}>
                        <SearchInputFilter
                          onChange={(e: any) => setUserId(e.target.value)}
                          value={userId}
                          placeholder='Search User ID'
                        />
                      </Col>

                      <Col md={3} sm={4}>
                        <SearchSubmitButton />
                      </Col>
                    </Row>
                  </CardBody>
                </form>
                <TableContainer
                  columns={columns}
                  data={topPerformerBonuses || []}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopPerformerBonusPage;
