import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Modal } from '../../../../../Components/Custom/modal';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import {
  handleValidDate,
  handleValidTime
} from '../../../../../helpers/helpers';
import { addComment } from '../../slice';

export const Comments = () => {
  const dispatch = useAppDispatch();
  const [addCommentModal, setAddCommentModal] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const { data } = useAppSelector((state) => state.userDetails);

  const handleSubmit = () => {
    if (!title) toast.error('Please enter title');
    if (!description) toast.error('Please enter description');
    dispatch(addComment({ userId: data.id, title, description }));
    setAddCommentModal(false);
    setTitle('');
    setDescription('');
  };

  return (
    <Row>
      <Col lg={12} className='justify-content-between d-flex mb-3'>
        <h4>Comments</h4>
        <Button
          color='primary'
          onClick={() => setAddCommentModal(!addCommentModal)}>
          Add comments
        </Button>
      </Col>
      {data?.adminComments?.map((comment, i) => (
        <Col lg={12} key={i}>
          <div className='border border-1 p-3 mb-3'>
            <h5>{comment.title}</h5>
            <p>{comment.description}</p>
            <p className='mb-0'>
              {comment.adminFullName}, {handleValidDate(comment.dateCreated)},{' '}
              <small className='text-muted'>
                {handleValidTime(comment.dateCreated)}
              </small>
            </p>
          </div>
        </Col>
      ))}

      <Modal
        isOpen={addCommentModal}
        setIsOpen={() => setAddCommentModal(!addCommentModal)}
        title='Add Comment'
        actions={
          <Button color='primary' onClick={handleSubmit}>
            Add
          </Button>
        }>
        <div>
          <Label htmlFor='title' className='form-label'>
            Title
          </Label>
          <Input
            type='text'
            className='form-control'
            id='title'
            placeholder='Enter title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Label htmlFor='description' className='form-label'>
            Description
          </Label>
          <Input
            type='textarea'
            rows='10'
            className='form-control'
            id='description'
            placeholder='Enter description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </Modal>
    </Row>
  );
};
