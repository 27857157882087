import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Counter } from '../../../Components/Custom/Counter';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { handleValidDate, setDocumentTitle } from '../../../helpers/helpers';
import { FastStartDirectUser } from './fastStartDirectUser';
import { getFastStartBonus } from './slice';

const PAGE_TITLE = 'Fast Start Bonus';

const FastStartBonusPreviewPage = () => {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.fastStartBonus);
  const match = useMatch('/fast-start-bonus/:id/:cashbackBonusId');
  const userId = match?.params.id;
  const cashbackBonusId =
    match?.params?.cashbackBonusId === 'null'
      ? null
      : match?.params?.cashbackBonusId;

  useEffect(() => {
    if (userId) {
      dispatch(getFastStartBonus({ userId }));
    }
  }, [userId]);

  setDocumentTitle(PAGE_TITLE);

  const date = new Date(data?.info?.limitDate);
  const timestamp = date.getTime();

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Bonuses' />
        <Card id='orderList'>
          <PageHeader
            loading={loading}
            title={`USER ID: ${userId} ${
              cashbackBonusId ? `/ CASHBACK BONUS ID ${cashbackBonusId}` : ''
            }`}
          />
        </Card>
        <Row>
          <Col lg={12}>
            {userId && (
              <>
                <>
                  <Card>
                    <CardBody>
                      <div className='d-flex align-center justify-content-between w-100'>
                        <h4 className='mb-0'>Qualified Partner</h4>

                        {!data?.info?.isQualifiedPartner &&
                        data?.info?.isLimitDatePassed ? (
                          <i className='ri-close-circle-fill fs-24'></i>
                        ) : (
                          <i className='ri-checkbox-circle-fill fs-24'></i>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div>
                        {data &&
                          data?.statusPerPackage?.map(
                            ({ hubNames, directUsers, boughtHub }, i) => {
                              return (
                                <Row key={i} className='mb-3 p-3 border'>
                                  <Col
                                    lg={5}
                                    className='d-flex align-items-center'>
                                    <div className='py-3'>
                                      <h5
                                        className={`${
                                          boughtHub && 'text-info'
                                        }`}>
                                        {hubNames}
                                      </h5>
                                      {boughtHub && (
                                        <>
                                          <p className='mb-0'>
                                            Order ID: {boughtHub?.userPackageId}{' '}
                                            / Payment Date:{' '}
                                            {handleValidDate(
                                              boughtHub?.datePaid
                                            )}
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={7}>
                                    <Row>
                                      {directUsers?.map((user, i) => (
                                        <Col key={i}>
                                          <FastStartDirectUser user={user} />
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className='d-flex align-items-center justify-content-between w-100'>
                        <h4>
                          Start Date: {handleValidDate(data?.info?.dateFrom)}
                        </h4>
                        <Counter timestamp={timestamp} />
                      </div>
                    </CardBody>
                  </Card>
                </>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FastStartBonusPreviewPage;
